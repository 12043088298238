import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import lightningIcon from "../../../assets/icons/lightning.svg"
import propicsRowImage from "../../../assets/images/propics-row.png"
import { useTranslation } from "react-i18next"
import { useContext, useEffect, useRef, useState } from "react"
import { ChallengeContext } from "../../../controllers/challenge"
import { MainContext } from "../../../controllers/main"
import Confetti from "../../global/common/Confetti"
import { MissionsContext } from "../../../controllers/missions"
import ChallengeDotProgressBar from "../common/ChallengeDotProgressBar"

const ChallengeCardDesktop = () => {
  const { t } = useTranslation()
  const { viewTutorial, currentTutorialPage } = useContext(MainContext)
  const { challenge } = useContext(ChallengeContext)
  const { completedMissionsCount } = useContext(MissionsContext)

  // show confetti fot tutorial animation
  const [showConfetti, setShowConfetti] = useState<boolean>(false)
  const showConfettiTimeoutId = useRef<any>(null)

  useEffect(() => {
    if (viewTutorial && currentTutorialPage === 1) {
      showConfettiTimeoutId.current = setTimeout(() => {
        setShowConfetti(true)
      }, 1000)
    } else {
      if (showConfettiTimeoutId.current) {
        clearTimeout(showConfettiTimeoutId.current)
      }
      setShowConfetti(false)
    }
  }, [viewTutorial, currentTutorialPage])

  return (
    <Stack
      id="challenge-card"
      alignItems="center"
      style={{
        width: "75%",
        height: 286,
        backgroundColor: colors.backgroundWhite,
        borderRadius: 15,
        position: "relative",
        paddingTop: 18,
        boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
      }}
      role="region"
      aria-label={t("challenge_card")}
    >
      {showConfetti ? <Confetti zIndex={150} particleCount={80} /> : null}
      <Title fontSize={14} lineHeight="17px" color={colors.primary}>
        {t("collective_challenge").toUpperCase()}
      </Title>
      <Title fontSize={24} lineHeight="32px" style={{ marginTop: 12 }}>
        {challenge?.title}
      </Title>
      <Text
        fontSize={14}
        lineHeight="16px"
        fontWeight={400}
        style={{ marginTop: 12 }}
      >
        {t("complete_together")}
      </Text>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ width: "100%", height: 23, gap: 8.5, marginTop: 12 }}
      >
        <img src={lightningIcon} style={{ height: 20 }} alt="" />
        <Title fontSize={20} lineHeight="23px" color={colors.primary}>
          {challenge!.targetAmount} {t("missions").toLowerCase()}
        </Title>
      </Stack>
      <ChallengeDotProgressBar
        percentage={
          viewTutorial && currentTutorialPage === 1
            ? 100
            : Math.round(
                (challenge!.currentAmount / challenge!.targetAmount) * 100
              )
        }
        style={{
          width: 260,
          height: 15.6,
          marginTop: 10,
        }}
      />
      <Stack
        direction="row"
        alignItems="center"
        style={{ width: 260, height: 24, gap: 8, marginTop: 10 }}
      >
        <img src={propicsRowImage} style={{ height: "100%" }} alt="" />
        <Text fontSize={14} fontWeight={400}>
          {challenge!.userCount} {t("partecipants")}
        </Text>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          height: 44,
          position: "absolute",
          bottom: 0,
          backgroundColor: colors.primary,
          borderBottomRightRadius: 15,
          borderBottomLeftRadius: 15,
          paddingInline: 25,
        }}
      >
        <Title fontSize={16} color={colors.textWhite}>
          {t("your_contribution")}
        </Title>
        <Title
          fontSize={16}
          color={colors.textWhite}
          style={{ position: "absolute", right: 25 }}
        >
          {(challenge!.currentAmount
            ? Math.round(
                ((completedMissionsCount / challenge!.currentAmount) * 100 +
                  Number.EPSILON) *
                  100
              ) / 100
            : 0
          )
            .toString()
            .replace(".", ",")}
          %
        </Title>
      </Stack>
    </Stack>
  )
}

export default ChallengeCardDesktop
