import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import DailyEpisodeMobile from "../../components/episode/DailyEpisodeMobile"
import { useNavigate, useParams } from "react-router-dom"
import { JourneysContext } from "../../controllers/journeys"
import DailyEpisodeDesktop from "../../components/episode/DailyEpisodeDesktop"
import { deepCopy, registerPageEvent } from "../../services/utils/utils"
import Mission from "../../models/mission"

const DailyEpisode = () => {
  const navigate = useNavigate()
  const { slideId } = useParams()
  const { isMobile, currentMission } = useContext(MainContext)
  const { dailyEpisode } = useContext(JourneysContext)

  // redirect to landing if currentMission isn't present
  useEffect(() => {
    if (!currentMission) {
      navigate("/")
    }
  }, [])

  // register google analytics event
  useEffect(() => {
    registerPageEvent("daily_episode")
  }, [])

  // current slide and slides info
  const currentSlideIndex = parseInt(slideId!)
  const currentSlide = dailyEpisode!.slides[currentSlideIndex]
    ? dailyEpisode!.slides[currentSlideIndex]
    : dailyEpisode!.quiz[currentSlideIndex - dailyEpisode!.slides.length]!
  const currentSlideQuizResult = currentSlide
    ? dailyEpisode!.result.quiz.find((item) => item.slideId === currentSlide.id)
    : undefined
  const numberOfSlides = dailyEpisode!.slides.length
  const numberOfQuiz = dailyEpisode!.quiz.length
  const totalSlides = numberOfSlides + numberOfQuiz

  // episode completed or mission completed feedbacks
  const [episodeFeedbackOpen, setEpisodeFeedbackOpen] = useState<boolean>(false)
  const [missionFeedbackOpen, setMissionFeedbackOpen] = useState<boolean>(false)

  // check if currentMission changed to show feedback
  const currentMissionCopy: Mission = useMemo(() => {
    return deepCopy(currentMission)
  }, [])

  useEffect(() => {
    if (
      currentMission &&
      currentMissionCopy &&
      currentSlideIndex === totalSlides &&
      dailyEpisode!.result.completed
    ) {
      if (currentMissionCopy.current + 1 >= currentMissionCopy.target) {
        setTimeout(() => {
          setMissionFeedbackOpen(true)
        }, 400)
      } else {
        setTimeout(() => {
          setEpisodeFeedbackOpen(true)
        }, 400)
      }
    }
  }, [currentSlideIndex, totalSlides, dailyEpisode])

  return isMobile ? (
    <DailyEpisodeMobile
      currentSlide={currentSlide}
      currentSlideIndex={currentSlideIndex}
      numberOfSlides={numberOfSlides}
      numberOfQuiz={numberOfQuiz}
      totalSlides={totalSlides}
      currentSlideQuizResult={currentSlideQuizResult}
      episodeFeedbackOpen={episodeFeedbackOpen}
      missionFeedbackOpen={missionFeedbackOpen}
    />
  ) : (
    <DailyEpisodeDesktop
      currentSlide={currentSlide}
      currentSlideIndex={currentSlideIndex}
      numberOfSlides={numberOfSlides}
      numberOfQuiz={numberOfQuiz}
      totalSlides={totalSlides}
      currentSlideQuizResult={currentSlideQuizResult}
      episodeFeedbackOpen={episodeFeedbackOpen}
      missionFeedbackOpen={missionFeedbackOpen}
    />
  )
}

export default DailyEpisode
