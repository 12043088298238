import { ButtonBase, Popover, Stack } from "@mui/material"
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react"
import Title from "./Title"
import "../../../styles/languageSelect.css"
import chevronDownBlackIcon from "../../../assets/icons/chevron-down-black.svg"
import { colors } from "../../../services/config/colors"
import { availableLanguages } from "../../../services/config/constants"
import { t } from "i18next"
import { MainContext } from "../../../controllers/main"

const LanguageSelect = ({
  setLangChangeAlertOpen,
  setLocalLang,
  width = 70,
  style,
}: {
  setLangChangeAlertOpen: Dispatch<SetStateAction<boolean>>
  setLocalLang: Dispatch<SetStateAction<string>>
  width?: number
  style?: CSSProperties
}) => {
  const { lang } = useContext(MainContext)

  // popover logic
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <ButtonBase
        style={{
          width: width,
          height: 24,
          backgroundColor: colors.backgroundWhite,
          borderRadius: 12,
          paddingInline: 6,
          justifyContent: "flex-start",
          position: "relative",
          ...style,
        }}
        onClick={handleClick}
        aria-label={t("change_language")}
      >
        <img
          src={availableLanguages.find((item) => item.code === lang)?.flag}
          style={{ width: 14, height: 14, borderRadius: "100%" }}
        />
        <Title
          fontSize={14}
          style={{
            transition: "200ms",
            marginLeft: 6,
          }}
        >
          {lang.toUpperCase()}
        </Title>
        <img
          src={chevronDownBlackIcon}
          style={{
            width: 16,
            height: 16,
            position: "absolute",
            right: 6,
            transition: "200ms",
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </ButtonBase>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{ zIndex: 1100000 }}
        classes={{ paper: "popover-paper" }}
      >
        <Stack
          style={{
            width: width,
          }}
        >
          {availableLanguages.map((item, index) => (
            <ButtonBase
              key={index}
              style={{
                width: "100%",
                height: 36,
                paddingLeft: 6,
                justifyContent: "flex-start",
                backgroundColor:
                  lang === item.code
                    ? colors.backgroundLight
                    : colors.backgroundWhite,
                transition: "200ms",
              }}
              onClick={() => {
                setLangChangeAlertOpen(true)
                setLocalLang(item.code)
                handleClose()
              }}
              aria-label={t(item.label)}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", gap: 6 }}
              >
                <img
                  src={item.flag}
                  style={{ width: 14, height: 14, borderRadius: "100%" }}
                />
                <Title fontSize={14}>{item.code.toUpperCase()}</Title>
              </Stack>
            </ButtonBase>
          ))}
        </Stack>
      </Popover>
    </>
  )
}

export default LanguageSelect
