import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import arrowRightWhiteIcon from "../../../assets/icons/arrow-right-white.svg"
import { MissionsContext } from "../../../controllers/missions"
import { Dispatch, SetStateAction, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useReducedMotion } from "framer-motion"
import { useNavigate } from "react-router-dom"
import spaceshipImage from "../../../assets/images/spaceship.svg"
import Text from "../../global/common/Text"

const CompletedMissionCardMobile = ({
  index,
  slidesLength,
  setPerformanceOpen,
}: {
  index: number
  slidesLength: number
  setPerformanceOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const shouldReduceMotion = useReducedMotion()
  const { currentSlide, missions } = useContext(MissionsContext)

  return (
    <div
      style={{
        width: 256,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
      onClick={() => {
        if (Math.abs(currentSlide % slidesLength) === index) {
          if (shouldReduceMotion) {
            navigate("/performance")
          } else {
            setPerformanceOpen(true)
            setTimeout(() => {
              navigate("/performance")
            }, 180)
          }
        }
      }}
      aria-hidden={
        Math.abs(currentSlide % slidesLength) === index ? "false" : "true"
      }
    >
      <ButtonBase
        disabled={Math.abs(currentSlide % slidesLength) !== index}
        style={{
          width: "100%",
          height: "calc(100% - 23px)",
          borderRadius: 20,
          boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
          border: "2px solid " + colors.primary,
        }}
        aria-label={
          missions.length
            ? t("view_all_completed_missions")
            : `${t("all_missions_completed")} ${t("go_to_your_points")}`
        }
      >
        <Stack
          alignItems="center"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 20,
            backgroundColor: colors.background,
          }}
        >
          <Title
            fontSize={18}
            fontWeight={500}
            lineHeight="22px"
            textAlign="center"
            style={{ marginTop: 22 }}
            paddingLeft={22}
            paddingRight={22}
          >
            {missions.length
              ? t("view_all_completed_missions")
              : t("all_missions_completed")}
          </Title>
          <img
            src={spaceshipImage}
            style={{ width: 165, marginTop: 28 }}
            alt=""
          />
          {!missions.length ? (
            <Text fontSize={14} fontWeight={400} style={{ marginTop: 18 }}>
              {t("go_to_your_points")}
            </Text>
          ) : null}
        </Stack>
      </ButtonBase>
      <div
        style={{
          width: 46,
          height: 46,
          borderRadius: "100%",
          backgroundColor: colors.primary,
          position: "absolute",
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={arrowRightWhiteIcon} style={{ width: 14 }} alt="" />
      </div>
    </div>
  )
}

export default CompletedMissionCardMobile
