import EmbedSlide from "../../../models/slides/embedSlide"
import FullImageSlide from "../../../models/slides/fullImageSlide"
import FullTextSlide from "../../../models/slides/fullTextSlide"
import PortraitSlide from "../../../models/slides/portraitSlide"
import QuizSlide from "../../../models/slides/quizSlide"
import QuoteSlide from "../../../models/slides/quoteSlide"
import TextImageSlide from "../../../models/slides/textImageSlide"
import TitleSlide from "../../../models/slides/titleSlide"
import TitleLayout from "./slideLayouts/TitleLayout"
import FullTextLayout from "./slideLayouts/FullTextLayout"
import TextImageLayout from "./slideLayouts/TextImageLayout"
import PortraitLayout from "./slideLayouts/PortraitLayout"
import QuoteLayout from "./slideLayouts/QuoteLayout"
import FullImageLayout from "./slideLayouts/FullImageLayout"
import EmbedLayout from "./slideLayouts/EmbedLayout"
import Quiz4Layout from "./slideLayouts/Quiz4Layout"
import Quiz3Layout from "./slideLayouts/Quiz3Layout"
import Slide from "../../../models/slide"
import Episode from "../../../models/episode"
import QuizResult from "../../../models/quizResult"

const EpisodeBody = ({
  currentSlide,
  currentEpisode,
  isDailyEpisode,
  isLastEpisode,
  quizResult,
}: {
  currentSlide: Slide
  currentEpisode: Episode
  isDailyEpisode: boolean
  isLastEpisode: boolean
  quizResult?: QuizResult
}) => {
  return currentSlide.slideType === "TitleSlide" ? (
    <TitleLayout slide={currentSlide as TitleSlide} />
  ) : currentSlide.slideType === "FullTextSlide" ? (
    <FullTextLayout slide={currentSlide as FullTextSlide} />
  ) : currentSlide.slideType === "TextImageSlide" ? (
    <TextImageLayout slide={currentSlide as TextImageSlide} />
  ) : currentSlide.slideType === "PortraitSlide" ? (
    <PortraitLayout slide={currentSlide as PortraitSlide} />
  ) : currentSlide.slideType === "QuoteSlide" ? (
    <QuoteLayout slide={currentSlide as QuoteSlide} />
  ) : currentSlide.slideType === "FullImageSlide" ? (
    <FullImageLayout slide={currentSlide as FullImageSlide} />
  ) : currentSlide.slideType === "EmbedSlide" ? (
    <EmbedLayout slide={currentSlide as EmbedSlide} />
  ) : currentSlide.slideType === "QuizSlide" &&
    (currentSlide as QuizSlide).opt4 &&
    (currentSlide as QuizSlide).opt4?.text ? (
    <Quiz4Layout
      slide={currentSlide as QuizSlide}
      currentEpisode={currentEpisode}
      isDailyEpisode={isDailyEpisode}
      isLastEpisode={isLastEpisode}
      quizResult={quizResult}
    />
  ) : currentSlide.slideType === "QuizSlide" ? (
    <Quiz3Layout
      slide={currentSlide as QuizSlide}
      currentEpisode={currentEpisode}
      isDailyEpisode={isDailyEpisode}
      isLastEpisode={isLastEpisode}
      quizResult={quizResult}
    />
  ) : null
}

export default EpisodeBody
