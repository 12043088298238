import { Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import bellBigIcon from "../../../assets/icons/bell-big.svg"
import jarImage from "../../../assets/images/jar.svg"
import rollerbladeImage from "../../../assets/images/rollerblade.svg"
import spaceshipImage from "../../../assets/images/spaceship.svg"
import { Trans } from "react-i18next"
import FadeFromTop from "../../animations/FadeFromTop"
import { MissionPeriod } from "../../../services/config/enum"

const MissionInfoCardDesktop = ({
  missionsCompleted,
  missionsPeriod,
}: {
  missionsCompleted: number
  missionsPeriod: MissionPeriod
}) => {
  return (
    <FadeFromTop delay={0.075} y={-40}>
      <Stack
        alignItems="center"
        style={{
          width: "100%",
          maxWidth: 330,
          height: 427,
          backgroundColor: colors.background,
          borderRadius: 20,
          border: "2px solid " + colors.primary,
          paddingTop: 30,
          paddingInline: 30,
          position: "relative",
        }}
      >
        <img src={bellBigIcon} style={{ height: 42 }} alt="" />
        <Text
          fontSize={20}
          fontWeight={400}
          lineHeight="24px"
          textAlign="center"
          style={{ marginTop: 28 }}
        >
          <Trans
            i18nKey={
              missionsCompleted === 0
                ? "start_completing_missions"
                : missionsCompleted === 1
                ? "you_are_doing_great"
                : missionsPeriod === MissionPeriod.DAILY
                ? "daily_missions_completed"
                : missionsPeriod === MissionPeriod.WEEKLY
                ? "weekly_missions_completed"
                : "custom_missions_completed"
            }
          />
        </Text>
        <img
          src={
            missionsCompleted === 0
              ? jarImage
              : missionsCompleted === 1
              ? rollerbladeImage
              : spaceshipImage
          }
          style={{ height: 180, position: "absolute", bottom: 38 }}
          alt=""
        />
      </Stack>
    </FadeFromTop>
  )
}

export default MissionInfoCardDesktop
