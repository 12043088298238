import { CSSProperties } from "react"

const ActionDetailsBackgroundDesktop = ({
  color,
  style,
}: {
  color: string
  style?: CSSProperties
}) => {
  return (
    <svg
      width="100%"
      viewBox="0 0 1280 997"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      aria-hidden="true"
    >
      <mask
        id="mask0_1126_94"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1280"
        height="997"
      >
        <rect width="1280" height="997" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1126_94)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M717.569 41.3086C924.8 -77.9448 1155.9 89.0765 1331.27 251.525C1499.01 406.912 1663.37 619.855 1564.96 826.283C1470.11 1025.25 1203.66 1009.93 985.855 976.073C806.161 948.14 644.769 850.218 594.608 675.445C528.943 446.654 511.219 160.055 717.569 41.3086Z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default ActionDetailsBackgroundDesktop
