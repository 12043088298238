import { CSSProperties } from "react"
import Text from "../common/Text"
import { ButtonBase, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { desktopMaxWidth } from "../../../services/config/constants"
import PaddingContainerDesktop from "./PaddingContainerDesktop"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"

const BreadcrumbDesktop = ({
  items,
  selectedItem,
  textColor = colors.text,
  style,
  withPadding = true,
  withBackground = false,
}: {
  items: { label: string; onClick?: () => void }[]
  selectedItem: number
  textColor?: string
  style?: CSSProperties
  withPadding?: boolean
  withBackground?: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <PaddingContainerDesktop disabled={!withPadding}>
      <div style={{ width: "100%", minHeight: 3 }} />
      <div
        style={{
          width: "100%",
          maxWidth: withPadding ? desktopMaxWidth : undefined,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "fit-content",
            alignSelf: "flex-start",
            height: 40,
            gap: 4,
            backgroundColor: withBackground ? colors.backgroundWhite : "none",
            borderRadius: 4,
            border: withBackground
              ? "1.5px solid " + colors.stroke
              : "1.5px solid rgba(0, 0, 0, 0)",
            ...style,
          }}
        >
          <ButtonBase
            disableRipple
            style={{
              width: "auto",
              height: 24,
              paddingInline: 4,
              position: "relative",
              zIndex: 2,
            }}
            onClick={() => {
              navigate("/")
            }}
            aria-label={t("go_to_page", { page: t("home") })}
          >
            <Text
              fontSize={20}
              lineHeight="24px"
              fontWeight={400}
              color={textColor}
            >
              {t("home")}
            </Text>
          </ButtonBase>
          <Text
            fontSize={20}
            lineHeight="24px"
            fontWeight={400}
            color={textColor}
          >
            |
          </Text>
          {items.map((item, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              style={{ width: "auto", height: 24, gap: 4 }}
            >
              {index !== 0 ? (
                <Text
                  fontSize={20}
                  lineHeight="24px"
                  fontWeight={400}
                  color={textColor}
                >
                  |
                </Text>
              ) : null}
              <ButtonBase
                aria-current={selectedItem === index ? "page" : "false"}
                disableRipple
                style={{
                  width: "auto",
                  height: 24,
                  paddingInline: 4,
                  position: "relative",
                  zIndex: 2,
                  cursor: selectedItem === index ? "default" : "pointer",
                }}
                onClick={item.onClick}
                aria-label={t("go_to_page", { page: item.label })}
              >
                <Text
                  fontSize={20}
                  lineHeight="24px"
                  fontWeight={selectedItem === index ? 700 : 400}
                  color={textColor}
                >
                  {item.label}
                </Text>
              </ButtonBase>
            </Stack>
          ))}
        </Stack>
      </div>
      <div style={{ width: "100%", minHeight: 3 }} />
    </PaddingContainerDesktop>
  )
}

export default BreadcrumbDesktop
