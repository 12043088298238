import { colors } from "../../../services/config/colors"
import closeWhiteIcon from "../../../assets/icons/close-white.svg"
import { ButtonBase, Stack } from "@mui/material"
import {
  desktopMaxWidth,
  desktopPadding,
} from "../../../services/config/constants"
import { useTranslation } from "react-i18next"
import { useContext, useMemo, useState } from "react"
import { MainContext } from "../../../controllers/main"
import LanguageSelect from "./LanguageSelect"
import Alert from "./Alert"

const CloseViewHeader = () => {
  const { t } = useTranslation()
  const {
    isMobile,
    visualizingErrorPage,
    visualizingLoadingPage,
    loading,
    signUpError,
    userError,
    viewOnboarding,
    viewTutorial,
    changeLang,
  } = useContext(MainContext)

  // hide or not language select
  const hideLanguageSelect = useMemo(() => {
    if (
      visualizingErrorPage ||
      visualizingLoadingPage ||
      loading ||
      signUpError ||
      userError ||
      viewOnboarding ||
      viewTutorial
    ) {
      return true
    }

    return false
  }, [
    visualizingErrorPage,
    visualizingLoadingPage,
    loading,
    signUpError,
    userError,
    viewOnboarding,
    viewTutorial,
  ])

  // local lang to set before user confirms change
  const [localLang, setLocalLang] = useState<string>("")

  // language change alert
  const [langChangeAlertOpen, setLangChangeAlertOpen] = useState<boolean>(false)

  // loading for lang change
  const [changingLang, setChangingLang] = useState<boolean>(false)

  // exit alert
  const [exitAlertOpen, setExitAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: 50,
        position: "fixed",
        top: 0,
        backgroundColor: colors.primary,
        zIndex: 1000000,
        paddingInline: isMobile
          ? 16
          : window.location.pathname.includes("/journey/") ||
            window.location.pathname.includes("/dailyepisode/") ||
            viewOnboarding ||
            viewTutorial ||
            visualizingErrorPage ||
            visualizingLoadingPage
          ? 30
          : desktopPadding,
        transition: "250ms",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        style={{
          width: "100%",
          maxWidth: isMobile
            ? "100%"
            : window.location.pathname.includes("/journey/") ||
              window.location.pathname.includes("/dailyepisode/") ||
              viewOnboarding ||
              viewTutorial ||
              visualizingErrorPage ||
              visualizingLoadingPage
            ? "100%"
            : desktopMaxWidth,
          transition: "250ms",
          position: "relative",
        }}
      >
        {hideLanguageSelect ? null : (
          <LanguageSelect
            setLangChangeAlertOpen={setLangChangeAlertOpen}
            setLocalLang={setLocalLang}
            style={{ position: "absolute", right: 0 }}
          />
        )}
      </Stack>
      {/* change language alert */}
      <Alert
        open={langChangeAlertOpen}
        title={t("change_language_alert_title")}
        description={t("change_language_alert_description")}
        primaryActionLabel={t("confirm")}
        primaryActionOnClick={async () => {
          setChangingLang(true)

          const result = await changeLang(localLang)

          if (result) {
            window.location.reload()
          } else {
            setChangingLang(false)
          }
        }}
        secondaryActionLabel={t("cancel")}
        secondaryActionOnClick={() => {
          setLangChangeAlertOpen(false)
        }}
        isSecondaryActionPrimary={false}
        loading={changingLang}
      />
      <Alert
        open={exitAlertOpen}
        title={t("exit_alert_title")}
        description={t("exit_alert_description")}
        primaryActionLabel={t("cancel")}
        primaryActionOnClick={() => {
          setExitAlertOpen(false)
        }}
        secondaryActionLabel={t("exit")}
        isSecondaryActionPrimary={false}
      />
    </Stack>
  )
}

export default CloseViewHeader
