import { Textfit } from "react-textfit"
import TextImageSlide from "../../../../models/slides/textImageSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"

const TextImageLayout = ({ slide }: { slide: TextImageSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 20,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Box slide={slide} />
        {slide.image && slide.image.type !== "XL" ? (
          <Image slide={slide} />
        ) : null}
      </div>
      <Decor slide={slide} />
      {slide.image && slide.image.type === "XL" ? (
        <Image slide={slide} />
      ) : null}
    </div>
  )
}

const Box = ({ slide }: { slide: TextImageSlide }) => {
  return (
    <div
      style={{
        backgroundColor:
          slide.box && slide.box.boxBackColor
            ? slide.box.boxBackColor
            : undefined,
        width: `calc(${slideWidth}px / ${numberOfColumns} * 13)`,
        bottom: `calc(${slideHeight}px / ${numberOfRows} * 6)`,
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 10)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
      }}
    >
      <Textfit
        style={{
          color: slide.textColor,
          lineHeight: 1.2,
          fontWeight: 400,
          marginTop: slide.decor
            ? `calc(${slideHeight}px / ${numberOfRows} * 4)`
            : `calc(${slideHeight}px / ${numberOfRows} * 2)`,
          marginLeft: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          marginRight: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          height:
            slide.image && slide.image.type === "S"
              ? slide.decor
                ? `calc(${slideHeight}px / ${numberOfRows} * 11.5)`
                : `calc(${slideHeight}px / ${numberOfRows} * 13.5)`
              : slide.decor
              ? `calc(${slideHeight}px / ${numberOfRows} * 9.5)`
              : `calc(${slideHeight}px / ${numberOfRows} * 11.5)`,
        }}
        min={18}
        max={60}
        mode="multi"
      >
        <div dangerouslySetInnerHTML={{ __html: slide.text }} />
      </Textfit>
    </div>
  )
}

const Image = ({ slide }: { slide: TextImageSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        top:
          slide.image.type === "S"
            ? `calc(${slideHeight}px / ${numberOfRows} * 27)`
            : slide.image.type === "XL"
            ? `calc(100% / 2 + 47px)`
            : `calc(${slideHeight}px / ${numberOfRows} * 25)`,
        left:
          slide.image.type === "S"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 2)`
            : slide.image.type === "M"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 1)`
            : `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
        right:
          slide.image.type === "S"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 2)`
            : slide.image.type === "M"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 1)`
            : `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
        bottom:
          slide.image.type === "S"
            ? `calc(${slideHeight}px / ${numberOfRows} * 7)`
            : slide.image.type === "M"
            ? `calc(${slideHeight}px / ${numberOfRows} * 6)`
            : slide.image.type === "L"
            ? `calc(${slideHeight}px / ${numberOfRows} * 5)`
            : `calc(${slideHeight}px / ${numberOfRows} * 0)`,
        backgroundImage: `url(${slide.image.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    />
  )
}

const Decor = ({ slide }: { slide: TextImageSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: 0,
        paddingBottom: 355,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
          left: `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
          height: `calc(${slideHeight}px / ${numberOfRows} * 7)`,
          backgroundImage: `url(${slide.decor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  )
}

export default TextImageLayout
