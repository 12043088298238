import { useEffect } from "react"
import LeaderboardDesktop from "../../components/leaderboard/LeaderboardDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Leaderboard = () => {
  // register google analytics event
  useEffect(() => {
    registerPageEvent("leaderboard")
  }, [])

  return <LeaderboardDesktop />
}

export default Leaderboard
