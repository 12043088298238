import { ButtonBase, Stack } from "@mui/material"
import { useContext } from "react"
import { Textfit } from "react-textfit"
import { JourneysContext } from "../../../../controllers/journeys"
import QuizSlide from "../../../../models/slides/quizSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"
import closeCircleRedIcon from "../../../../assets/icons/close-circle-red.png"
import checkCircleGreenIcon from "../../../../assets/icons/check-circle-green.png"
import { colors } from "../../../../services/config/colors"
import Episode from "../../../../models/episode"
import QuizResult from "../../../../models/quizResult"
import { useTranslation } from "react-i18next"

const Quiz4Layout = ({
  slide,
  currentEpisode,
  isDailyEpisode,
  isLastEpisode,
  quizResult,
}: {
  slide: QuizSlide
  currentEpisode: Episode
  isDailyEpisode: boolean
  isLastEpisode: boolean
  quizResult?: QuizResult
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Box slide={slide} />
        <Option
          currentEpisode={currentEpisode}
          slide={slide}
          option="opt1"
          top={`calc(${slideHeight}px / ${numberOfRows} * 20)`}
          left={`calc(${slideWidth}px / ${numberOfColumns} * 2)`}
          right={`calc(${slideWidth}px / ${numberOfColumns} * 8)`}
          quizResult={quizResult}
          isDailyEpisode={isDailyEpisode}
          isLastEpisode={isLastEpisode}
        />
        <Option
          currentEpisode={currentEpisode}
          slide={slide}
          option="opt2"
          top={`calc(${slideHeight}px / ${numberOfRows} * 20)`}
          left={`calc(${slideWidth}px / ${numberOfColumns} * 8)`}
          right={`calc(${slideWidth}px / ${numberOfColumns} * 2)`}
          quizResult={quizResult}
          isDailyEpisode={isDailyEpisode}
          isLastEpisode={isLastEpisode}
        />
        <Option
          currentEpisode={currentEpisode}
          slide={slide}
          option="opt3"
          top={`calc(${slideHeight}px / ${numberOfRows} * 28)`}
          left={`calc(${slideWidth}px / ${numberOfColumns} * 2)`}
          right={`calc(${slideWidth}px / ${numberOfColumns} * 8)`}
          quizResult={quizResult}
          isDailyEpisode={isDailyEpisode}
          isLastEpisode={isLastEpisode}
        />
        <Option
          currentEpisode={currentEpisode}
          slide={slide}
          option="opt4"
          top={`calc(${slideHeight}px / ${numberOfRows} * 28)`}
          left={`calc(${slideWidth}px / ${numberOfColumns} * 8)`}
          right={`calc(${slideWidth}px / ${numberOfColumns} * 2)`}
          quizResult={quizResult}
          isDailyEpisode={isDailyEpisode}
          isLastEpisode={isLastEpisode}
        />
      </div>
      <Decor slide={slide} />
    </div>
  )
}

const Box = ({ slide }: { slide: QuizSlide }) => {
  return (
    <div
      style={{
        backgroundColor: slide.box ? slide.box.boxBackColor : undefined,
        bottom: `calc(${slideHeight}px / ${numberOfRows} * 6)`,
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 9)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
      }}
    >
      <Textfit
        style={{
          color: slide.textColor,
          lineHeight: 1.3,
          fontWeight: 600,
          paddingLeft: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          paddingRight: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          marginTop: `calc(${slideHeight}px / ${numberOfRows} * 2.5)`,
          height: `calc(${slideHeight}px / ${numberOfRows} * 6)`,
          width: "100%",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
        }}
        min={19}
        max={80}
        mode="multi"
      >
        <div dangerouslySetInnerHTML={{ __html: slide.question }} />
      </Textfit>
    </div>
  )
}

const Option = ({
  currentEpisode,
  slide,
  option,
  top,
  left,
  right,
  quizResult,
  isDailyEpisode,
  isLastEpisode,
}: {
  currentEpisode: Episode
  slide: QuizSlide
  option: "opt1" | "opt2" | "opt3" | "opt4"
  top: string
  left: string
  right: string
  quizResult?: {
    opt1: number
    opt2: number
    opt3: number
    opt4: number
    outcome: string
    slideId: string
  }
  isDailyEpisode: boolean
  isLastEpisode: boolean
}) => {
  const { t } = useTranslation()
  const { submitSurvey } = useContext(JourneysContext)

  return (
    <ButtonBase
      disabled={quizResult !== undefined}
      style={{
        position: "absolute",
        top: top,
        left: left,
        right: right,
        height: `calc(${slideHeight}px / ${numberOfRows} * 7)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 2,
      }}
      onClick={() => {
        submitSurvey(
          currentEpisode,
          option,
          slide,
          isDailyEpisode,
          isLastEpisode
        )
      }}
      aria-label={
        quizResult && quizResult[option] && slide.correctAnswer === option
          ? t("your_correct_answer") + slide[option]!.text
          : quizResult && quizResult[option]
          ? t("your_incorrect_answer") + slide[option]!.text
          : quizResult && slide.correctAnswer === option
          ? t("correct_answer") + slide[option]!.text
          : slide[option]!.text
      }
      aria-live={quizResult && quizResult[option] ? "assertive" : "off"}
    >
      {quizResult && slide.correctAnswer === option ? (
        <img
          src={checkCircleGreenIcon}
          style={{
            position: "absolute",
            right: 5,
            bottom: 15.5,
            width: 18,
            height: 18,
          }}
          alt=""
        />
      ) : null}
      {quizResult && quizResult[option] && slide.correctAnswer !== option ? (
        <img
          src={closeCircleRedIcon}
          style={{
            position: "absolute",
            right: 5,
            bottom: 15.5,
            width: 18,
            height: 18,
          }}
          alt=""
        />
      ) : null}
      <Stack>
        <img
          src={slide[option]!.image!}
          style={{
            width: `calc(${slideWidth}px / ${numberOfColumns} * 5)`,
            height: `calc(${slideHeight}px / ${numberOfRows} * 4)`,
            objectFit: "cover",
          }}
          alt=""
        />
        <Textfit
          style={{
            color:
              (quizResult && slide.correctAnswer === option) ||
              (quizResult &&
                quizResult[option] &&
                slide.correctAnswer !== option)
                ? colors.textWhite
                : slide.textColor,
            lineHeight: 1.3,
            fontWeight: 400,
            height: `calc(${slideHeight}px / ${numberOfRows} * 3)`,
            width: `calc(${slideWidth}px / ${numberOfColumns} * 5)`,
            paddingInline: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
            paddingTop: `calc(${slideHeight}px / ${numberOfRows} * 0.5)`,
            paddingBottom: `calc(${slideHeight}px / ${numberOfRows} * 0.5)`,
            boxSizing: "border-box",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "0.5px solid " + slide.textColor,
            backgroundColor:
              quizResult && slide.correctAnswer === option
                ? colors.success
                : quizResult &&
                  quizResult[option] &&
                  slide.correctAnswer !== option
                ? colors.error
                : slide.box
                ? slide.box.boxBackColor
                : undefined,
          }}
          min={14}
          max={20}
          mode="multi"
        >
          {slide[option]!.text}
        </Textfit>
      </Stack>
    </ButtonBase>
  )
}

const Decor = ({ slide }: { slide: QuizSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: 0,
        paddingBottom: 420,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          height: `calc(${slideHeight}px / ${numberOfRows} * 6)`,
          backgroundImage: `url(${slide.decor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  )
}

export default Quiz4Layout
