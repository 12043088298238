import { Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import { useTranslation } from "react-i18next"

const LeaderboardListItemCard = ({
  place,
  name,
  points,
  isUser,
  profileImage,
}: {
  place: number
  name: string
  points: number
  isUser: boolean
  profileImage: string
}) => {
  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "100%",
        height: 80,
        borderRadius: 14,
        backgroundColor: isUser ? colors.background : colors.backgroundWhite,
        boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
        paddingInline: 30,
        position: "relative",
      }}
    >
      <div
        style={{
          width: "auto",
          height: 40,
          paddingInline: 12,
          borderRadius: 5,
          backgroundColor: colors.primary,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Title fontSize={16} color={colors.textWhite}>
          {place}°
        </Title>
      </div>
      <div
        style={{
          width: 56,
          height: 56,
          borderRadius: "100%",
          marginLeft: 30,
          backgroundImage: `url(${profileImage}), url(https://cdn.aworld.io/users/default/profile.jpg)`,
          backgroundSize: "105%, 100%",
          backgroundPosition: "center",
        }}
      />
      <Title
        fontSize={20}
        color={isUser ? colors.primary : colors.text}
        ellipsis
        style={{
          width: "calc(100% - 250px)",
          marginLeft: 26,
        }}
      >
        {isUser ? t("you") : name}
      </Title>
      <Title
        fontSize={20}
        color={isUser ? colors.primary : colors.text}
        style={{
          position: "absolute",
          right: 30,
        }}
      >
        {points} {t("points")}
      </Title>
    </Stack>
  )
}

export default LeaderboardListItemCard
