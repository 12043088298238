import FullImageSlide from "../../../../models/slides/fullImageSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"

const FullImageLayout = ({ slide }: { slide: FullImageSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Image slide={slide} />
        <CaptionTitle slide={slide} />
        <Caption slide={slide} />
      </div>
    </div>
  )
}

const Image = ({ slide }: { slide: FullImageSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 11)`,
        bottom: `calc(${slideHeight}px / ${numberOfRows} * 11)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        backgroundImage: slide.image ? `url(${slide.image.url})` : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
  )
}

const CaptionTitle = ({ slide }: { slide: FullImageSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 33)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        height: `calc(${slideHeight}px / ${numberOfRows} * 1)`,
        fontWeight: 600,
        fontSize: 18,
        lineHeight: "17px",
        color: slide.textColor,
      }}
    >
      {slide.captionTitle}
    </div>
  )
}

const Caption = ({ slide }: { slide: FullImageSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 34.2)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        fontWeight: 300,
        fontSize: 16,
        lineHeight: "17px",
        color: slide.textColor,
      }}
    >
      {slide.caption}
    </div>
  )
}

export default FullImageLayout
