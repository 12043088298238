import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import episodeSuccededImage from "../../../assets/images/episode-succeded.png"
import Confetti from "../../global/common/Confetti"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"

const DailyEpisodeFeedback = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        paddingTop: 120,
        position: "relative",
        overflow: "hidden",
        backgroundColor: colors.backgroundWhite,
      }}
    >
      <Stack alignItems="center" style={{ gap: 30 }}>
        <Title
          fontSize={26}
          textAlign="center"
          paddingLeft={32}
          paddingRight={32}
        >
          {t("daily_episode_completed")}
        </Title>
        <img
          src={episodeSuccededImage}
          style={{ width: 190, height: 190 }}
          alt=""
        />
      </Stack>
      <Confetti />
    </div>
  )
}

export default DailyEpisodeFeedback
