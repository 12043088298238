import { useContext, useEffect, useMemo, useRef, useState } from "react"
import FeedbackContainer from "../../feedbacks/common/FeedbackContainer"
import { ButtonBase, Stack } from "@mui/material"
import { MainContext } from "../../../controllers/main"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import Button from "../../global/common/Button"
import { t } from "i18next"
import chevronRightBigPrimaryIcon from "../../../assets/icons/chevron-right-big-primary.svg"
import chevronLeftBigPrimaryIcon from "../../../assets/icons/chevron-left-big-primary.svg"
import { capitalizeFirstCharacter } from "../../../services/utils/utils"
import { Trans } from "react-i18next"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"
import { defaultBoxShadowDarker } from "../../../services/config/constants"
import closeIcon from "../../../assets/icons/close.png"
import { MissionsContext } from "../../../controllers/missions"
import { MissionType } from "../../../services/config/enum"
import infoIconPrimary from "../../../assets/icons/info-primary.svg"
import { ChallengeContext } from "../../../controllers/challenge"

const TutorialAlertMobile = () => {
  const {
    viewTutorial,
    setViewTutorial,
    currentTutorialPage,
    setCurrentTutorialPage,
    user,
    setUserFirstAccess,
  } = useContext(MainContext)
  const { setCurrentSlide, missions } = useContext(MissionsContext)
  const { challenge } = useContext(ChallengeContext)

  // loading
  const [loading, setLoading] = useState<boolean>(false)

  // close message to quit tutorial
  const [closeMessageVisible, setCloseMessageVisible] = useState<boolean>(false)

  // do not show tutorial again
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(false)

  // can click continue button
  const canClick = useRef<boolean>(true)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  // array of elements to change style of
  const elements = useRef<HTMLElement[]>([])

  useEffect(() => {
    elements.current = [
      document.getElementById("challenge-bottom-sheet")
        ?.childNodes[0] as HTMLElement,
      document.getElementById("carousel-container-mobile") as HTMLElement,
      document.getElementById("performance-button") as HTMLElement,
    ]

    setCloseMessageVisible(false)
    setCurrentTutorialPage(0)
  }, [viewTutorial])

  // set every element zIndex to 0
  const cleanZIndexes = () => {
    elements.current.forEach((item) => {
      item.style.zIndex = ""
    })
  }

  // on click functions
  const onClickFunctions = [
    () => {
      // re-assign challenge card element to elements array in case it's null
      elements.current[0] = document.getElementById("challenge-bottom-sheet")
        ?.childNodes[0] as HTMLElement

      // clean all zIndexes
      cleanZIndexes()

      // get challenge card and change its zIndex
      const challengeCard = elements.current[0]
      challengeCard.style.zIndex = "150"

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.top = "84px"

      // change current page
      setCurrentTutorialPage(1)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get missions carousel container and change its zIndex
      const carouselContainer = elements.current[1]
      carouselContainer.style.zIndex = "150"

      // scroll to first mission
      setCurrentSlide(0)

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.top = ""
      tutorialContainer.style.bottom = "0px"

      // change current page
      setCurrentTutorialPage(2)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get missions carousel container and change its zIndex
      const carouselContainer = elements.current[1]
      carouselContainer.style.zIndex = "150"

      // scroll to third mission
      setCurrentSlide(2)

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.top = ""
      tutorialContainer.style.bottom = "0px"

      // change current page
      setCurrentTutorialPage(3)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get missions carousel container and change its zIndex
      const carouselContainer = elements.current[1]
      carouselContainer.style.zIndex = "150"

      // scroll to third mission
      setCurrentSlide(2)

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.top = ""
      tutorialContainer.style.bottom = "0px"

      // change current page
      setCurrentTutorialPage(4)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get missions carousel container and change its zIndex
      const carouselContainer = elements.current[1]
      carouselContainer.style.zIndex = "150"

      // scroll to act mission
      setCurrentSlide(
        missions.findIndex((mission) => mission.missionType === MissionType.ACT)
      )

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.top = ""
      tutorialContainer.style.bottom = "0px"

      // change current page
      setCurrentTutorialPage(5)
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get missions carousel container and change its zIndex
      const carouselContainer = elements.current[1]
      carouselContainer.style.zIndex = "150"

      // scroll to learn mission
      setCurrentSlide(
        missions.findIndex(
          (mission) => mission.missionType === MissionType.LEARN
        )
      )

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.top = ""
      tutorialContainer.style.bottom = "0px"

      // change current page
      setCurrentTutorialPage(6)
    },
    ...(missions.some((mission) => mission.missionType === MissionType.MEASURE)
      ? [
          () => {
            // clean all zIndexes
            cleanZIndexes()

            // get missions carousel container and change its zIndex
            const carouselContainer = elements.current[1]
            carouselContainer.style.zIndex = "150"

            // scroll to measure mission
            setCurrentSlide(
              missions.findIndex(
                (mission) => mission.missionType === MissionType.MEASURE
              )
            )

            // move tutorial container
            const tutorialContainer =
              document.getElementById("tutorial-container")!
            tutorialContainer.style.top = ""
            tutorialContainer.style.bottom = "0px"

            // change current page
            setCurrentTutorialPage(7)
          },
        ]
      : []),
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // get performance button and change its zIndex
      const performanceButton = elements.current[2]
      performanceButton.style.zIndex = "150"

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.top = ""
      tutorialContainer.style.bottom = "0px"

      // change current page
      setCurrentTutorialPage(
        missions.some((mission) => mission.missionType === MissionType.MEASURE)
          ? 8
          : 7
      )
    },
    () => {
      // clean all zIndexes
      cleanZIndexes()

      // move tutorial container
      const tutorialContainer = document.getElementById("tutorial-container")!
      tutorialContainer.style.top = ""
      tutorialContainer.style.bottom = ""

      // change current page
      setCurrentTutorialPage(
        missions.some((mission) => mission.missionType === MissionType.MEASURE)
          ? 9
          : 8
      )
    },
  ]

  // tutorial slides
  const slides = [
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">{t("onboarding_welcome")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="tour_description">
          Siamo molto felici che tu sia qui con noi!
          <br />
          Scopri come aiutare Electrolux a{" "}
          <strong style={{ fontWeight: 500 }}>completare missioni</strong> e
          raggiungere l’obiettivo della challenge.
        </Trans>
      </Text>
      <Button
        title={t("start_tour")}
        width="100%"
        onClick={onClickFunctions[currentTutorialPage]}
      >
        {t("start_tour")}
      </Button>
      {user?.firstAccess ? (
        <ButtonBase
          disableRipple
          style={{ width: "100%", height: 24 }}
          onClick={() => {
            setDoNotShowAgain((current) => !current)
          }}
          aria-live="assertive"
          role="checkbox"
          aria-checked={doNotShowAgain ? "true" : "false"}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <div
              style={{
                width: 26,
                height: 26,
                backgroundColor: doNotShowAgain
                  ? colors.primary
                  : colors.backgroundWhite,
                border: "1.5px solid " + colors.primary,
                borderRadius: "100%",
                transition: "150ms",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={checkWhiteIcon} style={{ width: 14 }} alt="" />
            </div>
            <Title fontSize={14} lineHeight="16px" color={colors.primary}>
              {t("do_not_show_again")}
            </Title>
          </Stack>
        </ButtonBase>
      ) : null}
    </Stack>,
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">
        {capitalizeFirstCharacter(t("the_collective_challenge").toLowerCase())}
      </Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="challenge_description" count={challenge?.targetAmount}>
          Electrolux vuole raggiungere un obiettivo comune:
          <br />
          completare <strong style={{ fontWeight: 600 }}>n missioni</strong> per
          il Pianeta.
          <br />
          <br />
          <strong style={{ fontWeight: 600 }}>
            Ogni missione completata aumenta la progressione
          </strong>{" "}
          della Challenge.
          <br />
          <br />
          Guarda l’avanzamento direttamente dalla Home o dalla sezione
          Challenge.
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">{t("what_are_missions_extended")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="what_are_missions_description">
          <strong style={{ fontWeight: 500 }}>
            Semplici attività a tema sostenibilità
          </strong>{" "}
          da completare con una certa frequenza (giornaliera, settimanale,
          speciale) che ti permettono di{" "}
          <strong style={{ fontWeight: 500 }}>ottenere punti</strong> e
          contribuire alla challenge.
          <br />
          <br />
          Esistono 3 tipologie di missioni:{" "}
          <strong style={{ fontWeight: 500 }}>Act, Learn & Measure.</strong>
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">{t("how_to_complete_missions")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="how_to_complete_missions_description_1">
          <strong style={{ fontWeight: 500 }}>Clicca sulla card</strong> per
          accedere al contenuto della missione. Alcune missioni richiedono di
          compiere attività più volte.
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">{t("how_to_complete_missions")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="how_to_complete_missions_description_2">
          Controlla il <strong style={{ fontWeight: 500 }}>contatore</strong>{" "}
          delle singole missioni per scoprire quanto manca al{" "}
          <strong style={{ fontWeight: 500 }}>completamento.</strong>
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">{t("act")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="act_missions_description">
          Migliora il tuo stile di vita,{" "}
          <strong style={{ fontWeight: 500 }}>esplora</strong> le azioni
          suggerite e <strong style={{ fontWeight: 500 }}>registra</strong>{" "}
          quelle che hai fatto.
        </Trans>
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">{t("learn")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="learn_missions_description">
          <strong style={{ fontWeight: 500 }}>Leggi gli episodi</strong>{" "}
          consigliati e{" "}
          <strong style={{ fontWeight: 500 }}>completa i quiz</strong> sui temi
          della sostenibilità
        </Trans>
      </Text>
    </Stack>,
    ...(missions.some((mission) => mission.missionType === MissionType.MEASURE)
      ? [
          <Stack alignItems="center" style={{ gap: 20 }}>
            <Title textAlign="center">{t("measure")}</Title>
            <Text
              fontSize={18}
              lineHeight="24px"
              fontWeight={300}
              textAlign="center"
            >
              <Trans i18nKey="measure_missions_description">
                Rispondi ad alcune{" "}
                <strong style={{ fontWeight: 500 }}>domande</strong> per
                completare il{" "}
                <strong style={{ fontWeight: 500 }}>
                  calcolo della tua Carbon Footprint.
                </strong>
              </Trans>
            </Text>
          </Stack>,
        ]
      : []),
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">{t("points_and_performance")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        {t("points_and_performance_description")}
      </Text>
    </Stack>,
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title textAlign="center">{t("tour_completed")}</Title>
      <Text fontSize={18} lineHeight="24px" fontWeight={300} textAlign="center">
        <Trans i18nKey="tour_completed_description">
          Ora sai tutto!
          <br />
          Se hai dubbi, clicca sull'icona{" "}
          <strong style={{ fontWeight: 500 }}>informazioni</strong> o accedi a
          questo tutorial dal menù.
        </Trans>
      </Text>
    </Stack>,
  ]

  // control buttons disabled
  const previousSlideButtonDisabled = useMemo(() => {
    if (currentTutorialPage <= 1) {
      return true
    }

    return false
  }, [currentTutorialPage])

  const nextSlideButtonDisabled = useMemo(() => {
    if (currentTutorialPage >= slides.length - 1) {
      return true
    }

    return false
  }, [currentTutorialPage])

  // focus correct controls when current page changes
  useEffect(() => {
    const controls = document.getElementById("tutorial-controls")
    if (controls) {
      if (17 * (currentTutorialPage - 1) - controls.scrollLeft === 34) {
        controls.scrollTo({
          left: controls.scrollLeft + 17,
          behavior: "smooth",
        })
      } else if (17 * (currentTutorialPage - 1) - controls.scrollLeft === 0) {
        controls.scrollTo({
          left: controls.scrollLeft - 17,
          behavior: "smooth",
        })
      }
    }
  }, [currentTutorialPage])

  return (
    <FeedbackContainer
      open={viewTutorial}
      setOpen={setViewTutorial}
      withCloseButton={false}
      withPadding={false}
      withBackground={false}
      forTutorial
    >
      {/* close button */}
      {!closeMessageVisible && currentTutorialPage !== slides.length - 1 ? (
        <ButtonBase
          style={{
            width: 36,
            height: 36,
            borderRadius: "100%",
            position: "absolute",
            top: currentTutorialPage === slides.length - 2 ? 84 : 24,
            right: 18,
            backgroundColor: colors.backgroundWhite,
            boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
            transition: "200ms",
          }}
          title={t("close")}
          onClick={() => {
            // re-assign challenge card element to elements array in case it's null
            elements.current[0] = document.getElementById(
              "challenge-bottom-sheet"
            )?.childNodes[0] as HTMLElement

            // clean zIndexes and make close message appear
            cleanZIndexes()
            setCloseMessageVisible(true)

            // move tutorial container to the center
            const tutorialContainer =
              document.getElementById("tutorial-container")!
            tutorialContainer.style.top = ""
            tutorialContainer.style.bottom = ""
          }}
        >
          <img src={closeIcon} style={{ width: 10, height: 10 }} alt="" />
        </ButtonBase>
      ) : null}
      {/* tutorial content and controls */}
      <Stack
        id="tutorial-container"
        alignItems="center"
        style={{ width: "100%", position: "absolute", transition: "300ms" }}
        gap={2}
      >
        {/* content */}
        <Stack
          alignItems="center"
          style={{
            width:
              currentTutorialPage > 1 &&
              currentTutorialPage < slides.length - 1 &&
              !closeMessageVisible
                ? "100%"
                : "calc(100% - 48px)",
            height: "auto",
            backgroundColor: colors.backgroundWhite,
            borderRadius: 30,
            borderBottomLeftRadius:
              currentTutorialPage > 1 &&
              !closeMessageVisible &&
              currentTutorialPage < slides.length - 1
                ? 0
                : 30,
            borderBottomRightRadius:
              currentTutorialPage > 1 &&
              !closeMessageVisible &&
              currentTutorialPage < slides.length - 1
                ? 0
                : 30,
            paddingTop: closeMessageVisible ? 28 : 50,
            paddingBottom: 22,
            paddingInline: 22,
            transition: "top 150ms, bottom 150ms",
          }}
        >
          {!closeMessageVisible && (
            <img
              src={user?.profileImage}
              style={{
                width: 64,
                height: 64,
                objectFit: "cover",
                objectPosition: "center",
                position: "absolute",
                top: -32,
                borderRadius: "100%",
                boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
              }}
              alt=""
            />
          )}
          {closeMessageVisible ? (
            <Stack alignItems="center" style={{ gap: 14 }}>
              <Title textAlign="center">{t("close_tour")}</Title>
              <Text
                fontSize={18}
                lineHeight="24px"
                fontWeight={300}
                textAlign="center"
              >
                <Trans i18nKey="close_tour_description">
                  Se hai dubbi, clicca sull'
                  <strong style={{ fontWeight: 600 }}>
                    icona informazioni
                  </strong>{" "}
                  o accedi a questo tutorial dal menù.
                </Trans>
              </Text>
              <div
                className="center"
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 5,
                  border: "1px solid " + colors.primary,
                  marginTop: 10,
                }}
              >
                <img src={infoIconPrimary} style={{ width: 16 }} alt="" />
              </div>
              <Button
                title={t("confirm")}
                width="100%"
                style={{ marginTop: 10 }}
                loading={loading}
                onClick={async () => {
                  if (user?.firstAccess && doNotShowAgain) {
                    setLoading(true)
                    await setUserFirstAccess()
                  }

                  setViewTutorial(false)
                }}
              >
                {t("confirm")}
              </Button>
              <Button
                outlined
                title={t("cancel")}
                width="100%"
                disabled={loading}
                onClick={() => {
                  setCloseMessageVisible(false)
                  if (currentTutorialPage > 0) {
                    onClickFunctions[currentTutorialPage - 1]()
                  }
                }}
              >
                {t("cancel")}
              </Button>
            </Stack>
          ) : (
            slides[currentTutorialPage]
          )}
        </Stack>
        {/* controls */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{
            width: "100%",
            gap: 18,
            backgroundColor:
              currentTutorialPage > 1 && currentTutorialPage < slides.length - 1
                ? colors.backgroundWhite
                : "rgba(0, 0, 0, 0)",
            marginTop:
              currentTutorialPage > 1 && currentTutorialPage < slides.length - 1
                ? -18
                : 0,
            paddingBottom:
              currentTutorialPage > 1 && currentTutorialPage < slides.length - 1
                ? 20
                : 0,
            zIndex:
              currentTutorialPage !== 0 && !closeMessageVisible ? 1 : -100,
            opacity: currentTutorialPage !== 0 && !closeMessageVisible ? 1 : 0,
          }}
          role="group"
          aria-label={t("carousel_controls")}
        >
          <ButtonBase
            style={{
              width: 36,
              minWidth: 36,
              height: 36,
              borderRadius: 30,
              backgroundColor: colors.backgroundWhite,
              boxShadow: defaultBoxShadowDarker,
            }}
            disabled={previousSlideButtonDisabled || loading}
            onClick={() => {
              if (canClick.current) {
                onClickFunctions[currentTutorialPage - 2]()

                canClick.current = false
                const timeoutId = setTimeout(() => {
                  canClick.current = true
                  clearTimeout(timeoutId)
                }, 200)
              }
            }}
            aria-label={t("previous_slide")}
            aria-disabled={previousSlideButtonDisabled || loading}
            onFocusVisible={() => {
              screenReaderEnabled.current = true
            }}
            onBlur={() => {
              screenReaderEnabled.current = false
            }}
          >
            <img
              src={chevronLeftBigPrimaryIcon}
              style={{
                height: 36,
                transition: "150ms",
                opacity: previousSlideButtonDisabled ? 0.4 : 1,
              }}
              alt=""
            />
          </ButtonBase>
          <Stack
            id="tutorial-controls"
            direction="row"
            alignItems="center"
            style={{ maxWidth: 50, height: 17, gap: 6, overflow: "hidden" }}
          >
            {slides.slice(1).map((item, index) => (
              <ButtonBase
                key={index}
                disabled={currentTutorialPage === index + 1 || loading}
                style={{
                  width: currentTutorialPage === index + 1 ? 16 : 11,
                  minWidth: currentTutorialPage === index + 1 ? 16 : 11,
                  height: currentTutorialPage === index + 1 ? 13 : 9,
                  backgroundColor:
                    currentTutorialPage === index + 1
                      ? colors.primary
                      : colors.disabled,
                  borderRadius: 3.5,
                  transition: "200ms",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => {
                  const container = document.getElementById(
                    "onboarding-container"
                  )
                  container?.scrollTo({
                    left: 2 * window.innerWidth,
                    behavior: screenReaderEnabled.current ? "auto" : "smooth",
                  })
                }}
                aria-label={t("go_to_slide_count_of_total", {
                  count: 3,
                  total: 3,
                })}
                aria-current={
                  currentTutorialPage === index + 1 ? "true" : "false"
                }
                onFocusVisible={() => {
                  screenReaderEnabled.current = true
                }}
                onBlur={() => {
                  screenReaderEnabled.current = false
                }}
              >
                <Text
                  fontSize={12}
                  fontWeight={700}
                  color={colors.textWhite}
                  style={{
                    opacity: currentTutorialPage === index + 1 ? 1 : 0,
                    transition: "200ms",
                  }}
                >
                  {index + 1}
                </Text>
              </ButtonBase>
            ))}
          </Stack>
          <ButtonBase
            style={{
              width: 36,
              minWidth: 36,
              height: 36,
              borderRadius: 30,
              backgroundColor: colors.backgroundWhite,
              boxShadow: defaultBoxShadowDarker,
            }}
            disabled={nextSlideButtonDisabled || loading}
            onClick={() => {
              if (canClick.current) {
                onClickFunctions[currentTutorialPage]()

                canClick.current = false
                const timeoutId = setTimeout(() => {
                  canClick.current = true
                  clearTimeout(timeoutId)
                }, 200)
              }
            }}
            aria-label={t("next_slide")}
            aria-disabled={nextSlideButtonDisabled || loading}
            onFocusVisible={() => {
              screenReaderEnabled.current = true
            }}
            onBlur={() => {
              screenReaderEnabled.current = false
            }}
          >
            <img
              src={chevronRightBigPrimaryIcon}
              style={{
                height: 36,
                transition: "150ms",
                opacity: nextSlideButtonDisabled ? 0.4 : 1,
              }}
              alt=""
            />
          </ButtonBase>
        </Stack>
      </Stack>
      {/* act now button */}
      <Button
        disabled={
          currentTutorialPage !== slides.length - 1 || closeMessageVisible
        }
        loading={loading}
        title={t("lets_start_singular")}
        style={{
          width: 330,
          position: "fixed",
          bottom: 22,
          opacity: closeMessageVisible
            ? 0
            : currentTutorialPage === slides.length - 1
            ? 1
            : 0,
          transition: "150ms",
        }}
        onClick={async () => {
          if (user?.firstAccess && doNotShowAgain) {
            setLoading(true)
            await setUserFirstAccess()
          }

          setViewTutorial(false)
        }}
      >
        {t("lets_start_singular")}
      </Button>
    </FeedbackContainer>
  )
}

export default TutorialAlertMobile
