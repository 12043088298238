import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import OnboardingMobile from "../../components/onboarding/OnboardingMobile"
import OnboardingDesktop from "../../components/onboarding/OnboardingDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Onboarding = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("onboarding")
  }, [])

  // footprint info alert open
  const [footprintAlertOpen, setFootprintAlertOpen] = useState<boolean>(false)

  return isMobile ? (
    <OnboardingMobile
      footprintAlertOpen={footprintAlertOpen}
      setFootprintAlertOpen={setFootprintAlertOpen}
    />
  ) : (
    <OnboardingDesktop
      footprintAlertOpen={footprintAlertOpen}
      setFootprintAlertOpen={setFootprintAlertOpen}
    />
  )
}

export default Onboarding
