import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"

const PrizeListItem = ({
  image,
  heading,
  title,
  value,
}: {
  image: string
  heading: string
  title: string
  value: string
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      style={{ width: "100%", height: isMobile ? 81 : 87 }}
    >
      <img
        src={image}
        style={{
          width: 88,
          minWidth: 88,
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
          borderRadius: 8,
        }}
        alt=""
      />
      <Stack gap={0.5}>
        <Title
          fontSize={isMobile ? 13 : 16}
          lineHeight={isMobile ? "15px" : "18px"}
          color={colors.textSecondary}
        >
          {heading}
        </Title>
        <Title
          fontSize={isMobile ? 15 : 16}
          lineHeight={isMobile ? "21px" : "22px"}
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: isMobile ? 42 : 44,
          }}
        >
          {title}
        </Title>
        <Title
          fontSize={isMobile ? 13 : 14}
          lineHeight={isMobile ? "15px" : "16px"}
          color={colors.textSecondary}
          fontWeight={500}
        >
          {value}
        </Title>
      </Stack>
    </Stack>
  )
}

export default PrizeListItem
