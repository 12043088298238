import { Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import awLogo from "../../assets/images/aw-logo.svg"
import partnerLogo from "../../assets/images/partner-logo.png"
import { Dispatch, SetStateAction, useContext, useRef, useState } from "react"
import { MainContext } from "../../controllers/main"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import Button from "../global/common/Button"
import { Trans, useTranslation } from "react-i18next"
import FadeFromTop from "../animations/FadeFromTop"
import FadeFromBottom from "../animations/FadeFromBottom"
import { onboardingElementsMaxWidth } from "../../services/config/constants"
import AlertInfo from "../global/common/AlertInfo"
import { useSearchParams } from "react-router-dom"
import ConsentCard from "./common/ConsentCard"
import Alert from "../global/common/Alert"
import AlertFullScreen from "../global/common/AlertFullScreen"
import {
  privacyPolicyEN,
  privacyPolicyIT,
} from "../../services/data/privacyPolicy"
import {
  contestRulesEN,
  contestRulesIT,
} from "../../services/data/contestRules"
import earthNatureImage from "../../assets/images/onboarding/earth-nature.png"

const OnboardingMobile = ({
  footprintAlertOpen,
  setFootprintAlertOpen,
  height,
  continueButtonWidth,
}: {
  footprintAlertOpen: boolean
  setFootprintAlertOpen: Dispatch<SetStateAction<boolean>>
  height?: number | string
  continueButtonWidth?: number
}) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const {
    lang,
    windowHeight,
    isMobile,
    appSignIn,
    getUserInfo,
    setViewOnboarding,
  } = useContext(MainContext)

  // local loading
  const [loading, setLoading] = useState<boolean>(false)

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  // consents to signin
  const [consents, setConsents] = useState<boolean[]>([false, false, false])

  // privacy and contest alerts
  const [privacyAlertOpen, setPrivacyAlertOpen] = useState<boolean>(false)
  const [contestAlertOpen, setContestAlertOpen] = useState<boolean>(false)

  // something is missing alert
  const [missingAlertOpen, setMissingAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      className="hide-scrollbars"
      style={{
        width: "100%",
        height: height ?? windowHeight,
        maxHeight: height ?? windowHeight,
        backgroundColor: colors.background,
        overflowY: "scroll",
      }}
    >
      {/* content */}
      <Stack
        id="onboarding-container"
        direction="row"
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          minHeight: 920,
          overflow: "hidden",
          paddingTop: 26,
          position: "relative",
        }}
        tabIndex={0}
      >
        <img
          src={earthNatureImage}
          style={{
            width: isMobile ? 214 : 274,
            height: isMobile ? 216 : 276,
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        />
        {/* first page */}
        <Stack
          style={{
            width: "100%",
            minWidth: "100%",
            paddingInline: isMobile ? 16 : 60,
          }}
          alignItems="center"
        >
          {/* header */}
          <FadeFromTop>
            <Stack direction="row" alignItems="center" gap={3}>
              <img
                src={partnerLogo}
                style={{ height: 20 }}
                alt={t("partner_logo_alt")}
              />
              <img
                src={awLogo}
                style={{ height: 16 }}
                alt={t("aworld_logo_alt")}
              />
            </Stack>
          </FadeFromTop>
          <FadeFromTop delay={0.05} style={{ marginTop: 28 }}>
            <Title
              fontSize={isMobile ? 26 : 28}
              textAlign="center"
              component="h1"
              style={{ maxWidth: 300 }}
            >
              {t("onboarding_welcome")}
            </Title>
          </FadeFromTop>
          <FadeFromTop delay={0.1} style={{ marginTop: 12 }}>
            <Text
              fontSize={isMobile ? 18 : 20}
              textAlign="center"
              fontWeight={500}
              component="h2"
              style={{ maxWidth: 350 }}
            >
              {t("onboarding_subtitle")}
            </Text>
          </FadeFromTop>
          <FadeFromTop delay={0.15} style={{ marginTop: 42 }}>
            <div
              style={{
                fontSize: isMobile ? 18 : 20,
                maxWidth: onboardingElementsMaxWidth,
                fontWeight: 300,
                marginLeft: -40,
                lineHeight: "36px",
              }}
            >
              <Trans i18nKey="onboarding_description">
                <ul>
                  <li>
                    <strong style={{ fontWeight: 500 }}>Entra in azione</strong>
                  </li>
                  <li>
                    <strong style={{ fontWeight: 500 }}>Completa</strong> le
                    missioni e accumula punti
                  </li>
                  <li>
                    <strong style={{ fontWeight: 500 }}>Vinci</strong> i premi
                    in palio
                  </li>
                </ul>
              </Trans>
            </div>
          </FadeFromTop>
          <ConsentCard
            title={t("authorization") + "*"}
            description={
              <Trans i18nKey="at_least_18">
                Dichiaro di avere{" "}
                <strong
                  style={{
                    color: colors.primary,
                    textDecoration: "underline",
                  }}
                >
                  almeno 18 anni
                </strong>
              </Trans>
            }
            checked={consents[0]}
            onChange={() => {
              consents[0] = !consents[0]
              setConsents([...consents])
            }}
            disabled={loading}
            style={{ marginTop: 62 }}
          />
          <ConsentCard
            title={t("privacy_policy") + "*"}
            description={
              <Trans i18nKey="privacy_policy_description">
                Dichiaro di aver letto e compreso l’
                <strong
                  style={{
                    color: colors.primary,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  role="button"
                  onClick={() => {
                    setPrivacyAlertOpen(true)
                  }}
                >
                  informativa privacy
                </strong>
              </Trans>
            }
            checked={consents[1]}
            onChange={() => {
              consents[1] = !consents[1]
              setConsents([...consents])
            }}
            disabled={loading}
            style={{ marginTop: 12 }}
          />
          <ConsentCard
            title={t("contest_rules") + "*"}
            description={
              <Trans i18nKey="contest_rules_description">
                Dichiaro di aver letto e compreso il{" "}
                <strong
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setContestAlertOpen(true)
                  }}
                  style={{
                    color: colors.primary,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  regolamento del concorso
                </strong>{" "}
                e che la partecipazione allo stesso prevede la creazione di un
                account AWorld
              </Trans>
            }
            checked={consents[2]}
            onChange={() => {
              consents[2] = !consents[2]
              setConsents([...consents])
            }}
            disabled={loading}
            style={{ marginTop: 12 }}
          />
        </Stack>
      </Stack>
      {/* continue button */}
      <FadeFromBottom
        style={{
          width: continueButtonWidth ?? "calc(100% - 32px)",
          position: "fixed",
          bottom: 22,
          display: "flex",
          justifyContent: "center",
          transition: "bottom 200ms",
        }}
      >
        <Button
          title={t("lets_start_short")}
          width="100%"
          loading={loading}
          onClick={async () => {
            if (consents.some((item) => !item)) {
              setMissingAlertOpen(true)
            } else {
              setLoading(true)

              const key = searchParams.get("key")!
              const iv = searchParams.get("iv")!

              // app signin
              await appSignIn(key, iv)

              // get current user info
              await getUserInfo()

              setLoading(false)
              setViewOnboarding(false)
            }
          }}
          onFocusVisible={() => {
            screenReaderEnabled.current = true
          }}
          onBlur={() => {
            screenReaderEnabled.current = false
          }}
        >
          {t("lets_start_short")}
        </Button>
      </FadeFromBottom>
      {/* alerts */}
      <AlertInfo
        open={footprintAlertOpen}
        setOpen={setFootprintAlertOpen}
        title={t("what_is_footprint")}
        description={t("what_is_footprint_description")}
        descriptionAlign="center"
      />
      {/* missing alert */}
      <Alert
        open={missingAlertOpen}
        title={t("something_is_missing")}
        description={t("consents_warning")}
        primaryActionLabel={t("i_understand")}
        primaryActionOnClick={() => {
          setMissingAlertOpen(false)
        }}
      />
      {/* full screen alerts for privacy policy and contest rules */}
      <AlertFullScreen
        open={privacyAlertOpen}
        setOpen={setPrivacyAlertOpen}
        content={lang === "it" ? privacyPolicyIT : privacyPolicyEN}
      />
      <AlertFullScreen
        open={contestAlertOpen}
        setOpen={setContestAlertOpen}
        content={lang === "it" ? contestRulesIT : contestRulesEN}
      />
    </Stack>
  )
}

export default OnboardingMobile
