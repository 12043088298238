import { useEffect } from "react"
import AvatarEditMobile from "../../components/settings/AvatarEditMobile"
import { registerPageEvent } from "../../services/utils/utils"

const AvatarEdit = () => {
  // register google analytics event
  useEffect(() => {
    registerPageEvent("avatar_edit")
  }, [])

  return <AvatarEditMobile />
}

export default AvatarEdit
