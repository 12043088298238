import { Stack } from "@mui/material"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import { useNavigate } from "react-router-dom"
import { useContext } from "react"
import { JourneysContext } from "../../controllers/journeys"
import learnMissionIconWhite from "../../assets/icons/missions/missionsTypes/learn-white.svg"
import Title from "../global/common/Title"
import JourneyCardMobile from "./mobile/JourneyCardMobile"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../controllers/main"
import AppearFromSide from "../animations/AppearFromSide"
import { colors } from "../../services/config/colors"
import { MissionPeriod } from "../../services/config/enum"
import PointsBadge from "../global/common/PointsBadge"

const JourneyMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentMission } = useContext(MainContext)
  const { journey } = useContext(JourneysContext)

  return !currentMission ? null : (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        position: "relative",
        paddingBottom: 22,
      }}
    >
      {/* back button */}
      <BackButtonMobile
        style={{ position: "absolute", top: 22, left: 16, zIndex: 1 }}
        onClick={() => {
          navigate("/")
        }}
      />
      {/* title */}
      <Title
        fontSize={20}
        lineHeight="48px"
        textAlign="center"
        style={{ marginTop: 22 }}
      >
        {t("learn_mission")}
      </Title>
      {/* mission */}
      <AppearFromSide fade style={{ marginTop: 14, paddingInline: 16 }}>
        <Stack direction="row" gap={0.75}>
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "auto",
              height: 32,
              borderRadius: 4,
              paddingInline: 8,
              backgroundColor: colors.missions.learn,
            }}
          >
            <img src={learnMissionIconWhite} style={{ width: 19 }} alt="" />
            <Title
              fontSize={14}
              color={colors.textWhite}
              style={{
                fontVariant: "all-small-caps",
                marginLeft: 8,
                marginBottom: 2,
              }}
            >
              {t("learn")}
            </Title>
            <Title
              fontSize={14}
              color={colors.textWhite}
              style={{
                fontVariant: "all-small-caps",
                marginLeft: 14,
                marginBottom: 2,
              }}
            >
              {currentMission.current}/{currentMission.target}
            </Title>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "auto",
              height: 32,
              borderRadius: 4,
              paddingInline: 8,
              border: "1px solid " + colors.tertiary,
              backgroundColor: colors.backgroundWhite,
            }}
          >
            <Title fontSize={14} color={colors.tertiary}>
              {currentMission.period === MissionPeriod.DAILY
                ? t("today")
                : currentMission.period === MissionPeriod.WEEKLY
                ? t("week")
                : t("special_singular")}
            </Title>
          </Stack>
          <PointsBadge points={currentMission.points} />
        </Stack>
      </AppearFromSide>
      <AppearFromSide
        fade
        delay={0.05}
        style={{ marginTop: 20, paddingInline: 16, position: "relative" }}
      >
        <Title fontSize={20}>{currentMission.title}</Title>
      </AppearFromSide>
      {/* image header */}
      <img
        src={journey?.image}
        style={{
          width: "100%",
          height: 156,
          objectFit: "cover",
          objectPosition: "top",
          marginTop: 20,
        }}
        alt=""
      />
      {/* journey card */}
      <JourneyCardMobile />
    </Stack>
  )
}

export default JourneyMobile
