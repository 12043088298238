export const privacyPolicyIT = `
<div class="elementor-widget-container">
  <style>
    /*! elementor - v3.10.2 - 29-01-2023 */
    .elementor-widget-text-editor.elementor-drop-cap-view-stacked
      .elementor-drop-cap {
      background-color: #818a91;
      color: #fff;
    }
    .elementor-widget-text-editor.elementor-drop-cap-view-framed
      .elementor-drop-cap {
      color: #818a91;
      border: 3px solid;
      background-color: transparent;
    }
    .elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
      .elementor-drop-cap {
      margin-top: 8px;
    }
    .elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
      .elementor-drop-cap-letter {
      width: 1em;
      height: 1em;
    }
    .elementor-widget-text-editor .elementor-drop-cap {
      float: left;
      text-align: center;
      line-height: 1;
      font-size: 50px;
    }
    .elementor-widget-text-editor .elementor-drop-cap-letter {
      display: inline-block;
    }
  </style>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>INFORMATIVA PRIVACY – Versione italiana</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b><br /></b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b
      >Informativa e richiesta di consenso ai sensi e per gli effetti degli
      artt. 13 e 14 del Regolamento (UE) 2016/679, relativo alla tutela del
      trattamento dei dati personali</b
    >
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Titolare del Trattamento dei Dati</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Titolare del trattamento è <b>AWorld Srl Società Benefit, </b>con sede
    legale in Corso Regina Margherita n. 1 Bis – 10124 Torino. Italia, P.IVA
    11867110014, in persona del suo legale rappresentante.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(220, 161, 13);
    "
  >
    <span style="color: rgb(0, 0, 0)">Contatti:&nbsp;</span
    ><a
      href="mailto:privacy@aworld.org"
      style="background-color: rgb(255, 255, 255); color: rgb(26, 154, 247)"
      >privacy@aworld.org</a
    >
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Responsabile della Protezione dei Dati</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Responsabile della protezione dei dati è la
    <b>Dott.ssa Debora Marianna Pagano</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(220, 161, 13);
    "
  >
    <span style="color: rgb(0, 0, 0)"
      >Contatti:
      <a href="mailto:privacy@emade.pro"
        ><span style="text-decoration-line: underline"
          >privacy@emade.pro</span
        ></a
      >&nbsp;</span
    >
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Finalità del trattamento</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    I dati personali forniti dagli utenti verranno utilizzati per:
  </p>
  <ul style="color: rgb(0, 0, 0); font-size: medium">
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Migliorare l’App e i servizi della stessa;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Informarti dei servizi disponibili di AWorld;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Autorizzare l’accesso all’App e ai servizi offerti dall’app;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Analisi sulle performance dell’app e dei servizi che offriamo;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Registrare la partecipazione dell’utente ad eventi tramite GPS laddove
      sia concesso il libero consenso;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Rendere visibile agli altri partecipanti la partecipazione dell’utente ad
      un determinato evento;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Creare contenuti e sondaggi all’interno dell’App relativamente ai temi
      della sostenibilità;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Gestire la tua partecipazione in programmi speciali, sondaggi, campagne,
      (inerenti il tema della sostenibilità) attraverso notifiche interne
      all’app stessa;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >La sicurezza dei nostri servizi, inclusa l’autenticazione degli utenti;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Supportare gli utenti nell’utilizzo dell’App;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Rispettare le Condizioni d’uso e altri accordi;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Eseguire analisi sui dati esclusivamente per l’assegnazione dei AW
      points;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Indagare su possibili frodi o altre violazioni delle Condizioni d’uso o
      di questa Informativa;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >La risoluzione di controversie in cui l’interessato o il Titolare del
      Trattamento sono coinvolti.
    </li>
  </ul>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Categorie di dati trattati</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    I dati personali trattati sono:
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    A – Dati di contatto (nome, cognome, mail): tali dati sono necessari per la
    creazione dell’account in AWorld.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    B -Immagini (l’utente può caricare un’immagine del profilo e condividere le
    foto degli eventi a cui prende parte).
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    C -Localizzazione (solo ed esclusivamente per la visualizzazione sulla mappa
    degli eventi e su esplicito e libero consenso dell’utente)
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    D -Abitudini di vita e di consumo (relative alle abitudini che hanno un
    impatto sul tema della sostenibilità): tali dati sono necessari per
    l’attribuzione del punteggio in AWorld.&nbsp;
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    I dati personali da Lei forniti non saranno oggetto di trasferimento verso
    Paesi non facenti parte dell’Unione Europea.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Modalità del trattamento</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    I dati personali degli utenti sono trattati con le opportune misure di
    sicurezza; tali misure sono utili a impedire la divulgazione, la modifica o
    altri utilizzi non autorizzati.&nbsp;
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Oltre al Titolare, potrebbero avere accesso ai Dati Personali persone
    autorizzate dal Titolare e i Responsabili Esterni. L’elenco è disponibile su
    richiesta scritta inviata al Titolare del Trattamento corredata di documento
    d’identità del richiedente.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Conseguenze del rifiuto dei dati</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    In caso di mancata autorizzazione al trattamento dei dati previsti nei punti
    A (dati di contatto) e D (abitudini di vita e consumo) l’interessato non
    potrà utilizzare l’App.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    In caso di mancata autorizzazione al trattamento dei dati previsti nei punti
    B (immagini) e C (localizzazione) l’interessato non potrà utilizzare servizi
    specifici per cui è previsto il trattamento dei relativi dati indicati nel
    punto definito “Categorie di dati trattati”.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Tutti i consensi potranno essere revocati e modificati in qualsiasi momento
    nell’area predisposta nell’account dell’utente all’interno dell’App.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Periodo di conservazione</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    I Dati sono trattati e conservati per il tempo richiesto dalle finalità per
    le quali sono stati raccolti. Pertanto:
  </p>
  <ul style="color: rgb(0, 0, 0); font-size: medium">
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >I Dati Personali raccolti per scopi collegati all’esecuzione di un
      contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia
      completata l’esecuzione di tale contratto.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >I Dati Personali raccolti per finalità riconducibili all’interesse
      legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale
      interesse. L’Utente può ottenere ulteriori informazioni in merito
      all’interesse legittimo perseguito dal Titolare nelle relative sezioni di
      questo documento o contattando il Titolare.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Il Titolare potrebbe essere obbligato a conservare i Dati Personali per
      un periodo più lungo in ottemperanza ad un obbligo di legge o per ordine
      di un’autorità.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Al termine del periodo di conservazioni i Dati Personali saranno
      anonimizzati. Pertanto, allo spirare di tale termine il diritto di
      accesso, cancellazione, rettificazione ed il diritto alla portabilità dei
      Dati non potranno più essere esercitati.
    </li>
  </ul>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Diritti dell’interessato</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Gli Utenti possono esercitare determinati diritti con riferimento ai Dati
    trattati dal Titolare.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    In particolare, l’Utente ha il diritto di:
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <ul style="color: rgb(0, 0, 0); font-size: medium">
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >revocare il consenso in ogni momento. L’Utente può revocare il consenso
      al trattamento dei propri Dati Personali precedentemente espresso.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >opporsi al trattamento dei propri Dati. L’Utente può opporsi al
      trattamento dei propri Dati quando esso avviene su una base giuridica
      diversa dal consenso. Ulteriori dettagli sul diritto di opposizione sono
      indicati nella sezione sottostante.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >accedere ai propri Dati. L’Utente ha diritto ad ottenere informazioni sui
      Dati trattati dal Titolare, su determinati aspetti del trattamento ed a
      ricevere una copia dei Dati trattati.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >verificare e chiedere la rettificazione. L’Utente può verificare la
      correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >ottenere la limitazione del trattamento. Quando ricorrono determinate
      condizioni, l’Utente può richiedere la limitazione del trattamento dei
      propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro
      scopo se non la loro conservazione.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >ottenere la cancellazione o rimozione dei propri Dati Personali. Quando
      ricorrono determinate condizioni, l’Utente può richiedere la cancellazione
      dei propri Dati da parte del Titolare.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >ricevere i propri Dati o farli trasferire ad altro titolare. L’Utente ha
      diritto di ricevere i propri Dati in formato strutturato, di uso comune e
      leggibile da dispositivo automatico e, ove tecnicamente fattibile, di
      ottenerne il trasferimento senza ostacoli ad un altro titolare. Questa
      disposizione è applicabile quando i Dati sono trattati con strumenti
      automatizzati ed il trattamento è basato sul consenso dell’Utente, su un
      contratto di cui l’Utente è parte o su misure contrattuali ad esso
      connesse.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >proporre reclamo. L’Utente può proporre un reclamo all’autorità di
      controllo della protezione dei dati personali competente o agire in sede
      giudiziale. Per farlo puoi utilizzare un modello messo a disposizione dal
      Garante Privacy.
    </li>
  </ul>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Ai sensi del medesimo articolo si ha il diritto di chiedere la
    cancellazione, la trasformazione informa anonima o il blocco dei dati
    trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi
    legittimi, al loro trattamento.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    L’interessato ha diritto di opporsi, in tutto o in parte: per motivi
    legittimi al trattamento dei dati personali che lo riguardano, ancorché
    pertinenti allo scopo della raccolta; al trattamento di dati personali che
    lo riguardano a fini di invio di materiale pubblicitario o di vendita
    diretta o per il compimento di ricerche di mercato.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Come esercitare i diritti</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Per esercitare i diritti dell’Utente, gli Utenti possono indirizzare una
    richiesta corredata da un documento identificativo agli estremi di contatto
    del Titolare indicati in questo documento. Le richieste sono depositate a
    titolo gratuito ed evase dal Titolare nel più breve tempo possibile, in ogni
    caso entro un mese.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Modifiche a questa privacy policy</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Il Titolare del Trattamento si riserva il diritto di apportare modifiche
    alla presente privacy policy in qualunque momento dandone informazione agli
    Utenti su questa pagina e, se possibile qualora tecnicamente e legalmente
    fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di
    contatto di cui è in possesso il Titolare. Si prega dunque di consultare
    regolarmente questa pagina, facendo riferimento alla data di ultima modifica
    indicata in fondo.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Ultima modifica: 25 Ottobre 2022
  </p>
</div>
`

export const privacyPolicyEN = `
<div class="elementor-widget-container">
  <style>
    /*! elementor - v3.10.2 - 29-01-2023 */
    .elementor-widget-text-editor.elementor-drop-cap-view-stacked
      .elementor-drop-cap {
      background-color: #818a91;
      color: #fff;
    }
    .elementor-widget-text-editor.elementor-drop-cap-view-framed
      .elementor-drop-cap {
      color: #818a91;
      border: 3px solid;
      background-color: transparent;
    }
    .elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
      .elementor-drop-cap {
      margin-top: 8px;
    }
    .elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
      .elementor-drop-cap-letter {
      width: 1em;
      height: 1em;
    }
    .elementor-widget-text-editor .elementor-drop-cap {
      float: left;
      text-align: center;
      line-height: 1;
      font-size: 50px;
    }
    .elementor-widget-text-editor .elementor-drop-cap-letter {
      display: inline-block;
    }
  </style>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Privacy Policy – english version</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b><br /></b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: center;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b
      >Privacy Policy and request of consent to the use of personal data in
      accordance with and for the effects of artt. 13 e 14 of General Data
      Protection Regulation (UE) 2016/679</b
    ><br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>The Data Controller</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    The Data Controller is <b>AWorld Srl Società Benefit, </b>with registered
    office in Corso Regina Margherita n. 1 Bis – 10124 Turin, Italy, VAT number
    11867110014, in the person of its legal representative.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(220, 161, 13);
    "
  >
    <span style="color: rgb(0, 0, 0)"
      >Contact: <a href="mailto:privacy@aworld.org">privacy@aworld.org</a></span
    >
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Purposes of processing</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    The personal data provided by users will be processed to:
  </p>
  <ul style="color: rgb(0, 0, 0); font-size: medium">
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Improve the App and its services;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Inform you of AWorld’s services;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Authorize access to the App and the services offered by the app;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Performance analysis of the app and its services;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Register user’s participation at events via GPS only in case free consent
      is granted;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Make user’s participation visible to other participants of the same
      event;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Create content and surveys within the App on sustainability issues;&nbsp;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Manage your participation in special programs, surveys, campaigns
      (related to the theme of sustainability) through notifications inside the
      app;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Security of our services, including user authentication;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Support users;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Respect the Terms of Use and other agreements;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Perform data analysis exclusively for AW points assignment;
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Investigate possible fraud or other violations of the Terms of Use or
      this Notice:
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Disputes resolution.
    </li>
  </ul>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Categories of data processed</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Processed personal data are:
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    A – Contact details (first name, last name, email): these data are necessary
    for the creation of the account in Aworld.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    B – Images (user can upload a profile image and share photos of events in
    which he or she takes part).
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    C -Location (only and exclusively for display on the event map and on
    explicit and free consent of the user)
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    D – Lifestyle and consumption habits (related to habits that have an impact
    on the theme of sustainability): these data are necessary for the
    attribution of the score in Aworld.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Personal data you provide will not be transferred to countries outside the
    European Union.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Processing methods</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Users’ personal data are processed with the appropriate security measures;
    these measures are useful to prevent disclosure, modification or other
    unauthorized use.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    In addition to the Data Controller, Personal Data may be processed by
    persons authorised by the Data Controller and External Data Processors. The
    list is available upon written request sent to the Data Controller
    accompanied by the applicant’s identity document.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Consequences of refusing data</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    In case of lack of authorization to the processing of the data provided in
    points A (contact data) and D (lifestyle and consumption habits), you will
    not be able to use the App.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    In case of failure to authorize the processing of the data provided for in
    points B (images) and C (location) the User may not use specific services
    for which the processing of the relevant data indicated in the point defined
    “Categories of data processed”.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    All consents can be withdrawn and modified at any time in the area set up in
    your account within the App.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Retention period</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    The data are processed and stored for the time required by the purposes for
    which they were collected. Therefore:
  </p>
  <ul style="color: rgb(0, 0, 0); font-size: medium">
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Personal Data collected for purposes related to the execution of a
      contract between the Controller and the User will be retained until the
      execution of this contract is completed.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >Personal data collected for purposes related to the legitimate interest
      of the Data Controller will be retained until the satisfaction of this
      interest. The User can obtain further information regarding the legitimate
      interest pursued by the Data Controller in the relevant sections of this
      document or by contacting the Controller.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >The Data Controller may be obliged to store Personal Data for a longer
      period in compliance with a legal obligation or by order of an authority.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >At the end of the retention period, Personal Data will be deleted.
      Therefore, at the end of this term, the right of access, cancellation,
      rectification and the right to data portability can no longer be
      exercised.
    </li>
  </ul>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Rights of the interested party</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Users can exercise certain rights with reference to the data processed by
    the controller.
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    In particular, the User has the right to:&nbsp;
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <ul style="color: rgb(0, 0, 0); font-size: medium">
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >withdraw consent at any time. The User can withdraw consent to the
      processing of their Personal Data previously expressed.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >oppose the processing of their data. The user can object to the
      processing of their data when it takes place on a legal basis other than
      consent. Further details on the right to object are indicated in the
      section below.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >access their data. The user has the right to obtain information on the
      data processed by the controller, on certain aspects of the processing and
      to receive a copy of the data processed.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >verify and request correction. The User can verify the accuracy of his
      Data and request its updating or correction.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >obtain the limitation of the treatment. When certain conditions are met,
      the User can request the limitation of the processing of their Data. In
      this case, the Controller will not process the Data for any other purpose
      other than their conservation.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >obtain the cancellation or removal of their Personal Data. When certain
      conditions are met, the User can request the cancellation of their Data by
      the Controller.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >receive your data or have it transferred to another controller. The User
      has the right to receive their Data in a structured format, commonly used
      and readable by an automatic device and, where technically feasible, to
      obtain its transfer without obstacles to another controller. This
      provision is applicable when the Data are processed with automated tools
      and the treatment is based on the User’s consent, on a contract of which
      the User is a party or on contractual measures connected to it.
    </li>
    <li
      style="
        font-variant-numeric: normal;
        font-variant-east-asian: normal;
        font-variant-alternates: normal;
        font-kerning: auto;
        font-optical-sizing: auto;
        font-feature-settings: normal;
        font-variation-settings: normal;
        font-stretch: normal;
        font-size: 13px;
        line-height: normal;
        font-family: 'Helvetica Neue';
      "
    >
      <span
        style="
          font-variant-numeric: normal;
          font-variant-east-asian: normal;
          font-variant-alternates: normal;
          font-kerning: auto;
          font-optical-sizing: auto;
          font-feature-settings: normal;
          font-variation-settings: normal;
          font-stretch: normal;
          font-size: 9px;
          line-height: normal;
          font-family: Menlo;
        "
      ></span
      >propose a complaint. The User can lodge a complaint with the competent
      personal data protection supervisory authority or take legal action. To do
      this you can use a model made available by the Data Protection Authority:
      download the model.
    </li>
  </ul>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      min-height: 15px;
      color: rgb(0, 0, 0);
    "
  >
    <br />
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Pursuant to the same article, you have the right to request cancellation,
    transformation inform anonymously or blocking of data processed in violation
    of the law, and to oppose in any case, for legitimate reasons, to their
    treatment.&nbsp;
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    The interested party has the right to object, in whole or in part: for
    legitimate reasons, to the processing of personal data concerning him, even
    if pertinent to the purpose of the collection; to the processing of personal
    data concerning him for the purpose of sending advertising materials or
    direct selling or for carrying out market research.&nbsp;
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>How to exercise the rights</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    To exercise the User’s rights, Users can send a request accompanied by an
    identification document to the contact details of the Data Controller
    indicated in this document. Requests are filed free of charge and processed
    by the Data Controller as soon as possible, in any case within a
    month.&nbsp;
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    <b>Changes to this privacy policy</b>
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    We reserve the right to make changes to this Privacy Policy at any time and
    for any reason.&nbsp; We will alert you about any changes by updating the
    “Last Updated” date of this Privacy Policy.&nbsp; Any changes or
    modifications will be effective immediately upon posting the updated Privacy
    Policy on the Site, and you waive the right to receive specific notice of
    each such change or modification.&nbsp;
  </p>
  <p
    style="
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      font-variant-alternates: normal;
      font-kerning: auto;
      font-optical-sizing: auto;
      font-feature-settings: normal;
      font-variation-settings: normal;
      font-stretch: normal;
      font-size: 13px;
      line-height: normal;
      font-family: 'Helvetica Neue';
      color: rgb(0, 0, 0);
    "
  >
    Last modified: 25<sup>th</sup> October 2022
  </p>
</div>
`
