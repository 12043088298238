import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import AvatarSelectionMobile from "../../components/onboarding/AvatarSelectionMobile"
import AvatarSelectionDesktop from "../../components/onboarding/AvatarSelectionDesktop"

const AvatarSelection = () => {
  const { isMobile, avatars } = useContext(MainContext)

  // selected avatar
  const [selectedAvatar, setSelectedAvatar] = useState<string>(avatars[0].url)

  return isMobile ? (
    <AvatarSelectionMobile
      selectedAvatar={selectedAvatar}
      setSelectedAvatar={setSelectedAvatar}
    />
  ) : (
    <AvatarSelectionDesktop
      selectedAvatar={selectedAvatar}
      setSelectedAvatar={setSelectedAvatar}
    />
  )
}

export default AvatarSelection
