import { useContext, useEffect } from "react"
import LandingMobile from "../../components/landing/LandingMobile"
import { MainContext } from "../../controllers/main"
import LandingDesktop from "../../components/landing/LandingDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Landing = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("landing")
  }, [])

  return isMobile ? <LandingMobile /> : <LandingDesktop />
}

export default Landing
