import { ButtonBase, Stack } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { colors } from "../../services/config/colors"
import Text from "../global/common/Text"
import Title from "../global/common/Title"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import FadeFromTop from "../animations/FadeFromTop"
import infoIcon from "../../assets/icons/info-primary.svg"
import { useTranslation } from "react-i18next"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { ChallengeContext } from "../../controllers/challenge"
import AppearFromSide from "../animations/AppearFromSide"
import months from "../../services/config/months"
import ChallengeProgressBar from "./common/ChallengeProgressBar"
import { MainContext } from "../../controllers/main"
import { UsersContext } from "../../controllers/users"
import AlertInfo from "../global/common/AlertInfo"
import SavingCard from "../actions/common/SavingCard"
import infoIconPrimary from "../../assets/icons/info-primary.svg"
import PrizeListItem from "./common/PrizeListItem"
import prize1Image from "../../assets/images/prizes/prize-1.png"
import prize2Image from "../../assets/images/prizes/prize-2.png"

const ChallengeMobile = ({
  prizesAlertOpen,
  setPrizesAlertOpen,
}: {
  prizesAlertOpen: boolean
  setPrizesAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { challenge, challengeSavings } = useContext(ChallengeContext)
  const { leaderboard } = useContext(UsersContext)

  // what is a challenge alert
  const [challengeInfoAlertOpen, setChallengeInfoAlertOpen] =
    useState<boolean>(false)

  return (
    <div style={{ width: "100%", minHeight: "100%" }}>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 36,
        }}
      >
        {/* back button */}
        <BackButtonMobile
          style={{ position: "absolute", top: 22, left: 16, zIndex: 1 }}
          onClick={() => {
            navigate("/")
          }}
        />
        {/* top background */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 170,
            minHeight: 170,
            backgroundImage: `url(${challenge?.image})`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            top: 0,
            borderBottomLeftRadius: 24,
            borderBottomRightRadius: 24,
            display: "flex",
            justifyContent: "center",
          }}
        />
        {/* header */}
        <FadeFromTop
          style={{
            width: "calc(100% - 32px)",
            marginTop: 125,
          }}
        >
          <Stack
            alignItems="center"
            style={{
              width: "100%",
              backgroundColor: colors.backgroundWhite,
              boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
              borderRadius: 10,
              position: "relative",
              padding: 24,
              gap: 16,
            }}
          >
            <Text
              fontSize={12}
              lineHeight="16px"
              fontWeight={700}
              color={colors.primary}
              component="h1"
            >
              {t("collective_challenge").toUpperCase()}
            </Text>
            <Title fontSize={20} lineHeight="25px">
              {challenge!.title}
            </Title>
            <ButtonBase
              disableRipple
              style={{
                height: 22,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              onClick={() => {
                setChallengeInfoAlertOpen(true)
              }}
            >
              <img src={infoIcon} style={{ width: 16, height: 16 }} alt="" />
              <Text
                fontSize={12}
                lineHeight="16px"
                fontWeight={700}
                style={{ marginLeft: 5 }}
                color={colors.primary}
              >
                {t("what_is_a_challenge")}
              </Text>
            </ButtonBase>
            <Stack
              direction="row"
              style={{
                width: "100%",
                height: 38,
                position: "relative",
              }}
            >
              <Stack style={{ gap: 4 }}>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  lineHeight="14px"
                  color={colors.textSecondary}
                >
                  {t("starts")}
                </Text>
                <Text fontSize={14} fontWeight={700} lineHeight="20px">
                  {`${new Date(challenge!.startDate).getDate()} ${t(
                    months[new Date(challenge!.startDate).getMonth()]
                  ).toLowerCase()} ${new Date(
                    challenge!.startDate
                  ).getFullYear()}`}
                </Text>
              </Stack>
              <Stack style={{ gap: 4, position: "absolute", right: 0 }}>
                <Text
                  fontSize={12}
                  fontWeight={500}
                  lineHeight="14px"
                  color={colors.textSecondary}
                >
                  {t("ends")}
                </Text>
                <Text fontSize={14} fontWeight={700} lineHeight="20px">
                  {`${new Date(challenge!.endDate).getDate()} ${t(
                    months[new Date(challenge!.endDate).getMonth()]
                  ).toLowerCase()} ${new Date(
                    challenge!.endDate
                  ).getFullYear()}`}
                </Text>
              </Stack>
            </Stack>
            <Stack
              direction="row"
              style={{ width: "100%", position: "relative" }}
            >
              <Text
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                color={colors.textSecondary}
              >
                {challenge!.currentAmount} {t("missions").toLowerCase()}
              </Text>
              <Text
                fontSize={12}
                lineHeight="14px"
                fontWeight={500}
                color={colors.textSecondary}
                style={{ position: "absolute", right: 0 }}
              >
                {challenge!.targetAmount} {t("missions").toLowerCase()}
              </Text>
            </Stack>
            <ChallengeProgressBar
              percentage={Math.round(
                (challenge!.currentAmount / challenge!.targetAmount) * 100
              )}
              style={{ marginTop: -8 }}
            />
          </Stack>
        </FadeFromTop>
        {/* challenge body */}
        {/* leaderboard */}
        <AppearFromSide fade style={{ width: "100%", marginTop: 24 }}>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 25,
                position: "relative",
                paddingInline: 16,
              }}
            >
              <Title fontSize={18} lineHeight="25px" component="h2">
                {t("leaderboard")}
              </Title>
              <Text
                fontSize={12}
                fontWeight={500}
                color={colors.textSecondary}
                style={{ position: "absolute", right: 16 }}
              >
                {challenge!.userCount} {t("partecipants")}
              </Text>
            </Stack>
            {leaderboard.length ? (
              <Stack style={{ marginTop: 16, paddingInline: 16, gap: 16 }}>
                {leaderboard.slice(0, 3).map((item, index) => (
                  <LeaderboardCard
                    key={item.sub}
                    place={index + 1}
                    name={`${item.first_name} ${item.last_name}`}
                    sub={item.sub}
                    points={item.points}
                    profileImage={item.profileImage}
                  />
                ))}
                {leaderboard.length > 3 && (
                  <ButtonBase
                    style={{ width: "fit-content" }}
                    aria-label={t("see_all")}
                    onClick={() => {
                      navigate("/performance", {
                        state: { viewLeaderboard: true },
                      })
                    }}
                  >
                    <Text
                      fontSize={14}
                      fontWeight={700}
                      color={colors.primary}
                      style={{ textDecoration: "underline" }}
                    >
                      {t("see_all")}
                    </Text>
                  </ButtonBase>
                )}
              </Stack>
            ) : (
              <Text fontSize={16} paddingLeft={16} style={{ marginTop: 16 }}>
                {t("leaderboard_empty_state")}.
              </Text>
            )}
          </Stack>
        </AppearFromSide>
        {/* prizes */}
        <AppearFromSide
          fade
          delay={0.05}
          style={{ width: "100%", marginTop: 24 }}
        >
          <Stack style={{ paddingInline: 16 }}>
            <Title fontSize={18} lineHeight="25px" component="h2">
              {t("prizes")}
            </Title>
            <ButtonBase
              disableRipple
              style={{
                width: "fit-content",
                justifyContent: "flex-start",
                marginTop: 16,
              }}
              aria-label={t("how_do_prizes_work")}
              onClick={() => {
                setPrizesAlertOpen(true)
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <img
                  src={infoIconPrimary}
                  style={{ width: 16, height: 16 }}
                  alt=""
                />
                <Title fontSize={14} lineHeight="16px" color={colors.primary}>
                  {t("how_do_prizes_work")}
                </Title>
              </Stack>
            </ButtonBase>
            <Stack
              style={{
                width: "100%",
                height: "auto",
                padding: 14,
                backgroundColor: "#FAF5E3",
                border: "1px solid #FFE895",
                borderRadius: 11,
                marginTop: 16,
                gap: 26,
              }}
            >
              <PrizeListItem
                image={prize1Image}
                heading={t("draw_prize")}
                title={t("electrolux_steam_oven")}
                value="1499,99€"
              />
              <PrizeListItem
                image={prize2Image}
                heading={t("first_10_places")}
                title="Set Vacuum Fresh Create 3 Electrolux"
                value="109,90€"
              />
            </Stack>
          </Stack>
        </AppearFromSide>
        {/* description */}
        <AppearFromSide
          fade
          delay={0.1}
          style={{ width: "100%", marginTop: 24 }}
        >
          <Stack style={{ paddingInline: 16 }}>
            <Title
              fontSize={18}
              lineHeight="25px"
              style={{ marginBottom: 16 }}
              component="h2"
            >
              {t("description")}
            </Title>
            <div
              className="html"
              dangerouslySetInnerHTML={{
                __html: challenge!.description,
              }}
              style={{
                fontSize: 16,
                fontWeight: 300,
                lineHeight: "22px",
                color: colors.text,
                overflow: "hidden",
              }}
            />
          </Stack>
        </AppearFromSide>
        {/* savings */}
        {challengeSavings &&
        (challengeSavings.co2Saving ||
          challengeSavings.waterSaving ||
          challengeSavings.energySaving) ? (
          <AppearFromSide
            fade
            delay={0.15}
            style={{ width: "100%", marginTop: 24 }}
          >
            <Stack>
              <Title
                fontSize={18}
                lineHeight="25px"
                paddingLeft={16}
                component="h2"
              >
                {t("community_savings")}
              </Title>
              <Stack
                direction="row"
                gap={1.5}
                style={{
                  width: "100%",
                  overflowX: "scroll",
                  paddingInline: 16,
                  marginTop: 16,
                }}
              >
                {challengeSavings.co2Saving ? (
                  <SavingCard
                    metric="co2"
                    amount={challengeSavings.co2Saving}
                  />
                ) : null}
                {challengeSavings.waterSaving ? (
                  <SavingCard
                    metric="water"
                    amount={challengeSavings.waterSaving}
                  />
                ) : null}
                {challengeSavings.energySaving ? (
                  <SavingCard
                    metric="energy"
                    amount={challengeSavings.energySaving}
                  />
                ) : null}
              </Stack>
            </Stack>
          </AppearFromSide>
        ) : null}
      </div>
      {/* alerts */}
      <AlertInfo
        open={challengeInfoAlertOpen}
        setOpen={setChallengeInfoAlertOpen}
        title={t("what_is_a_challenge")}
        description={t("challenges_description")}
        descriptionAlign="center"
      />
      <AlertInfo
        open={prizesAlertOpen}
        setOpen={setPrizesAlertOpen}
        title={t("how_do_prizes_work")}
        description={t("how_do_prizes_work_description")}
        descriptionAlign="center"
      />
    </div>
  )
}

const LeaderboardCard = ({
  place,
  name,
  sub,
  points,
  profileImage,
}: {
  place: number
  name: string
  sub: string
  points: number
  profileImage: string
}) => {
  const { t } = useTranslation()
  const { user } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: "100%",
        height: 78,
        borderRadius: 10,
        padding: 12,
        boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        backgroundColor:
          user!.sub === sub ? colors.background : colors.backgroundWhite,
      }}
    >
      <div
        style={{
          width: "auto",
          height: 22,
          paddingInline: 6,
          borderRadius: 4,
          backgroundColor: colors.primary,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Title fontSize={12} color={colors.textWhite}>
          {place}°
        </Title>
      </div>
      <div
        style={{
          width: 32,
          minWidth: 32,
          height: 32,
          borderRadius: "100%",
          marginLeft: 16,
          backgroundImage: `url(${profileImage}), url(https://cdn.aworld.io/users/default/profile.jpg)`,
          backgroundSize: "105%, 100%",
          backgroundPosition: "center",
        }}
      />
      <Stack
        style={{
          width: "100%",
          marginLeft: 12,
          gap: 4,
        }}
      >
        <Title
          fontSize={14}
          lineHeight="20px"
          color={user!.sub === sub ? colors.primary : colors.text}
          style={{
            width: "calc(100% - 100px)",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            textOverflow: "ellipsis",
            overflow: "hidden",
            wordWrap: "break-word",
          }}
        >
          {user?.sub === sub ? t("you") : name}
        </Title>
        <Text
          fontSize={12}
          fontWeight={500}
          lineHeight="14px"
          color={user!.sub === sub ? colors.primary : colors.textSecondary}
        >
          {points} {t("points_extended").toLowerCase()}
        </Text>
      </Stack>
    </Stack>
  )
}

export default ChallengeMobile
