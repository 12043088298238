import { Grow, Modal } from "@mui/material"
import { Dispatch, ReactNode, SetStateAction, useContext } from "react"
import { colors } from "../../../services/config/colors"
import Title from "./Title"
import { useTranslation } from "react-i18next"
import Button from "./Button"
import { MainContext } from "../../../controllers/main"
import Text from "./Text"

const AlertInfo = ({
  open,
  setOpen,
  title,
  description,
  descriptionAlign = "left",
  descriptionFontSize = 18,
  withAvatar = true,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  title: string
  description: string | ReactNode
  descriptionAlign?: "left" | "center" | "right"
  descriptionFontSize?: number
  withAvatar?: boolean
}) => {
  const { t } = useTranslation()
  const { user } = useContext(MainContext)

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      aria-labelledby={title}
      aria-describedby={typeof description === "string" ? description : ""}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <div
          style={{
            backgroundColor: colors.backgroundWhite,
            width: "calc(100% - 44px)",
            maxWidth: 375,
            height: "auto",
            borderRadius: 16,
            padding: 24,
            paddingTop: user && user.profileImage && withAvatar ? 72 : 24,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {user && user.profileImage && withAvatar ? (
            <img
              src={user.profileImage}
              style={{
                width: 78,
                height: 78,
                borderRadius: "100%",
                position: "absolute",
                top: -39,
                left: 0,
                right: 0,
                marginInline: "auto",
                zIndex: 2,
              }}
              alt=""
            />
          ) : null}
          <Title id={title} lineHeight="30.4px" textAlign="center">
            {title}
          </Title>
          {typeof description === "string" ? (
            <div
              id={description}
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              style={{
                fontSize: descriptionFontSize,
                fontWeight: 400,
                lineHeight: "24px",
                color: colors.text,
                marginTop: 20,
                textAlign: descriptionAlign,
                padding: 0,
                maxHeight: "52vh",
                overflowY: "scroll",
              }}
            />
          ) : (
            <Text
              fontSize={descriptionFontSize}
              lineHeight="24px"
              fontWeight={400}
              textAlign={descriptionAlign}
              style={{ marginTop: 20 }}
            >
              {description}
            </Text>
          )}
          <Button
            width="100%"
            style={{ marginTop: 24 }}
            onClick={() => {
              setOpen(false)
            }}
            title={t("i_understand")}
          >
            {t("i_understand")}
          </Button>
        </div>
      </Grow>
    </Modal>
  )
}

export default AlertInfo
