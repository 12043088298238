import { useEffect, useState } from "react"
import TitleSlide from "../../../../models/slides/titleSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"
import { Textfit } from "react-textfit"

const TitleLayout = ({ slide }: { slide: TitleSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 10,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Ellipsis slide={slide} />
        <Box slide={slide} />
        <Decor slide={slide} />
        {slide.image && slide.image.type !== "XL" ? (
          <Image slide={slide} />
        ) : null}
      </div>
      {slide.image && slide.image.type === "XL" ? (
        <Image slide={slide} />
      ) : null}
    </div>
  )
}

const Ellipsis = ({ slide }: { slide: TitleSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        left: `calc(${slideWidth}px / ${numberOfColumns} * 0.27)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 0.27)`,
        height: `calc(${slideWidth}px / ${numberOfColumns} * 13)`,
        borderRadius: `calc(${slideWidth}px / ${numberOfColumns} * 15)`,
        backgroundColor: slide.box ? slide.box.boxBackColor : undefined,
        top: `calc(${slideHeight}px / ${numberOfRows} * 3.6)`,
        opacity: slide.box && slide.box.boxShape === "rounded" ? 1 : 0,
        clipPath: "ellipse(44.9% 38% at 50% 50%)",
      }}
    />
  )
}

const Box = ({ slide }: { slide: TitleSlide }) => {
  // fix for textfit wrong rendering
  const [showTitle, setShowTitle] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setShowTitle(true)
    }, 100)
  }, [])

  return (
    <div
      style={{
        backgroundColor: slide.box ? slide.box.boxBackColor : undefined,
        width: `calc(${slideWidth}px / ${numberOfColumns} * 13)`,
        bottom: `calc(${slideHeight}px / ${numberOfRows} * 6)`,
        position: "absolute",
        top:
          (slide.box && slide.box.boxShape === "squared") || !slide.box
            ? `calc(${slideHeight}px / ${numberOfRows} * 10)`
            : `calc(${slideHeight}px / ${numberOfRows} * 13)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
      }}
    >
      <Textfit
        style={{
          color: slide.textColor,
          lineHeight: 1,
          fontWeight: 900,
          marginTop:
            (slide.box && slide.box.boxShape === "squared") || !slide.box
              ? slide.description.length
                ? `calc(${slideHeight}px / ${numberOfRows} * 2.5)`
                : `calc(${slideHeight}px / ${numberOfRows} * 3.5)`
              : slide.description.length
              ? `calc(${slideHeight}px / ${numberOfRows} * 0)`
              : `calc(${slideHeight}px / ${numberOfRows} * 1)`,
          marginLeft: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          marginRight: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          height: slide.description.length
            ? `calc(${slideHeight}px / ${numberOfRows} * 6)`
            : `calc(${slideHeight}px / ${numberOfRows} * 11)`,
          opacity: showTitle ? 1 : 0,
        }}
        min={30}
        max={78}
        mode="multi"
      >
        {slide.title.toUpperCase()}
      </Textfit>
      <Textfit
        style={{
          color: slide.textColor,
          lineHeight: 1.2,
          fontWeight: 300,
          marginLeft: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          marginRight: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          height: `calc(${slideHeight}px / ${numberOfRows} * 5)`,
          marginTop: `calc(${slideHeight}px / ${numberOfRows} * 0.5)`,
        }}
        min={15.3}
        max={80}
        mode="multi"
      >
        {slide.description}
      </Textfit>
    </div>
  )
}

const Image = ({ slide }: { slide: TitleSlide }) => {
  return slide.image ? (
    <div
      style={{
        position: "absolute",
        top: `calc(100% / 2 + 64px)`,
        left:
          slide.image.type === "XL"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 0)`
            : slide.image.type === "M"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 1)`
            : `calc(${slideWidth}px / ${numberOfColumns} * 2)`,
        right:
          slide.image.type === "XL"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 0)`
            : slide.image.type === "M"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 1)`
            : `calc(${slideWidth}px / ${numberOfColumns} * 2)`,
        bottom:
          slide.image.type === "S"
            ? `calc(${slideHeight}px / ${numberOfRows} * 7)`
            : slide.image.type === "M"
            ? `calc(${slideHeight}px / ${numberOfRows} * 6)`
            : `calc(${slideHeight}px / ${numberOfRows} * 0)`,
        backgroundImage: `url(${slide.image.url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center top",
      }}
    />
  ) : null
}

const Decor = ({ slide }: { slide: TitleSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 7)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 2)`,
        height: `calc(${slideHeight}px / ${numberOfRows} * 5)`,
        width: `calc(${slideHeight}px / ${numberOfRows} * 5)`,
        backgroundImage: `url(${slide.decor})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
  )
}

export default TitleLayout
