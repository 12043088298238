import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import journeySuccededImage from "../../../assets/images/journey-succeded.png"
import Confetti from "../../global/common/Confetti"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"

const JourneyFeedback = () => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        paddingTop: 120,
        position: "relative",
        overflow: "hidden",
        backgroundColor: colors.backgroundWhite,
      }}
    >
      <Stack alignItems="center" style={{ gap: 30 }}>
        <Title fontSize={26}>{t("journey_completed")}</Title>
        <img
          src={journeySuccededImage}
          style={{ width: 190, height: 190 }}
          alt=""
        />
      </Stack>
      <Confetti />
    </div>
  )
}

export default JourneyFeedback
