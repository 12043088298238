import { useContext, useEffect, useState } from "react"
import Episode from "../../../models/episode"
import { JourneysContext } from "../../../controllers/journeys"
import { ButtonBase, Stack } from "@mui/material"
import Title from "../../global/common/Title"
import episodeSuccededImage from "../../../assets/images/episode-succeded.png"
import episodeFailedImage from "../../../assets/images/episode-failed.png"
import { AnimatePresence, motion } from "framer-motion"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import { useNavigate } from "react-router-dom"
import playIconPrimary from "../../../assets/icons/play-primary.svg"
import Confetti from "../../global/common/Confetti"
import { useTranslation } from "react-i18next"

const EpisodeFeedback = ({
  episode,
  nextEpisode,
}: {
  episode: Episode
  nextEpisode?: Episode
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { journey } = useContext(JourneysContext)

  // if next episode popup should be visible and working or not
  const [showNextEpisodePopup, setShowNextEpisodePopup] =
    useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setShowNextEpisodePopup(true)
    }, 200)
  }, [])

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        paddingTop: 120,
        position: "relative",
        overflow: "hidden",
        backgroundColor: colors.backgroundWhite,
      }}
    >
      {episode.result.success ? (
        <EpisodeSuccededFeedback />
      ) : (
        <EpisodeFailedFeedback />
      )}
      {/* next episode popup */}
      <AnimatePresence>
        {showNextEpisodePopup && (
          <motion.div
            initial={{ y: 160 }}
            animate={{ y: 0 }}
            exit={{ y: 160 }}
            style={{
              width: "100%",
              height: 170,
              position: "absolute",
              bottom: -20,
              backgroundColor: colors.background,
              borderTopRightRadius: 24,
              borderTopLeftRadius: 24,
              zIndex: 5,
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <Stack
              style={{
                width: "100%",
                height: "100%",
                gap: 16,
                padding: 16,
              }}
            >
              <Text fontSize={16} fontWeight={400}>
                {t("next_episode")}
              </Text>
              <ButtonBase
                style={{ width: "100%", borderRadius: 18 }}
                onClick={(e) => {
                  navigate("/journey/" + nextEpisode!.id + "/0")
                }}
                aria-label={t("go_to_next_episode")}
              >
                <Stack
                  direction="row"
                  style={{
                    width: "100%",
                    height: 75,
                    borderRadius: 18,
                    backgroundColor: colors.backgroundWhite,
                    padding: 16,
                    boxShadow: "0px 2px 24px 2px rgba(147, 160, 167, 0.3)",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={nextEpisode!.image}
                    style={{
                      width: 42,
                      height: 42,
                      borderRadius: 21,
                      backgroundColor: nextEpisode!.slides.find(
                        (item) => item.slideType === "TitleSlide"
                      )!.box
                        ? nextEpisode!.slides.find(
                            (item) => item.slideType === "TitleSlide"
                          )!.box!.boxBackColor
                        : colors.backgroundWhite,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    alt=""
                  />
                  <Stack
                    style={{
                      width: "100%",
                      height: "100%",
                      marginLeft: 16,
                    }}
                  >
                    <Text fontSize={16} fontWeight={400}>
                      {t("episode")}{" "}
                      {journey!.episodes.findIndex(
                        (item) => item.id === nextEpisode!.id
                      ) + 1}
                    </Text>
                    <Text
                      fontSize={18}
                      fontWeight={700}
                      style={{
                        maxWidth: "calc(100% - 30px)",
                        marginTop: -3,
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {nextEpisode!.title}
                    </Text>
                  </Stack>
                  <img
                    src={playIconPrimary}
                    style={{
                      height: 20,
                      position: "absolute",
                      right: 16,
                      top: 28,
                    }}
                    alt=""
                  />
                </Stack>
              </ButtonBase>
            </Stack>
          </motion.div>
        )}
      </AnimatePresence>
      {episode.result.success && <Confetti />}
    </div>
  )
}

const EpisodeSuccededFeedback = () => {
  const { t } = useTranslation()

  return (
    <Stack alignItems="center" style={{ gap: 20 }}>
      <Title fontSize={26}>{t("episode_completed")}</Title>
      <img
        src={episodeSuccededImage}
        style={{ width: 200, height: 200 }}
        alt=""
      />
    </Stack>
  )
}

const EpisodeFailedFeedback = () => {
  const { t } = useTranslation()

  return (
    <Stack alignItems="center" style={{ gap: 35 }}>
      <Title fontSize={26}>{t("episode_failed")}</Title>
      <img
        src={episodeFailedImage}
        style={{ width: 170, height: 170 }}
        alt=""
      />
    </Stack>
  )
}

export default EpisodeFeedback
