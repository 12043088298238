import { useContext, useEffect } from "react"
import ActionCalculationsMobile from "../../components/actions/ActionCalculationsMobile"
import { MainContext } from "../../controllers/main"
import { ActionsContext } from "../../controllers/actions"
import { useParams } from "react-router-dom"
import ActionCalculationsDesktop from "../../components/actions/ActionCalculationsDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const ActionCalculations = () => {
  const { actionId } = useParams()
  const { isMobile } = useContext(MainContext)
  const { actions } = useContext(ActionsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("action_calculations")
  }, [])

  // current action
  const action = actions.find((item) => item.id === actionId)!

  return isMobile ? (
    <ActionCalculationsMobile action={action} />
  ) : (
    <ActionCalculationsDesktop action={action} />
  )
}

export default ActionCalculations
