import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import {
  availableLanguages,
  mobileBreakpoint,
} from "../services/config/constants"
import i18next from "i18next"
import { get, post, put } from "../services/api/api"
import {
  parseAvatarsListData,
  parseUserData,
} from "../services/utils/parseFunctions"
import User from "../models/user"
import { useNavigate, useSearchParams } from "react-router-dom"
import Avatar from "../models/avatar"
import {
  cacheImages,
  generateRandomString,
  logger,
} from "../services/utils/utils"
import Mission from "../models/mission"
import {
  signIn as amplifySignIn,
  signUp as amplifySignUp,
  confirmSignIn,
} from "aws-amplify/auth"
import slugify from "slugify"

interface MainContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  signUpError: boolean
  viewOnboarding: boolean
  viewTutorial: boolean
  setViewTutorial: Dispatch<SetStateAction<boolean>>
  viewAvatarSelection: boolean
  setViewAvatarSelection: Dispatch<SetStateAction<boolean>>
  setSignUpError: Dispatch<SetStateAction<boolean>>
  setViewOnboarding: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
  windowWidth: number
  windowHeight: number
  lang: string
  user: User | null
  setUser: Dispatch<SetStateAction<User | null>>
  userError: boolean
  getUserInfo: (firstCall?: boolean) => Promise<boolean>
  setUserFirstAccess: () => Promise<boolean>
  changeLang: (newLang: string) => Promise<boolean>
  visualizingErrorPage: boolean
  setVisualizingErrorPage: Dispatch<SetStateAction<boolean>>
  visualizingLoadingPage: boolean
  setVisualizingLoadingPage: Dispatch<SetStateAction<boolean>>
  currentTutorialPage: number
  setCurrentTutorialPage: Dispatch<SetStateAction<number>>
  avatars: Avatar[]
  changeAvatar: (newAvatar: string) => Promise<boolean>
  currentMission: Mission | null
  setCurrentMission: Dispatch<SetStateAction<Mission | null>>
  updatingMissions: boolean
  setUpdatingMissions: Dispatch<SetStateAction<boolean>>
  sendCode: (
    firstName: string,
    lastName: string,
    email: string
  ) => Promise<boolean>
  confirmCode: (code: string) => Promise<boolean | number>
  shouldSignIn: boolean
  setShouldSignIn: Dispatch<SetStateAction<boolean>>
  footprintFeedbackOpen: boolean
  setFootprintFeedbackOpen: Dispatch<SetStateAction<boolean>>
  appSignIn: (key: string, iv: string) => Promise<boolean>
}

const MainContext = createContext<MainContextInterface>({
  loading: true,
  setLoading: () => {},
  signUpError: false,
  viewOnboarding: true,
  viewTutorial: true,
  setViewTutorial: () => {},
  viewAvatarSelection: true,
  setViewAvatarSelection: () => {},
  setSignUpError: () => {},
  setViewOnboarding: () => {},
  isMobile: false,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  lang: "en",
  user: null,
  setUser: () => {},
  userError: false,
  getUserInfo: async () => true,
  setUserFirstAccess: async () => true,
  changeLang: async () => false,
  visualizingErrorPage: false,
  setVisualizingErrorPage: () => {},
  visualizingLoadingPage: false,
  setVisualizingLoadingPage: () => {},
  currentTutorialPage: 0,
  setCurrentTutorialPage: () => {},
  avatars: [],
  changeAvatar: async () => true,
  currentMission: null,
  setCurrentMission: () => {},
  updatingMissions: false,
  setUpdatingMissions: () => {},
  sendCode: async () => true,
  confirmCode: async () => true,
  shouldSignIn: false,
  setShouldSignIn: () => {},
  footprintFeedbackOpen: false,
  setFootprintFeedbackOpen: () => {},
  appSignIn: async () => true,
})

const MainController = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // loadings
  const [loading, setLoading] = useState<boolean>(true) // main loading
  const [updatingMissions, setUpdatingMissions] = useState<boolean>(true) // loading for missions update

  // states
  const [signUpError, setSignUpError] = useState<boolean>(false) // signin error
  const [viewOnboarding, setViewOnboarding] = useState<boolean>(true) // view or not sign up onboarding
  const [viewAvatarSelection, setViewAvatarSelection] = useState<boolean>(true) // view or not user avatar selection
  const [viewTutorial, setViewTutorial] = useState<boolean>(true) // view or not tutorial
  const [isMobile, setIsMobile] = useState<boolean>(false) // if screen is mobile size or not
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth) // window current width
  const [windowHeight, setWindowHeight] = useState<number>(
    window.innerHeight - 50
  ) // window current height
  const [lang, setLang] = useState<string>("en") // app language
  const [user, setUser] = useState<User | null>(null) // current user
  const [userError, setUserError] = useState<boolean>(false) // current user error
  const [visualizingErrorPage, setVisualizingErrorPage] =
    useState<boolean>(false) // if user is visualizing error page or not
  const [visualizingLoadingPage, setVisualizingLoadingPage] =
    useState<boolean>(false) // if user is visualizing loading page or not
  const [currentTutorialPage, setCurrentTutorialPage] = useState<number>(0) // current tutorial page
  const [avatars, setAvatars] = useState<Avatar[]>([]) // avatars list
  const [currentMission, setCurrentMission] = useState<Mission | null>(
    localStorage.getItem("currentMission")
      ? JSON.parse(localStorage.getItem("currentMission")!)
      : null
  ) // current mission
  const [shouldSignIn, setShouldSignIn] = useState<boolean>(false) // if user should signin skipping the footprint typeform or not
  const [footprintFeedbackOpen, setFootprintFeedbackOpen] =
    useState<boolean>(false) // if should show footprint calculations feedback or not

  // change language
  const changeLang = async (newLang: string) => {
    try {
      await put("/web/user/update", { lang: newLang })

      return true
    } catch (e) {
      logger("user update error", e)

      return false
    }
  }

  // get user
  const getUser = async () => {
    const { data } = await get("/web/user/get")

    return data
  }

  // get user wallet
  const getUserWallet = async () => {
    const { data } = await get("/web/mission/point/user")

    return data
  }

  // get all user info
  const getUserInfo = async (firstCall = false) => {
    setUserError(false)

    try {
      const result = await Promise.all([
        getUser(),
        getUserWallet(),
        getAvatars(),
      ])

      const userData = result[0]
      const userWallet = result[1]

      // parse data
      parseUserData(userData)
      if (userWallet.points) {
        userData.points = userWallet.points
      } else {
        userData.points = 0
      }

      logger("user", userData)
      setUser(userData)

      // set app language based on user language
      if (userData.lang) {
        i18next.changeLanguage(userData.lang)
        if (availableLanguages.find((item) => item.code === userData.lang)) {
          setLang(userData.lang)
          document.documentElement.lang = userData.lang
        } else {
          setLang("en")
          document.documentElement.lang = "en"
        }
      } else {
        const langFromNavigator = navigator.language.slice(0, 2)
        if (
          langFromNavigator &&
          availableLanguages.some((item) => item.code === langFromNavigator)
        ) {
          await changeLang(langFromNavigator)
        }
      }

      // don't show avatar selection if the user has already one set
      if (userData.profileImage) {
        setViewAvatarSelection(false)
      }
      if (firstCall) {
        if (!userData.firstAccess) {
          setViewTutorial(false)
        } else {
          navigate("/")
        }
      }

      return true
    } catch (e) {
      logger("user error", e)
      setUserError(true)

      return false
    }
  }

  // get avatars list
  const getAvatars = async () => {
    try {
      const { data } = await get("/web/user/avatar/list")
      const dataToSet = parseAvatarsListData(data)
      logger("avatars list", dataToSet)

      // cache avatars
      await cacheImages(dataToSet.map((item) => item.url))

      setAvatars(dataToSet)

      return true
    } catch (e) {
      logger("avatars list error", e)
      return false
    }
  }

  // change user avatar
  const changeAvatar = async (newAvatar: string) => {
    try {
      await put("/web/user/profileimage", { profileImage: newAvatar })
      logger(`avatar set ${newAvatar}`)

      // update user locally
      user!.profileImage = newAvatar
      setUser({ ...user! })

      return true
    } catch (e) {
      logger("profile image change error", e)

      return false
    }
  }

  // set user first access to false
  const setUserFirstAccess = async () => {
    try {
      await put("/web/user/firstaccess")
      logger("user firstAccess set to false")

      // set first access to false locally
      user!.firstAccess = false
      setUser({ ...user! })

      return true
    } catch (e) {
      logger("firstaccess error", e)
      setUserError(true)

      return false
    }
  }

  // check if screen is mobile or not
  useEffect(() => {
    // first check
    if (window.innerWidth >= mobileBreakpoint) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    // event listener on resize
    window.addEventListener("resize", () => {
      if (window.innerWidth >= mobileBreakpoint) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }

      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight - 50)
    })
  }, [])

  // amplify send code (signin or signup)
  const sendCode = async (
    firstName: string,
    lastName: string,
    email: string
  ) => {
    try {
      await amplifySignIn({
        username: email,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      })

      return true
    } catch (e) {
      if (shouldSignIn) {
        return false
      }

      // do signup and send code
      const uid =
        slugify(`${firstName} ${lastName}`, { lower: true }) +
        generateRandomString(6, "0123456789abcdefghijklmnopqrstuvwxyz")
      const password = generateRandomString(
        20,
        "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      )

      await amplifySignUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            name: `${firstName} ${lastName}`,
            preferred_username: uid,
            "custom:tenant": "aworld",
          },
        },
      })

      await amplifySignIn({
        username: email,
        options: {
          authFlowType: "CUSTOM_WITHOUT_SRP",
        },
      })

      return true
    }
  }

  // check code that user has entered
  const confirmCode = async (code: string) => {
    try {
      const result = await confirmSignIn({ challengeResponse: code })

      if (result.isSignedIn) {
        logger("right code")

        // search for amplify accessToken in localStorage
        let amplifyAccessToken
        for (let i = 0; i < localStorage.length; i++) {
          if (
            localStorage.key(i) &&
            localStorage.key(i)!.includes("accessToken")
          ) {
            amplifyAccessToken = localStorage.getItem(localStorage.key(i)!)!
          }
        }

        // clear local storage from amplify session
        localStorage.clear()

        // encrypt amplify accessToken
        const { data } = await post(
          "/web/encrypt",
          {},
          {
            Authorization: "Bearer " + amplifyAccessToken,
          },
          false
        )

        const key = data.key
        const iv = data.iv

        // do a normal signin
        const result = await post(
          "/web/signin/withsession",
          {},
          {
            key: key,
            iv: iv,
          },
          false
        )

        // set tokens to local storage
        localStorage.setItem("accessToken", result.data.AccessToken)
        localStorage.setItem("refreshToken", result.data.RefreshToken)

        // get current user
        await getUserInfo(true)

        return true
      } else {
        logger("wrong code")

        return false
      }
    } catch (e: any) {
      logger("confirmSignIn error", e)

      if (
        e.response.data.errors[0] === "footprint in cfstation not found" ||
        e.response.data.errors[0] === "sessionid not found"
      ) {
        return 0
      }

      return false
    }
  }

  // signin for users coming from app
  const appSignIn = async (key: string, iv: string) => {
    try {
      const result = await post(
        "/web/signin",
        {},
        {
          key: key,
          iv: iv,
        },
        false
      )

      // set tokens to local storage
      localStorage.setItem("accessToken", result.data.AccessToken)
      localStorage.setItem("refreshToken", result.data.RefreshToken)

      return true
    } catch (e) {
      logger("appSignIn error", e)

      setSignUpError(true)
      return false
    }
  }

  // check auth session
  const checkSession = async () => {
    const key = searchParams.get("key")
    const iv = searchParams.get("iv")
    const privacy = searchParams.get("privacy")

    if (key && iv) {
      if (privacy === "true") {
        // app signin
        await appSignIn(key, iv)

        // get current user info
        await getUserInfo(true)

        setViewOnboarding(false)
      }

      setLoading(false)
    } else {
      setSignUpError(true)
    }
  }

  // initial fetch
  useEffect(() => {
    // get language from navigator and set it
    const langFromNavigator = navigator.language.slice(0, 2)
    if (langFromNavigator) {
      i18next.changeLanguage(langFromNavigator)
      if (availableLanguages.some((item) => item.code === langFromNavigator)) {
        document.documentElement.lang = langFromNavigator
        setLang(langFromNavigator)
      } else {
        document.documentElement.lang = "en"
        setLang("en")
      }
    }

    checkSession()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContext.Provider
      value={{
        loading,
        setLoading,
        signUpError,
        viewOnboarding,
        viewTutorial,
        setViewTutorial,
        viewAvatarSelection,
        setViewAvatarSelection,
        setSignUpError,
        setViewOnboarding,
        isMobile,
        windowWidth,
        windowHeight,
        lang,
        user,
        setUser,
        userError,
        getUserInfo,
        setUserFirstAccess,
        changeLang,
        visualizingErrorPage,
        setVisualizingErrorPage,
        visualizingLoadingPage,
        setVisualizingLoadingPage,
        currentTutorialPage,
        setCurrentTutorialPage,
        avatars,
        changeAvatar,
        currentMission,
        setCurrentMission,
        updatingMissions,
        setUpdatingMissions,
        sendCode,
        confirmCode,
        shouldSignIn,
        setShouldSignIn,
        footprintFeedbackOpen,
        setFootprintFeedbackOpen,
        appSignIn,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
