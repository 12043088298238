import { useNavigate } from "react-router-dom"
import Action from "../../models/action"
import { Stack } from "@mui/material"
import {
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { scrollWindowToTop } from "../../services/utils/utils"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import { useContext } from "react"
import { MainContext } from "../../controllers/main"

const ActionCalculationsDesktop = ({ action }: { action: Action }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: windowHeight - footerHeight,
      }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("actions"),
            onClick: () => {
              navigate("/actions")
            },
          },
          ...(window.location.pathname.includes("/explore")
            ? [
                {
                  label: t("explore_all_actions"),
                  onClick: () => {
                    scrollWindowToTop()
                    navigate("/actions/explore")
                  },
                },
              ]
            : []),
          {
            label: action.document.title,
            onClick: () => {
              scrollWindowToTop()
              navigate(
                window.location.pathname.slice(
                  0,
                  window.location.pathname.indexOf("/calculations")
                )
              )
            },
          },
          {
            label: t("how_is_calculated"),
          },
        ]}
        selectedItem={window.location.pathname.includes("/explore") ? 3 : 2}
        style={{ marginTop: 22, zIndex: 1 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            paddingBottom: 60,
          }}
        >
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22 }}
              component="h1"
            >
              {t("how_is_calculated")}
            </Title>
          </AppearFromSide>
          {/* content */}
          <AppearFromSide
            fade
            delay={0.05}
            style={{ width: "100%", marginTop: 16 }}
          >
            <div
              className="html"
              dangerouslySetInnerHTML={{
                __html: action!.document.assumptions!.replace(
                  /<\/?a[^>]*>/g,
                  ""
                ),
              }}
              style={{
                fontSize: 20,
                fontWeight: 300,
                lineHeight: "32px",
                color: colors.text,
                overflow: "hidden",
              }}
            />
          </AppearFromSide>
        </Stack>
      </PaddingContainerDesktop>
    </Stack>
  )
}

export default ActionCalculationsDesktop
