export const contestRulesIT = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLU6URoVWdvrt6olE8F0BqMIqHRdycFbaa49uhq8ou3xL);
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c38 {
        -webkit-text-decoration-skip: none;
        color: #000000;
        font-weight: 400;
        text-decoration: underline;
        vertical-align: baseline;
        text-decoration-skip-ink: none;
        font-size: 12pt;
        font-family: "Calibri";
        font-style: italic;
      }
      .c79 {
        margin-left: 1pt;
        padding-top: 0.6pt;
        text-indent: -0.8pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c57 {
        margin-left: 0.1pt;
        padding-top: 0.6pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 1.016846776008606;
        text-align: justify;
        margin-right: 1.8pt;
      }
      .c74 {
        margin-left: 36.3pt;
        padding-top: 6.6pt;
        text-indent: -17.2pt;
        padding-bottom: 0pt;
        line-height: 1.050166130065918;
        text-align: justify;
        margin-right: 1.7pt;
      }
      .c5 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c55 {
        margin-left: 0.3pt;
        padding-top: 0.4pt;
        text-indent: 0.7pt;
        padding-bottom: 0pt;
        line-height: 1.0168499946594238;
        text-align: left;
        margin-right: 2.3pt;
      }
      .c51 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 1.7pt;
      }
      .c44 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016847848892212;
        text-align: left;
        margin-right: 1.9pt;
      }
      .c9 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 3.3pt;
      }
      .c54 {
        margin-left: 36.9pt;
        padding-top: 0pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c23 {
        margin-left: 0.2pt;
        padding-top: 0.6pt;
        text-indent: 0pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 1.9pt;
      }
      .c36 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Noto Sans Symbols";
        font-style: normal;
      }
      .c47 {
        margin-left: 0.8pt;
        padding-top: 0pt;
        text-indent: -0.3pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 2pt;
      }
      .c67 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 1.5pt;
      }
      .c31 {
        margin-left: 0.9pt;
        padding-top: 0.6pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c11 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.0168499946594238;
        text-align: left;
        margin-right: 2.8pt;
      }
      .c19 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 8.8pt;
      }
      .c21 {
        margin-left: 36.5pt;
        padding-top: 6.2pt;
        text-indent: -17.4pt;
        padding-bottom: 0pt;
        line-height: 1.0410786867141724;
        text-align: justify;
        margin-right: 1.8pt;
      }
      .c71 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Times New Roman";
        font-style: normal;
      }
      .c1 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Calibri";
        font-style: normal;
      }
      .c24 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.0168452262878418;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c59 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016837239265442;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c61 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        text-indent: 17.5pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 2.5pt;
      }
      .c22 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 2pt;
      }
      .c32 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016844630241394;
        text-align: justify;
        margin-right: 1.5pt;
      }
      .c40 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 3.3pt;
      }
      .c35 {
        margin-left: 0.8pt;
        padding-top: 0.6pt;
        text-indent: 0.2pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 2pt;
      }
      .c63 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 2pt;
      }
      .c26 {
        margin-left: 39.1pt;
        padding-top: 5.5pt;
        text-indent: -17.8pt;
        padding-bottom: 0pt;
        line-height: 1.2834118604660034;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c48 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.0168499946594238;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c43 {
        margin-left: 0.2pt;
        padding-top: 0.6pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 2.2pt;
      }
      .c27 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 2.1pt;
      }
      .c2 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c39 {
        margin-left: 36.4pt;
        padding-top: 0.4pt;
        text-indent: -18.4pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 0.4pt;
      }
      .c41 {
        margin-left: 0.2pt;
        padding-top: 0.6pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.016844630241394;
        text-align: justify;
        margin-right: 2.1pt;
      }
      .c12 {
        margin-left: 0.1pt;
        padding-top: 0.4pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c56 {
        margin-left: 0.2pt;
        padding-top: 0.6pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 1.9pt;
      }
      .c18 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 2.1pt;
      }
      .c66 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: -0.8pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 3.4pt;
      }
      .c30 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c58 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 1.8pt;
      }
      .c29 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 1.016844630241394;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c65 {
        margin-left: 0.9pt;
        padding-top: 0.4pt;
        text-indent: 0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 1.6pt;
      }
      .c62 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.0168449878692627;
        text-align: justify;
        margin-right: 1.8pt;
      }
      .c69 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 3.2pt;
      }
      .c17 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016847848892212;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c76 {
        margin-left: 39.1pt;
        padding-top: 6.8pt;
        text-indent: -17.8pt;
        padding-bottom: 0pt;
        line-height: 1.1057010889053345;
        text-align: justify;
        margin-right: 2.1pt;
      }
      .c42 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: justify;
        margin-right: 1.5pt;
      }
      .c20 {
        margin-left: 36.5pt;
        padding-top: 6.2pt;
        text-indent: -17.4pt;
        padding-bottom: 0pt;
        line-height: 1.0501649379730225;
        text-align: justify;
        margin-right: 1.7pt;
      }
      .c52 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.5pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 3pt;
      }
      .c72 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016846776008606;
        text-align: justify;
        margin-right: 2pt;
      }
      .c64 {
        margin-left: 1pt;
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1.016854166984558;
        text-align: left;
        margin-right: 3.4pt;
      }
      .c34 {
        margin-left: 0.1pt;
        padding-top: 0.6pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
      }
      .c46 {
        margin-left: 0.8pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 3.2pt;
      }
      .c70 {
        margin-left: 1pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1.016845703125;
        text-align: left;
        margin-right: 46.9pt;
      }
      .c33 {
        margin-left: 90.3pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c45 {
        margin-left: 0.1pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c14 {
        margin-left: 0.3pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c16 {
        margin-left: 0pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c78 {
        margin-left: 0.4pt;
        padding-top: 14.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c53 {
        margin-left: 0.4pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c10 {
        margin-left: 1pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c4 {
        margin-left: 0.1pt;
        padding-top: 11pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c13 {
        margin-left: 0.3pt;
        padding-top: 15.3pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c8 {
        margin-left: 19pt;
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c0 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c28 {
        margin-left: 0.8pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c60 {
        margin-left: 0.3pt;
        padding-top: 14.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c25 {
        margin-left: 0.8pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c6 {
        margin-left: 1pt;
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c37 {
        margin-left: 0.8pt;
        padding-top: 15.1pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c80 {
        margin-left: 0.4pt;
        padding-top: 14.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c50 {
        margin-left: 19pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c75 {
        margin-left: 0.4pt;
        padding-top: 15.1pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c77 {
        margin-left: 0.1pt;
        padding-top: 14.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c3 {
        margin-left: 0.8pt;
        padding-top: 14.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c7 {
        margin-left: 19pt;
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c49 {
        margin-left: 0.5pt;
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c68 {
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c15 {
        padding-top: 0.6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: center;
      }
      .c73 {
        background-color: #ffffff;
        max-width: 485.4pt;
        padding: 70.5pt 53.9pt 67.5pt 56.7pt;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    </style>
  </head>
  <body class="c73 doc-content">
    <p class="c33">
      <span class="c5">REGOLAMENTO ART.11 D.P.R. 26 OTTOBRE 2001, N. 430 </span>
    </p>
    <p class="c15"><span class="c5">CONCORSO A PREMI DENOMINATO </span></p>
    <p class="c15">
      <span class="c71">&ldquo;</span
      ><span class="c5">PRENDI PARTE AL CAMBIAMENTO&rdquo; </span>
    </p>
    <p class="c60"><span class="c5">SOGGETTO PROMOTORE </span></p>
    <p class="c79">
      <span class="c1"
        >AWorld Srl Societ&agrave; Benefit con sede in Corso Regina Margherita -
        10124 Torino (TO), C.F. e P.IVA 11867110014 (nel seguito
        &ldquo;Promotore&rdquo;).
      </span>
    </p>
    <p class="c14"><span class="c5">SOGGETTO DELEGATO </span></p>
    <p class="c11">
      <span class="c1"
        >Clipper Srl, con sede in Viale Caterina da Forl&igrave; 32 &ndash;
        20146 &ndash; Milano &ndash; P. IVA 06601410159 (nel seguito
        &ldquo;Delegato&rdquo;).
      </span>
    </p>
    <p class="c16"><span class="c5">TIPOLOGIA E DENOMINAZIONE </span></p>
    <p class="c49">
      <span class="c1"
        >Concorso a premi denominato &ldquo;Prendi parte al cambiamento&rdquo;
        (nel seguito &ldquo;Concorso&rdquo;).
      </span>
    </p>
    <p class="c77"><span class="c5">AMBITO TERRITORIALE </span></p>
    <p class="c6">
      <span class="c1"
        >Il Concorso ha svolgimento sull&rsquo;intero territorio nazionale
        italiano.
      </span>
    </p>
    <p class="c13"><span class="c5">SCOPO DEL CONCORSO </span></p>
    <p class="c41">
      <span class="c1"
        >Il Concorso viene indetto con lo scopo di incentivare il download, la
        conoscenza e l&rsquo;utilizzo dell&rsquo;App AWorld di propriet&agrave;
        del promotore, nonch&eacute; il completamento delle missioni educative
        previste (nel seguito &ldquo;App&rdquo;).
      </span>
    </p>
    <p class="c28"><span class="c5">DURATA </span></p>
    <p class="c35">
      <span class="c1"
        >La partecipazione al Concorso sar&agrave; ammessa dal giorno 16 aprile
        2024 al giorno 16 maggio 2024 (nel seguito &ldquo;Durata&rdquo;).
      </span>
    </p>
    <p class="c10">
      <span class="c1"
        >L&rsquo;estrazione dei vincitori sar&agrave; svolta al termine del
        Concorso, entro il 28 giugno 2024.
      </span>
    </p>
    <p class="c3"><span class="c5">DESTINATARI </span></p>
    <p class="c32">
      <span class="c1"
        >Il Concorso &egrave; riservato a tutti i soggetti persone fisiche,
        maggiorenni al momento della partecipazione, dotati di uno smartphone
        con sistema operativo &ldquo;Android&rdquo; e &ldquo;iOS&rdquo; che, nel
        corso della durata, abbiano scaricato l&rsquo;App AWorld di
        propriet&agrave; del Promotore, si siano registrati ed abbiano
        completato le missioni di volta in volta presenti, ottenendo il relativo
        punteggio (nel seguito &ldquo;Partecipanti&rdquo;).
      </span>
    </p>
    <p class="c53">
      <span class="c1">Sono esclusi dalla partecipazione al Concorso: </span>
    </p>
    <p class="c8">
      <span class="c2">&#9679; </span
      ><span class="c1"
        >tutti i dipendenti ed i collaboratori del Promotore e del Delegato;
      </span>
    </p>
    <p class="c8">
      <span class="c2">&#9679; </span
      ><span class="c1"
        >tutti i soggetti che abbiano contribuito alla realizzazione del
        Concorso;
      </span>
    </p>
    <p class="c8">
      <span class="c2">&#9679; </span
      ><span class="c1"
        >tutti i soggetti che non rientrino nella specifica definizione di
        Partecipanti.
      </span>
    </p>
    <p class="c78"><span class="c5">COMUNICAZIONE </span></p>
    <p class="c64">
      <span class="c1"
        >Il Concorso sar&agrave; pubblicizzato attraverso diversi canali di
        comunicazione on line ed off line che il Promotore riterr&agrave; idonei
        per il raggiungimento del proprio obiettivo.
      </span>
    </p>
    <p class="c18">
      <span class="c1"
        >Il regolamento integrale del concorso potr&agrave; essere consultato
        dai partecipanti nell&rsquo;apposita sezione all&rsquo;interno
        dell&rsquo;applicazione
      </span>
    </p>
    <p class="c45"><span class="c5">AVVERTENZE </span></p>
    <p class="c68">
      <span class="c38"
        >Si precisa che i dati dei Partecipanti saranno salvati su un server
        ubicato in Italia.</span
      >
    </p>
    <p class="c25">
      <span class="c5">MODALITA&rsquo; DI PARTECIPAZIONE </span>
    </p>
    <p class="c56">
      <span class="c1"
        >Al fine di incentivare le interazioni con l&rsquo;App e promuovere
        comportamenti virtuosi, il Promotore organizza il Concorso che
        avr&agrave; svolgimento come di seguito indicato e che premier&agrave; i
        n. 10 utenti in graduatoria che, nell&rsquo;impegnarsi in un percorso di
        comportamenti sostenibili, abbiano fatto registrare i migliori risultati
        e n. 1 utente attraverso apposita estrazione a sorte.
      </span>
    </p>
    <p class="c12">
      <span class="c1"
        >In particolare, per prendere parte al Concorso, i Partecipanti, nel
        corso della Durata, dovranno preliminarmente effettuare il download
        dell&rsquo;App sul proprio smartphone o device mobile e completare,
        altres&igrave;, la procedura di registrazione, compilando tutti i dati
        richiesti. Il medesimo Partecipante, riconoscibile dai dati utilizzati,
        potr&agrave; completare la registrazione una sola volta: qualora si
        verificassero registrazioni multiple da parte del medesimo Partecipante,
        saranno automaticamente cancellate quelle effettuate successivamente
        alla prima. Tutti i Partecipanti che risultassero gi&agrave; registrati
        in data antecedente rispetto all&rsquo;inizio del Concorso non saranno
        tenuti a ripetere le procedure di registrazione e potranno accedere con
        le credenziali in loro possesso.
      </span>
    </p>
    <p class="c9">
      <span class="c1"
        >Una volta completata la registrazione, ciascun Partecipante
        potr&agrave; scoprire le missioni individuali previste e che saranno
        suddivise in:
      </span>
    </p>
    <p class="c74">
      <span class="c36">&#11162; </span
      ><span class="c1"
        >missioni giornaliere: ogni giorno, per l&rsquo;intera Durata del
        Concorso, sar&agrave; proposta la missione giornaliera il cui
        completamento consentir&agrave; al singolo Partecipante di ottenere da
        un minimo di n. 1 (uno) punto ad un massimo di n. 10 (dieci) punti (il
        punteggio di ciascuna missione giornaliera sar&agrave; chiaramente
        dettagliato sull&rsquo;App). Il medesimo Partecipante potr&agrave;
        decidere se prendere parte a tutte le missioni giornaliere o solamente
        ad alcune, non essendo obbligatorio completarle integralmente. Si
        tratter&agrave; di missioni educative sul tema della
        sostenibilit&agrave;, attraverso la pubblicazione di contenuti formativi
        e richiesta di azioni, che i Partecipanti dovranno leggere e registrare
        per completare la missione stessa ed ottenere il relativo punteggio.
      </span>
    </p>
    <p class="c20">
      <span class="c36">&#11162; </span
      ><span class="c1"
        >missioni settimanali: ogni settimana, per l&rsquo;intera Durata del
        Concorso, sar&agrave; proposta la missione settimanale il cui
        completamento consentir&agrave; al singolo Partecipante di ottenere un
        minimo di n. 8 (otto) punti ad un massimo di n. 90 (novanta) punti (il
        punteggio di ciascuna missione settimanale sar&agrave; chiaramente
        dettagliato sull&rsquo;App). Il medesimo Partecipante potr&agrave;
        decidere se prendere parte a tutte le missioni settimanali o solamente
        ad alcune, non essendo obbligatorio completarle integralmente. Si
        tratter&agrave; di missioni educative sul tema ambientale, volte anche
        misurare l&rsquo;impronta di CO2 personale attraverso appositi
        questionari, nonch&eacute; la richiesta di azioni da registrare. Il
        completamento di ciascuna missione settimanale consentir&agrave; al
        Partecipante di ottenere il relativo punteggio
      </span>
    </p>
    <p class="c21">
      <span class="c36">&#11162; </span
      ><span class="c1"
        >missioni speciali: nel corso della Durata, saranno altres&igrave;
        proposte le missioni speciali, il cui contenuto e la cui frequenza
        sar&agrave; dettagliatamente descritta in App. Anche per queste
        missioni, il relativo completamento consentir&agrave; al singolo
        Partecipante di ottenere n. 140 (centoquaranta) punti, come chiaramente
        dettagliato sull&rsquo;App. Le missioni speciali, analogamente alle
        missioni giornaliere/settimanali, potranno essere completate o meno a
        scelta del Partecipante ed il mancato completamento delle stesse non
        impedir&agrave; comunque la partecipazione all&rsquo;estrazione (a
        condizione che il Partecipante abbia comunque ottenuto almeno n. 1 punto
        nel corso dell&rsquo;intera Durata). Le missioni speciali saranno
        strutturate in maniera analoga alle missioni giornaliere/settimanali:
        anche in tal caso, il Partecipante sar&agrave; invitato a leggere
        contenuti educativi in tema di sostenibilit&agrave;, registrare azioni
        che dimostrino il comportamento virtuoso del Partecipante, misurare
        l&rsquo;impronta CO2 personale e registrare tali risultati.</span
      >
    </p>
    <p class="c54">
      <span class="c1"
        >Il semplice completamento della missione permetter&agrave; al
        Partecipante di ottenere il relativo punteggio.
      </span>
    </p>
    <p class="c27">
      <span class="c1"
        >Per ciascuna missione completata (giornaliera/settimanale/speciale), il
        punteggio di ciascun Partecipante si aggiorner&agrave; e sar&agrave;
        consultabile nell&rsquo;area riservata dell&rsquo;App AWorld nella
        sezione dedicata (classifica).
      </span>
    </p>
    <p class="c40">
      <span class="c1"
        >Il software che gestisce l&rsquo;App sar&agrave; in grado di registrare
        per ciascun partecipante le azioni sostenibili richieste ed il relativo
        punteggio.
      </span>
    </p>
    <p class="c0">
      <span class="c1">Al termine del Concorso, sar&agrave; predisposto: </span>
    </p>
    <p class="c76">
      <span class="c36">&#11162; </span
      ><span class="c1"
        >un tabulato generale di tutti i Partecipati da utilizzare per
        l&rsquo;estrazione del premio: ciascun Partecipante sar&agrave; inserito
        all&rsquo;interno del tabulato tante volte quanti saranno i punti
        progressivamente cumulati nel corso dell&rsquo;intera Durata, avendo,
        pertanto, maggiori probabilit&agrave; di essere estratto quanti
        pi&ugrave; punti siano stati conseguiti;
      </span>
    </p>
    <p class="c26">
      <span class="c36">&#11162; </span
      ><span class="c1"
        >un tabulato che riporti la graduatoria a scalare sulla base del
        punteggio complessivamente realizzato da ciascun partecipante.
      </span>
    </p>
    <p class="c4"><span class="c5">VERBALIZZAZIONI </span></p>
    <p class="c23">
      <span class="c1"
        >Al termine del Concorso ed entro il 28 giugno 2024 a Milano, presso la
        sede del Delegato o altra sede da concordare, alla presenza di un
        Pubblico Ufficiale (Notaio o Funzionario della Camera di Commercio)
        competente per territorio, sar&agrave; svolta l&rsquo;estrazione
        utilizzando il relativo file, riportante tutti gli aventi diritto,
        eventualmente ripetuti in funzione di quanto descritto.
      </span>
    </p>
    <p class="c30">
      <span class="c1"
        >Dal file sar&agrave; estratto n. 1 vincitore e n. 5 riserve che
        subentreranno in caso di necessit&agrave; nell&rsquo;esatto ordine di
        estrazione.
      </span>
    </p>
    <p class="c65">
      <span class="c1"
        >Nella medesima occasione, si proceder&agrave; a verbalizzare la
        graduatoria unica, secondo quanto precedentemente specificato, e saranno
        premiati i n. 10 Partecipanti che abbiano ottenuto i punteggi pi&ugrave;
        elevati.
      </span>
    </p>
    <p class="c72">
      <span class="c1"
        >Inoltre, saranno verbalizzati i successivi n. 5 Partecipanti in
        graduatoria che fungeranno da riserve qualora di necessit&agrave;: le
        riserve subentreranno, in ordine di posizionamento, ai vincitori in caso
        di irreperibilit&agrave; o di inadempienza di questi ultimi.
      </span>
    </p>
    <p class="c44">
      <span class="c1"
        >In caso di parimerito nella graduatoria, il posizionamento dei
        vincitori e delle riserve sar&agrave; definito mediante estrazione a
        sorte.
      </span>
    </p>
    <p class="c55">
      <span class="c1"
        >Il medesimo Partecipante potr&agrave; aggiudicarsi il premio in
        estrazione e un solo premio nella graduatoria.
      </span>
    </p>
    <p class="c80"><span class="c5">COMUNICAZIONE DI VINCITA </span></p>
    <p class="c34">
      <span class="c1"
        >Tutti i vincitori saranno personalmente avvisati mediante comunicazione
        e-mail inviata all&rsquo;indirizzo indicato in sede di adesione al
        concorso: i Partecipanti sono, quindi, invitati a controllare la propria
        casella di posta elettronica e la corrispondenza in arrivo, anche nella
        casella degli spam. Alla comunicazione di vincita sar&agrave; allegato
        il modulo di accettazione che il vincitore dovr&agrave; compilare con
        tutti i dati richiesti e restituire entro 7 giorni di calendario dalla
        data di ricezione della comunicazione di vincita e con le
        modalit&agrave; che saranno dettagliate.
      </span>
    </p>
    <p class="c59">
      <span class="c1"
        >La mancata risposta del vincitore entro tale termine determiner&agrave;
        l&rsquo;impossibilit&agrave; di assegnare il premio ed il subentro della
        riserve in ordine di graduatoria.
      </span>
    </p>
    <p class="c70">
      <span class="c1"
        >Per le riserve saranno adottate le medesime tempistiche e
        modalit&agrave; di avviso della vincita. Il Promotore non assume alcuna
        responsabilit&agrave; qualora:
      </span>
    </p>
    <p class="c50">
      <span class="c2">&#9679; </span
      ><span class="c1">la mailbox risulti piena </span>
    </p>
    <p class="c8">
      <span class="c2">&#9679; </span
      ><span class="c1"
        >l&rsquo;email indicata in fase di registrazione fosse errata o
        incompleta
      </span>
    </p>
    <p class="c8">
      <span class="c2">&#9679; </span
      ><span class="c1"
        >non vi fosse risposta dall&rsquo;host computer dopo l&rsquo;invio
        dell&rsquo;e-mail di notifica della vincita</span
      >
    </p>
    <p class="c7">
      <span class="c2">&#9679; </span
      ><span class="c1">la mailbox risulti disabilitata; </span>
    </p>
    <p class="c8">
      <span class="c2">&#9679; </span
      ><span class="c1"
        >l&rsquo;email indicata in fase di registrazione fosse inserita in una
        black-list.
      </span>
    </p>
    <p class="c19">
      <span class="c1"
        >A seguito dell&rsquo;accettazione, i vincitori riceveranno tutte le
        informazioni necessarie per poter fruire del premio.
      </span>
    </p>
    <p class="c37"><span class="c5">PREMI </span></p>
    <p class="c6"><span class="c1">Estrazione: </span></p>
    <p class="c61">
      <span class="c1"
        >&#11162; 1&deg; estratto: n 1 Forno a vapore Electrolux SteamBoost:
        Forno a vapore con connettivit&agrave; - EOB9S21WX | Electrolux del
        valore indicativo di </span
      ><span class="c2">&euro; </span
      ><span class="c1">1.499,99 iva inclusa (</span
      ><span class="c2">&euro; </span
      ><span class="c1">1.229,50 + iva) Graduatoria: </span>
    </p>
    <p class="c39">
      <span class="c1"
        >&#11162; dal 1&deg; all&rsquo; 10&deg; classificato: n. 1 Set
        sottovuoto Electrolux: Macchina sottovuoto Vacuum Sealer Electrolux del
        valore indicativo di </span
      ><span class="c2">&euro; </span
      ><span class="c1">109,90 iva inclusa (</span
      ><span class="c2">&euro; </span><span class="c1">90,08 + iva) </span>
    </p>
    <p class="c37"><span class="c5">MONTEPREMI E CAUZIONE </span></p>
    <p class="c43">
      <span class="c1">Il montepremi complessivo ammonta a </span
      ><span class="c2">&euro; </span
      ><span class="c1">2.598,99 iva inclusa (</span
      ><span class="c2">&euro; </span
      ><span class="c1"
        >2.130,32 + IVA). Ai sensi dell&rsquo;art. 7 del D.P.R. n. 430 del 26
        ottobre 2001, &egrave; stata versata una cauzione a copertura del 100%
        del valore complessivo del montepremi a favore del Ministero delle
        Imprese e del Made in Italy.
      </span>
    </p>
    <p class="c75"><span class="c5">ONLUS BENEFICIARIA </span></p>
    <p class="c57">
      <span class="c1"
        >I premi non assegnati o non richiesti, diversamente da quelli
        rifiutati, saranno devoluti ai sensi di legge alla seguente Onlus:
        Fondazione Paideia Ente Filantropico con sede in Via Moncalvo 1, 10131
        Torino - C.F. 97552690014.
      </span>
    </p>
    <p class="c37"><span class="c5">RINUNCIA ALLA RIVALSA </span></p>
    <p class="c17">
      <span class="c1"
        >Il Promotore dichiara di rinunciare ad esercitare la facolt&agrave; di
        rivalsa a favore dei vincitori, ex art. 30 del D.P.R. n. 600 del
        29/09/1973.
      </span>
    </p>
    <p class="c45"><span class="c5">ADEMPIMENTI E GARANZIE </span></p>
    <p class="c31">
      <span class="c1"
        >La partecipazione al Concorso implica l&rsquo;accettazione integrale
        del presente regolamento e delle relative condizioni da parte dei
        Partecipanti.
      </span>
    </p>
    <p class="c58">
      <span class="c1"
        >Eventuali modifiche che dovessero essere apportate al regolamento nel
        corso della Durata saranno preventivamente comunicate ai Partecipanti
        con le medesime modalit&agrave; di comunicazione riservate al presente
        regolamento, nel rispetto dei diritti acquisiti.
      </span>
    </p>
    <p class="c29">
      <span class="c1"
        >I premi in palio non potranno essere commutati in denaro o in gettoni
        d&rsquo;oro. In caso di impossibilit&agrave; sopravvenuta, il Promotore
        si riserva di eventualmente sostituire i premi previsti con beni
        differenti, possibilmente della stessa natura e di valore uguale o
        superiore. I premi saranno resi disponibili entro 180 giorni dalla data
        della verbalizzazione e, comunque, in tempo utile per poterne fruire.
      </span>
    </p>
    <p class="c48">
      <span class="c1"
        >Il Promotore non esercita l&rsquo;attivit&agrave; di Internet provider
        e non trae alcun beneficio dalla connessione alla rete Internet.
      </span>
    </p>
    <p class="c22">
      <span class="c1"
        >La partecipazione attraverso l&rsquo;App risulter&agrave; gratuita
        senza costi aggiuntivi differenti rispetto a quelli del collegamento
        alla rete Internet che il singolo partecipante ha concordato con il
        proprio provider.
      </span>
    </p>
    <p class="c42">
      <span class="c1"
        >Il Promotore non si assume alcuna responsabilit&agrave; per eventi ad
        esso non imputabili, quali a titolo esemplificativo e non esaustivo:
        l&rsquo;accesso, l&rsquo;impedimento, la disfunzione o difficolt&agrave;
        riguardanti gli strumenti tecnici, il computer, i cavi,
        l&rsquo;elettronica, il software e l&rsquo;hardware, la trasmissione e
        la</span
      >
    </p>
    <p class="c47">
      <span class="c1"
        >connessione, la linea telefonica che possano impedire all&rsquo;utente
        di partecipare alla presente iniziativa.
      </span>
    </p>
    <p class="c52">
      <span class="c1"
        >Il Promotore non si assume alcuna responsabilit&agrave; per eventuali
        contaminazioni di virus informatici o per intrusioni di terzi al device
        dei Partecipanti.
      </span>
    </p>
    <p class="c51">
      <span class="c1"
        >I Partecipanti che, secondo il giudizio insindacabile del Promotore o
        di terze parti incaricate dallo stesso alla gestione del Concorso,
        abbiano utilizzato strumenti giudicati in maniera sospetta, fraudolenta,
        o in violazione del normale svolgimento del Concorso stesso, saranno
        esclusi dalla partecipazione e non sar&agrave; considerato valido il
        punteggio maturato in tale modo.
      </span>
    </p>
    <p class="c66">
      <span class="c1"
        >Tutti i tentativi di uso fraudolento e di forzatura esterna
        dell&rsquo;App potrebbero integrare illeciti perseguibili penalmente.
      </span>
    </p>
    <p class="c69">
      <span class="c1"
        >Il Promotore si riserva di effettuare tutte le verifiche relative alla
        corretta partecipazione al Concorso.
      </span>
    </p>
    <p class="c63">
      <span class="c1"
        >In caso di accertata irregolarit&agrave; della partecipazione, il
        premio, qualora fosse stato richiesto, non potr&agrave; essere
        riconosciuto.
      </span>
    </p>
    <p class="c24">
      <span class="c1"
        >Inoltre, qualora fossero riscontrate anomalie circa i punteggi
        acquisiti, il Promotore effettuer&agrave; tutte le verifiche necessarie,
        eventualmente correggendo l&rsquo;anomalia prima di svolgere
        l&rsquo;estrazione. Il Promotore non pu&ograve; in nessun caso essere
        ritenuto responsabile di eventuali problematiche sorte durante
        l&rsquo;utilizzo dei premi in palio e non pu&ograve; essere ritenuto
        responsabile dell&rsquo;uso improprio dei premi da parte dei vincitori.
      </span>
    </p>
    <p class="c67">
      <span class="c1"
        >Inoltre, con la partecipazione al Concorso, i Partecipanti manlevano e
        dichiarano il Promotore non responsabile di eventuali problematiche o
        danni e/o infortuni, a cose e/o persone sorti durante la fruizione dei
        premi.
      </span>
    </p>
    <p class="c46">
      <span class="c5"
        >INFORMATIVA SUL TRATTAMENTO DEI DATI AI SENSI del CODICE IN MATERIA DI
        PROTEZIONE DEI DATI PERSONALI E DEL REGOLAMENTO EU N 2016/679
      </span>
    </p>
    <p class="c62">
      <span class="c1"
        >Il Promotore, in qualit&agrave; di titolare autonomo del trattamento,
        si impegna in ordine alle informazioni ed ai dati che acquisir&agrave;
        durante tutto il corso del Concorso al rispetto delle norme e degli
        obblighi imposti dalla vigente normativa in materia di protezione dei
        dati personali (Regolamento Ue 2016/679, D.lgs. 196/2003 e successive
        modifiche ed integrazioni).</span
      >
    </p>
  </body>
</html>
`

export const contestRulesEN = `
<html>
  <head>
    <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
    <style type="text/css">
      @import url(https://themes.googleusercontent.com/fonts/css?kit=OUdT43qE98k1fLl98sLVVqp2XEQ3ORDOYyBwgvLfuxU);
      ol {
        margin: 0;
        padding: 0;
      }
      table td,
      table th {
        padding: 0;
      }
      .c41 {
        margin-left: 72.2pt;
        padding-top: 0.4pt;
        text-indent: 0.8pt;
        padding-bottom: 0pt;
        line-height: 0.9996006488800049;
        text-align: justify;
        margin-right: 0.2pt;
      }
      .c0 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Figtree";
        font-style: normal;
      }
      .c76 {
        margin-left: 72pt;
        padding-top: 0.4pt;
        text-indent: 1pt;
        padding-bottom: 0pt;
        line-height: 0.9996004700660706;
        text-align: justify;
        margin-right: 1.1pt;
      }
      .c73 {
        margin-left: 0pt;
        padding-top: 2.8pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: justify;
        margin-right: 1.2pt;
      }
      .c57 {
        margin-left: 0.5pt;
        padding-top: 0.4pt;
        text-indent: 0.6pt;
        padding-bottom: 0pt;
        line-height: 0.9995995759963989;
        text-align: justify;
        margin-right: 1.3pt;
      }
      .c21 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: 0.6pt;
        padding-bottom: 0pt;
        line-height: 0.9995890259742737;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c36 {
        margin-left: 0.4pt;
        padding-top: 0.4pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9996000528335571;
        text-align: justify;
        margin-right: 1.2pt;
      }
      .c6 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9995974898338318;
        text-align: left;
        margin-right: 1.6pt;
      }
      .c27 {
        margin-left: 0pt;
        padding-top: 14.8pt;
        text-indent: 0pt;
        padding-bottom: 0pt;
        line-height: 0.999610185623169;
        text-align: left;
        margin-right: 1.8pt;
      }
      .c32 {
        margin-left: 0.4pt;
        padding-top: 0.4pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9995988607406616;
        text-align: justify;
        margin-right: 1.5pt;
      }
      .c15 {
        margin-left: 0.1pt;
        padding-top: 14.8pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 0.9995890259742737;
        text-align: left;
        margin-right: 1.5pt;
      }
      .c64 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.999593198299408;
        text-align: justify;
        margin-right: 1.6pt;
      }
      .c31 {
        margin-left: 0pt;
        padding-top: 14.8pt;
        text-indent: 0pt;
        padding-bottom: 0pt;
        line-height: 0.9996012449264526;
        text-align: justify;
        margin-right: 1.6pt;
      }
      .c75 {
        margin-left: 36.1pt;
        padding-top: 0.4pt;
        text-indent: -17pt;
        padding-bottom: 0pt;
        line-height: 0.9995988607406616;
        text-align: justify;
        margin-right: 1.7pt;
      }
      .c22 {
        margin-left: 0.8pt;
        padding-top: 0pt;
        text-indent: -0.8pt;
        padding-bottom: 0pt;
        line-height: 1.9992033243179321;
        text-align: left;
        margin-right: 33.2pt;
      }
      .c19 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.999603807926178;
        text-align: justify;
        margin-right: 2.6pt;
      }
      .c68 {
        margin-left: 0pt;
        padding-top: 14.8pt;
        text-indent: 0pt;
        padding-bottom: 0pt;
        line-height: 0.999593198299408;
        text-align: justify;
        margin-right: 1.2pt;
      }
      .c63 {
        margin-left: 0.4pt;
        padding-top: 2.9pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9996006488800049;
        text-align: justify;
        margin-right: 1.3pt;
      }
      .c53 {
        margin-left: 0pt;
        padding-top: 14.8pt;
        text-indent: 1pt;
        padding-bottom: 0pt;
        line-height: 1.9992033243179321;
        text-align: left;
        margin-right: 72.2pt;
      }
      .c54 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: 0.6pt;
        padding-bottom: 0pt;
        line-height: 0.9995961785316467;
        text-align: left;
        margin-right: 1.5pt;
      }
      .c37 {
        margin-left: 0.2pt;
        padding-top: 29.2pt;
        text-indent: 0.6pt;
        padding-bottom: 0pt;
        line-height: 0.999593198299408;
        text-align: left;
        margin-right: 2.4pt;
      }
      .c48 {
        margin-left: 36.1pt;
        padding-top: 0.4pt;
        text-indent: -17pt;
        padding-bottom: 0pt;
        line-height: 0.999593198299408;
        text-align: left;
        margin-right: 2pt;
      }
      .c10 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.999593198299408;
        text-align: justify;
        margin-right: 1.4pt;
      }
      .c44 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: 0.6pt;
        padding-bottom: 0pt;
        line-height: 0.9995890259742737;
        text-align: left;
        margin-right: 1.3pt;
      }
      .c74 {
        margin-left: 0.2pt;
        padding-top: 0.4pt;
        text-indent: -0.2pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: justify;
        margin-right: 1.4pt;
      }
      .c72 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9995988607406616;
        text-align: justify;
        margin-right: 1.6pt;
      }
      .c55 {
        margin-left: 0.4pt;
        padding-top: 14.8pt;
        text-indent: 0.6pt;
        padding-bottom: 0pt;
        line-height: 0.999610185623169;
        text-align: left;
        margin-right: 2.1pt;
      }
      .c13 {
        margin-left: 0pt;
        padding-top: 14.8pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 0.9995988607406616;
        text-align: justify;
        margin-right: 1.8pt;
      }
      .c2 {
        color: #0d0d0d;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Figtree";
        font-style: normal;
      }
      .c66 {
        margin-left: 0.7pt;
        padding-top: 14.8pt;
        text-indent: 0.3pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: justify;
        margin-right: 1.8pt;
      }
      .c28 {
        margin-left: 0.1pt;
        padding-top: 15.4pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 2.0408518314361572;
        text-align: left;
        margin-right: 52.5pt;
      }
      .c70 {
        margin-left: 0pt;
        padding-top: 14.8pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 0.9995988607406616;
        text-align: justify;
        margin-right: 1.3pt;
      }
      .c20 {
        margin-left: 0.1pt;
        padding-top: 14.8pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 0.9995890259742737;
        text-align: left;
        margin-right: 2.5pt;
      }
      .c39 {
        margin-left: 36.6pt;
        padding-top: 15.4pt;
        text-indent: -17.5pt;
        padding-bottom: 0pt;
        line-height: 0.9995911121368408;
        text-align: justify;
        margin-right: 1.1pt;
      }
      .c12 {
        margin-left: 72pt;
        padding-top: 0.4pt;
        text-indent: 0pt;
        padding-bottom: 0pt;
        line-height: 0.9995993375778198;
        text-align: justify;
        margin-right: 1pt;
      }
      .c23 {
        margin-left: 72.4pt;
        padding-top: 0pt;
        text-indent: 0.3pt;
        padding-bottom: 0pt;
        line-height: 0.999610185623169;
        text-align: left;
        margin-right: 1.1pt;
      }
      .c9 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Figtree";
        font-style: normal;
      }
      .c40 {
        margin-left: 0.1pt;
        padding-top: 14.8pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 0.9995763301849365;
        text-align: left;
        margin-right: 1.9pt;
      }
      .c51 {
        margin-left: 0.4pt;
        padding-top: 0.4pt;
        text-indent: -0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9995974898338318;
        text-align: justify;
        margin-right: 1.2pt;
      }
      .c14 {
        margin-left: 0.7pt;
        padding-top: 15.4pt;
        text-indent: -0.3pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: left;
        margin-right: 1.2pt;
      }
      .c5 {
        color: #0d0d0d;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Figtree";
        font-style: normal;
      }
      .c7 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c25 {
        margin-left: 0pt;
        padding-top: 15.4pt;
        text-indent: -0pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: left;
        margin-right: 1.4pt;
      }
      .c42 {
        margin-left: 0pt;
        padding-top: 14.8pt;
        text-indent: 0pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: justify;
        margin-right: 1.6pt;
      }
      .c4 {
        color: #0d0d0d;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Arial";
        font-style: normal;
      }
      .c11 {
        margin-left: 0.1pt;
        padding-top: 0.4pt;
        text-indent: -0.1pt;
        padding-bottom: 0pt;
        line-height: 0.9996008276939392;
        text-align: justify;
        margin-right: 1.4pt;
      }
      .c50 {
        margin-left: 0.6pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: left;
        margin-right: 84.8pt;
      }
      .c65 {
        padding-top: 14.8pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 0.9995988607406616;
        text-align: justify;
        margin-right: 1.4pt;
      }
      .c71 {
        padding-top: 0pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 0.999593198299408;
        text-align: justify;
        margin-right: 1.3pt;
      }
      .c45 {
        padding-top: 14.8pt;
        text-indent: 0.2pt;
        padding-bottom: 0pt;
        line-height: 1.9992033243179321;
        text-align: left;
        margin-right: 15.5pt;
      }
      .c69 {
        padding-top: 14.8pt;
        text-indent: -0.8pt;
        padding-bottom: 0pt;
        line-height: 0.9995911121368408;
        text-align: justify;
        margin-right: 1.7pt;
      }
      .c59 {
        padding-top: 0.4pt;
        text-indent: -0.3pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: left;
        margin-right: 1.9pt;
      }
      .c38 {
        padding-top: 2.9pt;
        text-indent: -0.8pt;
        padding-bottom: 0pt;
        line-height: 2.0408518314361572;
        text-align: left;
        margin-right: 106.3pt;
      }
      .c60 {
        margin-left: 0pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9995988607406616;
        text-align: left;
        margin-right: 1.7pt;
      }
      .c62 {
        padding-top: 14.8pt;
        text-indent: 0.9pt;
        padding-bottom: 0pt;
        line-height: 0.9996049404144287;
        text-align: justify;
        margin-right: 1.2pt;
      }
      .c46 {
        margin-left: 18.9pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 0.9996017217636108;
        text-align: left;
        margin-right: 2.6pt;
      }
      .c26 {
        margin-left: 1pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c24 {
        margin-left: 0.8pt;
        padding-top: 3.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c43 {
        margin-left: 18.9pt;
        padding-top: 2.8pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c67 {
        margin-left: 19.1pt;
        padding-top: 15.4pt;
        padding-bottom: 0pt;
        line-height: 0.999610185623169;
        text-align: center;
      }
      .c1 {
        margin-left: 55.1pt;
        padding-top: 14.8pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c56 {
        margin-left: 18.9pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c35 {
        margin-left: 0.6pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c47 {
        margin-left: 0pt;
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c18 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: center;
      }
      .c52 {
        padding-top: 29.8pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c58 {
        padding-top: 43.6pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c8 {
        padding-top: 14.8pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c29 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c34 {
        padding-top: 15.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c16 {
        padding-top: 0.4pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: center;
      }
      .c33 {
        padding-top: 29.2pt;
        padding-bottom: 0pt;
        line-height: 1;
        text-align: left;
      }
      .c49 {
        max-width: 485.2pt;
        padding: 70.2pt 54pt 68.5pt 56.8pt;
      }
      .c17 {
        margin-left: 0.3pt;
      }
      .c61 {
        margin-left: 0.1pt;
      }
      .c3 {
        background-color: #ffffff;
      }
      .c30 {
        margin-left: 0.8pt;
      }
      .title {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 36pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      .subtitle {
        padding-top: 18pt;
        color: #666666;
        font-size: 24pt;
        padding-bottom: 4pt;
        font-family: "Georgia";
        line-height: 1.15;
        page-break-after: avoid;
        font-style: italic;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
      }
      h1 {
        padding-top: 24pt;
        color: #000000;
        font-weight: 700;
        font-size: 24pt;
        padding-bottom: 6pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h2 {
        padding-top: 18pt;
        color: #000000;
        font-weight: 700;
        font-size: 18pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h3 {
        padding-top: 14pt;
        color: #000000;
        font-weight: 700;
        font-size: 14pt;
        padding-bottom: 4pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h4 {
        padding-top: 12pt;
        color: #000000;
        font-weight: 700;
        font-size: 12pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h5 {
        padding-top: 11pt;
        color: #000000;
        font-weight: 700;
        font-size: 11pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
      h6 {
        padding-top: 10pt;
        color: #000000;
        font-weight: 700;
        font-size: 10pt;
        padding-bottom: 2pt;
        font-family: "Arial";
        line-height: 1.15;
        page-break-after: avoid;
        orphans: 2;
        widows: 2;
        text-align: left;
      }
    </style>
  </head>
  <body class="c3 c49 doc-content">
    <p class="c18">
      <span class="c5 c3"
        >REGULATION ART.11 D.P.R. OCTOBER 26, 2001, NO. 430</span
      ><span class="c5">&nbsp;</span>
    </p>
    <p class="c16">
      <span class="c5 c3">PRIZE CONTEST NAMED</span
      ><span class="c5">&nbsp;</span>
    </p>
    <p class="c16">
      <span class="c5 c3">&quot;JOIN THE CHANGE&quot;</span
      ><span class="c5">&nbsp;</span>
    </p>
    <p class="c30 c58"><span class="c5">PROMOTER </span></p>
    <p class="c25">
      <span class="c2">A</span
      ><span class="c2 c3"
        >World Srl Benefit Company with registered office at Corso Regina
        Margherita - 10124</span
      ><span class="c2">&nbsp;T</span
      ><span class="c2 c3"
        >urin (TO), VAT number 11867110014 (hereinafter referred to as the
        &quot;Promoter&quot;).</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c34 c30"><span class="c5">DELEGATED ENTITY </span></p>
    <p class="c14">
      <span class="c2 c3"
        >Clipper Srl, with registered office at Viale Caterina da Forl&igrave;
        32 &ndash; 20146 &ndash; Milan &ndash; VAT</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >number 06601410159 (hereinafter referred to as the
        &quot;Delegate&quot;).</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c34 c61"><span class="c5">TYPE AND NAME </span></p>
    <p class="c28">
      <span class="c2 c3"
        >Prize contest named &quot;Join the Change&quot; (hereinafter referred
        to as the &quot;Contest&quot;).</span
      ><span class="c2">&nbsp;</span><span class="c5">TERRITORIAL SCOPE </span>
    </p>
    <p class="c30 c38">
      <span class="c2">T</span
      ><span class="c2 c3"
        >he Contest takes place throughout the entire Italian national
        territory.</span
      ><span class="c2">&nbsp;</span
      ><span class="c5">PURPOSE OF THE CONTEST </span>
    </p>
    <p class="c63">
      <span class="c2">T</span
      ><span class="c2 c3"
        >he Contest is held to encourage the download, knowledge, and use of the
        App AWorld</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >owned by the Promoter, as well as the completion of the educational
        missions provided</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >(hereinafter referred to as the &quot;App&quot;).</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c33 c30"><span class="c9">DURATION </span></p>
    <p class="c57">
      <span class="c0"
        >Participation in the Contest will be allowed from April 16, 2024, to
        May 16, 2024 (hereinafter referred to as the &quot;Duration&quot;). The
        winners will be drawn at the end of the Contest, by June 28, 2024.
      </span>
    </p>
    <p class="c8 c30"><span class="c9">RECIPIENTS </span></p>
    <p class="c36">
      <span class="c0"
        >The Contest is open to all individuals of legal age at the time of
        participation, who possess a smartphone with &quot;Android&quot; and
        &quot;iOS&quot; operating systems, and who, during the Duration, have
        downloaded the AWorld App owned by the Promoter, registered, and
        completed the respective missions, obtaining the corresponding score
        (hereinafter referred to as &quot;Participants&quot;). The following are
        excluded from participating in the Contest:
      </span>
    </p>
    <p class="c35">
      <span class="c0"
        >- all employees and collaborators of the Promoter and the Delegate;
      </span>
    </p>
    <p class="c50">
      <span class="c0"
        >- all individuals who have contributed to the realization of the
        Contest; - all individuals who do not fall within the specific
        definition of Participants.</span
      >
    </p>
    <p class="c17 c29"><span class="c9">COMMUNICATION </span></p>
    <p class="c60">
      <span class="c0"
        >The Contest will be advertised through various online and offline
        communication channels considered suitable by the Promoter to achieve
        its objective. The full contest rules can be consulted by Participants
        in the dedicated section within the application.
      </span>
    </p>
    <p class="c8 c30"><span class="c9">DISCLAIMER </span></p>
    <p class="c26">
      <span class="c0"
        >It is specified that the data of the Participants will be stored on a
        server located in Italy.
      </span>
    </p>
    <p class="c8 c30">
      <span class="c5 c3">MODE OF PARTICIPATION</span
      ><span class="c5">&nbsp;</span>
    </p>
    <p class="c32">
      <span class="c2 c3"
        >To encourage interactions with the App and promote virtuous behaviors,
        the Promoter</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >organizes the Contest, which will take place as indicated below and
        will award the top 10</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >users on the leaderboard who, by engaging in a path of sustainable
        behaviors, have</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >achieved the best results, and 1 user through a special random
        draw.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c11">
      <span class="c2 c3"
        >To take part in the Contest, Participants must, during the Duration,
        download the App to</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >their smartphone or mobile device and complete the registration
        procedure, filling in all</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3">the required data.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c74">
      <span class="c2 c3"
        >The same Participant, identifiable by the data used, may complete the
        registration only</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >once: if multiple registrations by the same Participant are found, all
        those made after the</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3">first one will be automatically deleted.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c51">
      <span class="c2 c3"
        >All Participants who were already registered before the start of the
        Contest will not be</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >required to repeat the registration procedures and can access with the
        pre-existing</span
      ><span class="c2">&nbsp;</span><span class="c2 c3">credentials.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c30 c59">
      <span class="c2 c3"
        >Once registration is completed, each Participant can discover the
        individual missions</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3">provided, which will be divided into:</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c1">
      <span class="c7">&#10146; </span><span class="c9">DAILY MISSIONS </span>
    </p>
    <p class="c41">
      <span class="c0"
        >Every day, throughout the entire Contest Duration, a daily mission will
        be proposed, completion of which will allow individual Participants to
        earn from a minimum of 1 (one) point to a maximum of 10 (ten) points
        (the score of each daily mission will be clearly detailed on the App).
        The same Participant can choose whether to take part in all daily
        missions or only some, as it is not mandatory to complete them entirely.
        These missions will be educational, focusing on sustainability themes,
        involving the publication of educational content and the completion of
        actions. Participants must read and log these actions to complete the
        mission and earn the corresponding score.
      </span>
    </p>
    <p class="c1">
      <span class="c7">&#10146; </span><span class="c9">WEEKLY MISSIONS </span>
    </p>
    <p class="c76">
      <span class="c0"
        >Every week, throughout the entire Contest Duration, a weekly mission
        will also be proposed, completion of which will allow individual
        Participants to earn a minimum of 8 (eight) points to a maximum of 90
        (ninety) points (the score of each weekly mission will be clearly
        detailed on the App). Similarly to daily missions, Participants can
        choose whether to participate in all weekly missions or only some, as it
        is not mandatory to complete them entirely. These missions will focus on
        environmental themes, including measuring personal CO2 footprint through
        specific questionnaires and completing</span
      >
    </p>
    <p class="c23">
      <span class="c0"
        >logged actions. Completion of each weekly mission will allow
        Participants to earn the corresponding score.
      </span>
    </p>
    <p class="c1">
      <span class="c7">&#10146; </span><span class="c9">SPECIAL MISSIONS </span>
    </p>
    <p class="c12">
      <span class="c0"
        >Throughout the Duration, special missions will be proposed, with their
        content and frequency detailed in the App. Completion of these missions
        will allow individual Participants to earn 140 (one hundred and forty)
        points each, as clearly detailed on the App. Special missions, like
        daily and weekly missions, can be completed at the Participant&#39;s
        discretion. Failure to complete these missions will not prevent
        participation in the draw (provided that the Participant has earned at
        least 1 point during the entire Duration). These missions will be
        structured similarly to daily and weekly missions, involving reading
        educational content on sustainability, logging actions demonstrating
        virtuous behavior, measuring personal CO2 footprint, and recording these
        results. Simple completion of the mission will allow Participants to
        earn the corresponding score.
      </span>
    </p>
    <p class="c54">
      <span class="c2 c3"
        >For each completed mission (daily/weekly/special), the score of each
        Participant will be</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >updated and available in the dedicated area of the AWorld App
        (leaderboard section).</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >For each Participant, the software managing the App will be able to
        record the required</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >sustainable actions and their corresponding scores.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c47">
      <span class="c2 c3"
        >At the end of the Contest, the following will be arranged:</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c75">
      <span class="c4 c3">&#10146; </span
      ><span class="c2 c3"
        >a general list of all Participants to be used for the prize draw: each
        Participant will</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >be included in the list as many times as the points accumulated
        throughout the</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >entire Duration, therefore having a higher chance of being drawn the
        more points</span
      ><span class="c2">&nbsp;</span><span class="c2 c3">they have earned;</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c48">
      <span class="c4 c3">&#10146; </span
      ><span class="c2 c3"
        >a list displaying the ranking in descending order, determined by each
        Participant&#39;s</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3">total score achieved.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c8"><span class="c9">VERBALIZATIONS </span></p>
    <p class="c72">
      <span class="c0"
        >At the end of the Contest and by June 28, 2024, in Milan, at the
        Delegate&#39;s headquarters or another agreed-upon location, in the
        presence of a competent Public Officer (Notary or Chamber of Commerce
        Official) for the territory, the draw will be conducted using the
        corresponding file, listing all eligible Participants, potentially
        repeated as described.
      </span>
    </p>
    <p class="c44">
      <span class="c0"
        >From the file, 1 winner and 5 alternates will be drawn, who will step
        in if necessary in the exact order of extraction.
      </span>
    </p>
    <p class="c15">
      <span class="c0"
        >During the same occasion, a single ranking will be verbalized, as
        previously specified, and the top 10 Participants with the highest
        scores will be awarded.
      </span>
    </p>
    <p class="c19">
      <span class="c0"
        >Additionally, the next 5 Participants in the ranking will be verbalized
        as alternates, who will act as reserves if needed: the reserves will
        step in, in order of placement, in case of unavailability or
        non-compliance by the winners.
      </span>
    </p>
    <p class="c21">
      <span class="c0"
        >In the event of a tie in the ranking, the placement of the winners and
        reserves will be determined by random draw.</span
      >
    </p>
    <p class="c22">
      <span class="c0"
        >The same Participant may win the prize in the draw and only one prize
        in the ranking. </span
      ><span class="c9">NOTIFICATION OF WINNING </span>
    </p>
    <p class="c73">
      <span class="c0"
        >All winners will be personally notified via email sent to the address
        provided upon entry to the contest: Participants are therefore invited
        to check their email inbox and incoming mail, including the spam folder.
      </span>
    </p>
    <p class="c64">
      <span class="c0"
        >The notification of winning will be accompanied by an acceptance form
        that the winner must fill out with all required information and return
        within 7 calendar days from the date of receipt of the notification of
        winning and using the detailed instructions provided.
      </span>
    </p>
    <p class="c55">
      <span class="c0"
        >Failure to respond by the winner within this period will result in the
        impossibility of awarding the prize and the substitution of the
        alternates in order of ranking.
      </span>
    </p>
    <p class="c53">
      <span class="c0"
        >For the alternates, the same timing and notification methods will be
        adopted. The Promoter assumes no responsibility in the event that:
      </span>
    </p>
    <p class="c43">
      <span class="c7">&#9679; </span
      ><span class="c0">the mailbox appears to be full </span>
    </p>
    <p class="c46">
      <span class="c7">&#9679; </span
      ><span class="c0"
        >the email provided during registration was incorrect or incomplete </span
      ><span class="c7">&#9679; </span
      ><span class="c0"
        >no response from the host computer after sending the email notification
        of winning
      </span>
    </p>
    <p class="c56">
      <span class="c7">&#9679; </span
      ><span class="c0">the mailbox is disabled </span>
    </p>
    <p class="c56">
      <span class="c7">&#9679; </span
      ><span class="c0"
        >the email provided during registration is on a blacklist
      </span>
    </p>
    <p class="c30 c45">
      <span class="c0"
        >Following acceptance, winners will receive all necessary information to
        claim their prize. </span
      ><span class="c5">PRIZES </span>
    </p>
    <p class="c24">
      <span class="c5 c3">Drawing:</span><span class="c5">&nbsp;</span>
    </p>
    <p class="c39">
      <span class="c3 c4">&#10146; </span
      ><span class="c2 c3"
        >1st draw: 1 Electrolux SteamBoost: Steam oven with online connectivity
        -</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >EOB9S21WX | Electrolux with an approximate value of &euro; 1,499.99
        including VAT (&euro;</span
      ><span class="c2">&nbsp;</span><span class="c2 c3">1,229.50 + VAT)</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c30 c34">
      <span class="c5 c3">Ranking:</span><span class="c5">&nbsp;</span>
    </p>
    <p class="c67">
      <span class="c4 c3">&#10146; </span
      ><span class="c2 c3"
        >1st to 10th place: 1 Electrolux Vacuum Set: Electrolux Vacuum Sealer
        Vacuum</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >Machine of the approximate value of &euro; 109.90 VAT included (&euro;
        90.08 + VAT)</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c30 c52">
      <span class="c9">PRIZE FUND AND SECURITY DEPOSIT </span>
    </p>
    <p class="c6">
      <span class="c0"
        >The total prize fund amounts to &euro;2,598.99 including VAT
        (&euro;2,130.32 + VAT). In accordance with Article 7 of Presidential
        Decree No. 430 of October 26, 2001, a deposit covering 100% of the total
        prize fund value has been submitted to the Ministry of Enterprises and
        Made in Italy.</span
      >
    </p>
    <p class="c29 c30">
      <span class="c9">NON-PROFIT ORGANIZATION (ONLUS) BENEFICIARY </span>
    </p>
    <p class="c66">
      <span class="c0"
        >Prizes not awarded or unclaimed, except for those declined, will be
        donated as required by law to the following non-profit organization:
        Fondazione Paideia Ente Filantropico, located at Via Moncalvo 1, 10131
        Turin - VAT number 97552690014.
      </span>
    </p>
    <p class="c33"><span class="c9">WAIVER OF RECOURSE </span></p>
    <p class="c27">
      <span class="c0"
        >The Promoter hereby declares the waiver of the right to exercise
        recourse in favor of the winners, as stipulated in Article 30 of
        Presidential Decree No. 600 dated 29/09/1973.
      </span>
    </p>
    <p class="c8 c17">
      <span class="c5 c3">COMPLIANCE AND GUARANTEES</span
      ><span class="c5">&nbsp;</span>
    </p>
    <p class="c61 c65">
      <span class="c2 c3"
        >Participation in the Contest implies full acceptance of this regulation
        and its conditions by</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >the Participants. Any modifications made to the regulation during the
        Duration will be</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >communicated to Participants in advance through the same communication
        channels</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >used for this regulation, while respecting acquired rights.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c42">
      <span class="c2 c3"
        >The prizes cannot be converted into cash or gold coins. In the event of
        unforeseen</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >circumstances, the Promoter reserves the right to potentially replace
        the planned prizes</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >with different goods, possibly of the same nature and of equal or
        greater value.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c40">
      <span class="c2 c3"
        >The prizes will be made available within 180 days from the date of
        formalization and, in</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3">time for Participants to benefit from them.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c13">
      <span class="c2 c3"
        >The Promoter does not provide Internet services and does not benefit
        from Internet</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >connection. Participation through the App will be free of charge
        without additional costs</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >other than those associated with the participant&#39;s Internet
        connection agreed upon with</span
      ><span class="c2">&nbsp;</span><span class="c2 c3">their provider.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c70">
      <span class="c2 c3"
        >The Promoter assumes no responsibility for events beyond its control,
        such as but not</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >limited to: access, impediment, malfunction, or difficulties regarding
        technical tools,</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >computer, cables, electronics, software and hardware, broadcasting and
        connection, and</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >telephone line that may prevent the user from participating in the
        current initiative.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c20">
      <span class="c2 c3"
        >The Promoter assumes no responsibility for any contamination by
        computer viruses or</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >third-party intrusions into Participants&#39; devices.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c61 c62">
      <span class="c2 c3"
        >Participants found to have used tools considered suspicious,
        fraudulent, or in violation of</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >the Contest&#39;s rules, at the discretion of the Promoter or third
        parties entrusted by the</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >Promoter to manage the Contest, will be disqualified. Any points
        obtained through such</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >means will not be considered valid. Any attempt at fraudulent use or
        external</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >manipulation of the App may be considered a criminal offense.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c30 c69">
      <span class="c2 c3"
        >The Promoter reserves the right to carry out all necessary checks
        regarding correct</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >participation in the Contest. In the event of established
        irregularities in participation, the</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3">prize, if requested, cannot be awarded.</span>
    </p>
    <p class="c61 c71">
      <span class="c2 c3"
        >Furthermore, if anomalies are detected regarding the points earned, the
        Promoter will</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >conduct all necessary verifications, potentially correcting the anomaly
        before conducting</span
      ><span class="c2">&nbsp;</span><span class="c2 c3">the draw.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c68">
      <span class="c2 c3"
        >The Promoter cannot in any case be held responsible for any issues
        arising during the use</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >of the prizes, nor can it be held responsible for the improper use of
        the prizes by the</span
      ><span class="c2">&nbsp;</span><span class="c2 c3">winners.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c10">
      <span class="c2 c3"
        >Additionally, by participating in the Contest, Participants indemnify
        and declare the</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >Promoter not liable for any issues, damages, and/or injuries to
        property or persons arising</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3">during the enjoyment of the prizes.</span
      ><span class="c2">&nbsp;</span>
    </p>
    <p class="c37">
      <span class="c5 c3"
        >DATA PROCESSING INFORMATION PURSUANT TO THE REGULATION EU No.</span
      ><span class="c5">&nbsp;</span
      ><span class="c5 c3"
        >2016/679 AND THE CODE ON PERSONAL DATA PROTECTION</span
      ><span class="c5">&nbsp;</span>
    </p>
    <p class="c31">
      <span class="c2 c3"
        >The Promoter, as an independent data controller, commits to complying
        with the</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >regulations and obligations established by current legislation
        concerning the protection</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >of personal data (EU Regulation 2016/679 and the Italian Legislative
        Decree 196/2003, as</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >well as subsequent amendments and integrations) regarding the
        information and data</span
      ><span class="c2">&nbsp;</span
      ><span class="c2 c3"
        >acquired throughout the duration of the Contest.</span
      >
    </p>
  </body>
</html>
`
