import { CSSProperties, ReactNode } from "react"
import { motion, useReducedMotion } from "framer-motion"

const FadeFromTop = ({
  children,
  style,
  delay = 0,
  className,
  y = -60,
}: {
  children?: ReactNode
  style?: CSSProperties
  delay?: number
  className?: string
  y?: number
}) => {
  const shouldReduceMotion = useReducedMotion()

  return (
    <motion.div
      className={className}
      style={style}
      initial={{ opacity: 0, y: y }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        delay: shouldReduceMotion ? 0 : delay,
        y: {
          type: "spring",
          duration: 0.4,
        },
      }}
    >
      {children}
    </motion.div>
  )
}

export default FadeFromTop
