import { Stack } from "@mui/material"
import { Dispatch, SetStateAction, useContext } from "react"
import { MainContext } from "../../controllers/main"
import OnboardingMobile from "./OnboardingMobile"

const OnboardingDesktop = ({
  footprintAlertOpen,
  setFootprintAlertOpen,
}: {
  footprintAlertOpen: boolean
  setFootprintAlertOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      style={{
        width: "100%",
        height: windowHeight,
        backgroundColor: "#252525",
      }}
    >
      <OnboardingMobile
        footprintAlertOpen={footprintAlertOpen}
        setFootprintAlertOpen={setFootprintAlertOpen}
        height="100%"
        continueButtonWidth={330}
      />
    </Stack>
  )
}

export default OnboardingDesktop
