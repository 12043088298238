import { ButtonBase, LinearProgress, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import settingsIcon from "../../assets/icons/settings.svg"
import { useLocation, useNavigate } from "react-router-dom"
import Text from "../global/common/Text"
import Appear from "../animations/Appear"
import AppearFromSide from "../animations/AppearFromSide"
import Tabs from "../global/common/Tabs"
import { useContext, useState } from "react"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import { UsersContext } from "../../controllers/users"
import PointsHistoryListItem from "./common/PointsHistoryListItem"
import LeaderboardTop3 from "./common/LeaderboardTop3"
import LeaderboardListItem from "./common/LeaderboardListItem"
import { MissionsContext } from "../../controllers/missions"
import { MainContext } from "../../controllers/main"

const PerformanceMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useContext(MainContext)
  const {
    leaderboard,
    leaderboardUpdating,
    leaderboardNextToken,
    getLeaderboard,
  } = useContext(UsersContext)
  const {
    missionsHistory,
    missionsHistoryUpdating,
    missionsHistoryNextToken,
    getMissionsHistory,
    completedMissionsCount,
  } = useContext(MissionsContext)

  // tabs
  const tabs = [t("missions"), t("leaderboard")]
  const [selectedTab, setSelectedTab] = useState<number>(
    location.state && location.state.viewLeaderboard ? 1 : 0
  )

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/", { state: { fromPerformance: true } })
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("performance")}
        </Title>
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/performance/settings")
          }}
          aria-label={t("settings")}
        >
          <img src={settingsIcon} style={{ width: 24, height: 24 }} alt="" />
        </ButtonBase>
      </Stack>
      {/* user info */}
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          height: 77,
          marginTop: 20,
          paddingInline: 16,
          gap: 12,
        }}
      >
        <Appear fade>
          <ButtonBase
            aria-label={t("edit_avatar")}
            style={{ borderRadius: "100%" }}
            onClick={() => {
              navigate("/performance/settings/avataredit")
            }}
          >
            <div
              style={{
                width: 77,
                height: 77,
                borderRadius: "100%",
                backgroundImage: `url(${
                  user!.profileImage
                }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                backgroundSize: "105%, 100%",
                backgroundPosition: "center",
              }}
            />
          </ButtonBase>
        </Appear>
        <Appear style={{ width: "72%" }} fade>
          <Stack style={{ gap: 10 }}>
            <Title
              fontSize={18}
              lineHeight="22px"
              style={{
                width: "100%",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordWrap: "break-word",
              }}
            >
              {user?.first_name} {user?.last_name}
            </Title>
            <div
              style={{
                width: "fit-content",
                height: 32,
                borderRadius: 4,
                paddingInline: 6,
                border: "1px solid " + colors.primary,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text fontSize={14} fontWeight={700} color={colors.primary}>
                {user?.points} {t("points")}
              </Text>
            </div>
          </Stack>
        </Appear>
      </Stack>
      {/* tabs */}
      <AppearFromSide
        delay={0.1}
        fade
        style={{
          marginTop: 25,
        }}
      >
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabContentId="performance-tabs-content"
        />
      </AppearFromSide>
      {/* tabs content */}
      <AppearFromSide delay={0.15} fade style={{ marginTop: 25 }}>
        <motion.div
          animate={selectedTab.toString()}
          variants={tabs.reduce(
            (obj, current, index) => ({
              ...obj,
              [index]: {
                x: `calc(-100vw * ${index})`,
              },
            }),
            {}
          )}
          transition={{ x: { type: "spring", damping: 18, stiffness: 160 } }}
          initial={false}
        >
          <Stack
            id="performance-tabs-content"
            direction="row"
            style={{
              width: "300vw",
              minWidth: "300vw",
              marginBottom: 26,
            }}
            role="tabpanel"
          >
            {/* completed missions */}
            <Stack
              style={{
                width: "100vw",
                minWidth: "100vw",
                paddingInline: 16,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", height: 30, position: "relative" }}
              >
                <Title fontSize={18} lineHeight="24px" component="h2">
                  {t("completed_missions")}
                </Title>
              </Stack>
              {!completedMissionsCount ? (
                <Text fontSize={14} fontWeight={400} style={{ marginTop: 18 }}>
                  {t("points_history_empty_state")}.
                </Text>
              ) : (
                <Stack
                  id="missions-history-container"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: 396.5,
                    borderRadius: 10,
                    boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                    marginTop: 18,
                    gap: 18,
                    paddingTop: 18,
                    paddingBottom: 18,
                    overflowY: "scroll",
                    position: "relative",
                  }}
                  onScroll={() => {
                    const container = document.getElementById(
                      "missions-history-container"
                    )

                    if (
                      container!.scrollTop + 224 + 400 >=
                        container!.scrollHeight &&
                      missionsHistoryNextToken &&
                      !missionsHistoryUpdating
                    ) {
                      getMissionsHistory(false)
                    }
                  }}
                >
                  {missionsHistory.map((item, index) => (
                    <Stack key={index} style={{ gap: 18 }}>
                      {index > 0 && (
                        <div
                          style={{
                            width: "100%",
                            height: 1.5,
                            backgroundColor: colors.backgroundLight,
                          }}
                        />
                      )}
                      <PointsHistoryListItem
                        title={item.title}
                        time={item.updatedAt!}
                        points={item.points}
                        type={item.missionType}
                      />
                    </Stack>
                  ))}
                  {missionsHistoryUpdating && (
                    <LinearProgress
                      style={{
                        width: "calc(100% - 32px)",
                        position: "fixed",
                        bottom: 0,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    />
                  )}
                </Stack>
              )}
            </Stack>
            {/* leaderboard */}
            <Stack
              style={{ width: "100vw", minWidth: "100vw", paddingInline: 16 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", height: 30, position: "relative" }}
              >
                <Title fontSize={18} lineHeight="24px" component="h2">
                  {t("leaderboard")}
                </Title>
              </Stack>
              <LeaderboardTop3 style={{ marginTop: 18 }} />
              {leaderboard.length > 3 ? (
                <Stack
                  id="leaderboard-container"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: 224,
                    borderRadius: 10,
                    marginTop: 28,
                    boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                    overflowY: "scroll",
                    position: "relative",
                  }}
                  onScroll={() => {
                    const container = document.getElementById(
                      "leaderboard-container"
                    )

                    if (
                      container!.scrollTop + 224 + 200 >=
                        container!.scrollHeight &&
                      leaderboardNextToken &&
                      !leaderboardUpdating
                    ) {
                      getLeaderboard(false)
                    }
                  }}
                >
                  {leaderboard.slice(3).map((item, index) => (
                    <Stack key={index} style={{ height: 56, minHeight: 56 }}>
                      {index > 0 &&
                      item.sub !== user!.sub &&
                      leaderboard[index + 2].sub !== user!.sub ? (
                        <div
                          style={{
                            width: "100%",
                            height: 1.5,
                            backgroundColor: colors.backgroundLight,
                          }}
                        />
                      ) : null}
                      <LeaderboardListItem
                        place={index + 4}
                        name={`${item.first_name} ${item.last_name}`}
                        points={item.points}
                        isUser={item.sub === user!.sub}
                        profileImage={item.profileImage}
                      />
                    </Stack>
                  ))}
                  {leaderboardUpdating && (
                    <LinearProgress
                      style={{
                        width: "calc(100% - 32px)",
                        position: "fixed",
                        bottom: 0,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    />
                  )}
                </Stack>
              ) : (
                <Text fontSize={14} fontWeight={400} style={{ marginTop: 18 }}>
                  {t("leaderboard_empty_state")}.
                </Text>
              )}
            </Stack>
          </Stack>
        </motion.div>
      </AppearFromSide>
    </Stack>
  )
}

export default PerformanceMobile
