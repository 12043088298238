import { Stack } from "@mui/material"
import { BottomSheet } from "react-spring-bottom-sheet"
import "react-spring-bottom-sheet/dist/style.css"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import propicsRowImage from "../../../assets/images/propics-row.png"
import challengeTrophyImage from "../../../assets/images/challenge-trophy.png"
import { useContext, useEffect, useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import lightningIcon from "../../../assets/icons/lightning.svg"
import { useTranslation } from "react-i18next"
import { ChallengeContext } from "../../../controllers/challenge"
import { numberWithSeparators } from "../../../services/utils/utils"
import ChallengeProgressBar from "../../challenge/common/ChallengeProgressBar"
import { MissionsContext } from "../../../controllers/missions"
import "../../../styles/challengeBottomSheetMobile.css"
import { MainContext } from "../../../controllers/main"
import Confetti from "../../global/common/Confetti"

const ChallengeBottomSheetMobile = ({ open }: { open: boolean }) => {
  const { t } = useTranslation()
  const { viewTutorial, currentTutorialPage } = useContext(MainContext)
  const { challenge } = useContext(ChallengeContext)
  const { completedMissionsCount } = useContext(MissionsContext)

  // bottom sheet ref to know its current height
  const sheetRef = useRef<any>(null)

  // bottom sheet current height
  const [bottomSheetHeight, setBottomSheetHeight] = useState<number>(0)

  // bottom sheet snap points
  const firstSnapPoint = 88
  const secondSnapPoint = 164

  // snap bottom sheet to secondo snap point if user is visualizing tutorial
  useEffect(() => {
    if (sheetRef.current) {
      if (viewTutorial && currentTutorialPage === 1) {
        sheetRef.current.snapTo(secondSnapPoint)
      } else {
        sheetRef.current.snapTo(firstSnapPoint)
      }
    }
  }, [viewTutorial, currentTutorialPage])

  // show confetti fot tutorial animation
  const [showConfetti, setShowConfetti] = useState<boolean>(false)
  const showConfettiTimeoutId = useRef<any>(null)

  useEffect(() => {
    if (viewTutorial && currentTutorialPage === 1) {
      showConfettiTimeoutId.current = setTimeout(() => {
        setShowConfetti(true)
      }, 1000)
    } else {
      if (showConfettiTimeoutId.current) {
        clearTimeout(showConfettiTimeoutId.current)
      }
      setShowConfetti(false)
    }
  }, [viewTutorial, currentTutorialPage])

  return (
    <BottomSheet
      id="challenge-bottom-sheet"
      open={open}
      ref={sheetRef}
      blocking={false}
      snapPoints={() => [firstSnapPoint, secondSnapPoint]}
      expandOnContentDrag
      onSpringStart={() => {
        requestAnimationFrame(() => {
          if (sheetRef.current) {
            setBottomSheetHeight(sheetRef.current.height)
          }
        })
      }}
      scrollLocking={false}
      tabIndex={0}
      aria-label={t("challenge_sheet")}
      onFocusCapture={() => {
        sheetRef.current.snapTo(secondSnapPoint)
      }}
      onBlurCapture={() => {
        sheetRef.current.snapTo(firstSnapPoint)
      }}
      className="bottom-sheet"
      skipInitialTransition={viewTutorial}
    >
      <Stack
        style={{
          padding: 16,
          paddingTop: 12,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {showConfetti ? <Confetti zIndex={150} particleCount={60} /> : null}
        <Stack style={{ position: "relative" }}>
          <Title
            fontSize={14}
            lineHeight="16px"
            color={colors.primary}
            style={{ marginLeft: 16 }}
          >
            {t("collective_challenge").toUpperCase()}
          </Title>
          <AnimatePresence>
            {bottomSheetHeight === 0 || bottomSheetHeight === firstSnapPoint ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ position: "absolute", top: 22, left: 16 }}
              >
                <Text fontSize={14} fontWeight={500} lineHeight="16px">
                  {t("challenge_contribution_percentage", {
                    percentage: (challenge!.currentAmount
                      ? Math.round(
                          ((completedMissionsCount / challenge!.currentAmount) *
                            100 +
                            Number.EPSILON) *
                            100
                        ) / 100
                      : 0
                    )
                      .toString()
                      .replace(".", ","),
                  })}
                </Text>
              </motion.div>
            ) : null}
          </AnimatePresence>
          <AnimatePresence>
            {bottomSheetHeight === secondSnapPoint ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ position: "absolute", top: 22, left: 16 }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{ height: 16, gap: 7 }}
                >
                  <img src={lightningIcon} style={{ height: 14 }} alt="" />
                  <Text
                    fontSize={14}
                    fontWeight={700}
                    lineHeight="16px"
                    color={colors.primary}
                  >
                    {numberWithSeparators(challenge!.targetAmount)}{" "}
                    {t("missions").toLowerCase()}
                  </Text>
                </Stack>
              </motion.div>
            ) : null}
          </AnimatePresence>
          <img
            src={challengeTrophyImage}
            style={{
              width: 38,
              height: 38,
              position: "absolute",
              top: 0,
              right: 16,
            }}
            alt=""
          />
        </Stack>
        <ChallengeProgressBar
          percentage={
            viewTutorial && currentTutorialPage === 1
              ? 100
              : Math.round(
                  (challenge!.currentAmount / challenge!.targetAmount) * 100
                )
          }
          style={{ marginTop: 40 }}
          percentageTextColor={
            viewTutorial && currentTutorialPage === 1
              ? colors.background
              : colors.textWhite
          }
        />
        <Stack
          direction="row"
          alignItems="center"
          style={{ width: "100%", height: 24, marginTop: 16, gap: 6 }}
        >
          <img src={propicsRowImage} style={{ width: 74, height: 24 }} alt="" />
          <Text fontSize={14} fontWeight={500}>
            {numberWithSeparators(challenge!.userCount)} {t("partecipants")}
          </Text>
        </Stack>
      </Stack>
    </BottomSheet>
  )
}

export default ChallengeBottomSheetMobile
