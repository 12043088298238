import { Textfit } from "react-textfit"
import FullTextSlide from "../../../../models/slides/fullTextSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"

const FullTextLayout = ({ slide }: { slide: FullTextSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 30,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Box slide={slide} />
      </div>
      <Decor slide={slide} />
    </div>
  )
}

const Box = ({ slide }: { slide: FullTextSlide }) => {
  return (
    <div
      style={{
        backgroundColor: slide.box ? slide.box.boxBackColor : undefined,
        width: `calc(${slideWidth}px / ${numberOfColumns} * 13)`,
        bottom: `calc(${slideHeight}px / ${numberOfRows} * 7)`,
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 10)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
      }}
    >
      <Textfit
        style={{
          color: slide.textColor,
          lineHeight: 1.2,
          fontWeight: 400,
          marginTop: slide.decor
            ? `calc(${slideHeight}px / ${numberOfRows} * 4)`
            : `calc(${slideHeight}px / ${numberOfRows} * 1)`,
          marginLeft: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          marginRight: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          height: slide.decor
            ? `calc(${slideHeight}px / ${numberOfRows} * 21)`
            : `calc(${slideHeight}px / ${numberOfRows} * 22)`,
        }}
        min={12}
        max={60}
        mode="multi"
      >
        <div dangerouslySetInnerHTML={{ __html: slide.text }} />
      </Textfit>
    </div>
  )
}

const Decor = ({ slide }: { slide: FullTextSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        position: "absolute",
        top: 0,
        paddingBottom: 345,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          position: "absolute",
          right: `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
          left: `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
          height: `calc(${slideHeight}px / ${numberOfRows} * 7)`,
          backgroundImage: `url(${slide.decor})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </div>
  )
}

export default FullTextLayout
