import { ButtonBase, Stack } from "@mui/material"
import { useContext, useMemo } from "react"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"
import Action from "../../../models/action"
import { ActionsContext } from "../../../controllers/actions"
import { colors } from "../../../services/config/colors"
import { registerEvent, scrollWindowToTop } from "../../../services/utils/utils"
import Text from "../../global/common/Text"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../../controllers/main"
import { useTranslation } from "react-i18next"
import {
  accessibilityHeightThreshold,
  maximumActionsPerTime,
} from "../../../services/config/constants"

const ActionCardHorizontal = ({
  action,
  width = "87.2vw",
  showCategoryAndCo2 = false,
}: {
  action: Action
  width?: string
  showCategoryAndCo2?: boolean
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { isMobile, windowHeight, updatingMissions } = useContext(MainContext)
  const {
    selectedActions,
    setSelectedActions,
    currentDetailedHistory,
    currentDate,
    setActionsPerTimeLimitAlertOpen,
  } = useContext(ActionsContext)

  // plus button selected or not
  const selected = useMemo(() => {
    if (selectedActions.some((item) => item.id === action.id)) {
      return true
    }

    return false
  }, [selectedActions]) // eslint-disable-line react-hooks/exhaustive-deps

  // if action has been logged in current date or not
  const actionLogged = useMemo(() => {
    if (
      currentDetailedHistory.find(
        (item) =>
          new Date(item.createdAt).toLocaleDateString() ===
          currentDate.toLocaleDateString()
      )
    ) {
      return true
    }

    return false
  }, [currentDetailedHistory, currentDate]) // eslint-disable-line react-hooks/exhaustive-deps

  // if it is current date or not
  const isCurrentDate = useMemo(() => {
    if (currentDate.toLocaleDateString() === new Date().toLocaleDateString()) {
      return true
    }

    return false
  }, [currentDate])

  // number of times that action has been logged
  const timesLogged = useMemo(() => {
    return currentDetailedHistory.filter(
      (item) =>
        new Date(item.createdAt).toLocaleDateString() ===
          currentDate.toLocaleDateString() && item.action.id === action.id
    ).length
  }, [currentDetailedHistory, currentDate]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: width,
        minWidth: width,
        minHeight:
          windowHeight <= accessibilityHeightThreshold && showCategoryAndCo2
            ? 106
            : !isMobile && showCategoryAndCo2
            ? 112
            : 88,
        height:
          windowHeight <= accessibilityHeightThreshold && showCategoryAndCo2
            ? 106
            : !isMobile && showCategoryAndCo2
            ? 112
            : 88,
        backgroundColor: colors.backgroundWhite,
        borderRadius: 10,
        boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
        padding: 12,
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => {
        if (!updatingMissions) {
          registerEvent("open_view", {
            view: "action",
            data: action.id,
          })
          scrollWindowToTop()
          navigate(`${window.location.pathname}/${action.id}`)
        }
      }}
    >
      <div
        style={{
          minWidth: !isMobile && showCategoryAndCo2 ? 88 : 64,
          width: !isMobile && showCategoryAndCo2 ? 88 : 64,
          height: !isMobile && showCategoryAndCo2 ? 88 : 64,
          borderRadius: 10,
          backgroundColor: action.category.backColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={action.image} style={{ width: 52, height: 52 }} alt="" />
      </div>
      <ButtonBase
        disabled={updatingMissions}
        disableRipple
        style={{
          width: "100%",
          height: "100%",
          marginLeft: 12,
          justifyContent: "flex-start",
        }}
        onClick={(e) => {
          e.stopPropagation()
          registerEvent("open_view", {
            view: "action",
            data: action.id,
          })
          scrollWindowToTop()
          navigate(`${window.location.pathname}/${action.id}`)
        }}
        aria-label={`${action.document.title}, ${t("open_action_details")}`}
      >
        <Stack
          justifyContent={showCategoryAndCo2 ? "flex-start" : "center"}
          style={{
            width:
              !isMobile && showCategoryAndCo2
                ? "calc(100% - 66px)"
                : "calc(100% - 42px)",
            height: "100%",
            gap: 6,
          }}
        >
          {showCategoryAndCo2 && (
            <Stack direction="row" style={{ height: 20, gap: 4 }}>
              {showCategoryAndCo2 && (
                <div
                  style={{
                    width: "min-content",
                    height: 20,
                    minHeight: 20,
                    borderRadius: 4,
                    paddingInline: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: action.category.backTagColor,
                  }}
                >
                  <Text
                    fontSize={isMobile ? 10 : 14}
                    lineHeight="16px"
                    fontWeight={700}
                    color={action.category.foreColor}
                  >
                    {action.category.name}
                  </Text>
                </div>
              )}
              {action.metrics.co2Saving > 0 ? (
                <div
                  style={{
                    width: "min-content",
                    height: 20,
                    minHeight: 20,
                    borderRadius: 4,
                    paddingInline: 6,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: colors.background,
                  }}
                >
                  <Text
                    fontSize={isMobile ? 10 : 14}
                    lineHeight="16px"
                    fontWeight={700}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    -{action.metrics.co2Saving} {t("kg_co2")}
                  </Text>
                </div>
              ) : null}
            </Stack>
          )}
          <div
            style={{
              width: "100%",
              height:
                windowHeight <= accessibilityHeightThreshold
                  ? 60
                  : isMobile
                  ? 40
                  : 63,
              minHeight:
                windowHeight <= accessibilityHeightThreshold
                  ? 60
                  : isMobile
                  ? 40
                  : 63,
              display: "flex",
              alignItems: !showCategoryAndCo2 ? "center" : "flex-start",
            }}
          >
            <Text
              id={action.id}
              textAlign="left"
              fontSize={isMobile ? 16 : 18}
              fontWeight={500}
              lineHeight={isMobile ? "20px" : "21px"}
              style={{
                maxWidth: "100%",
                minWidth: "100%",
                maxHeight:
                  windowHeight <= accessibilityHeightThreshold
                    ? 60
                    : isMobile
                    ? 40
                    : 63,
                display: "-webkit-box",
                WebkitLineClamp:
                  windowHeight <= accessibilityHeightThreshold
                    ? 3
                    : isMobile
                    ? 2
                    : 3,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {action.document.title}
            </Text>
          </div>
        </Stack>
      </ButtonBase>
      <Stack
        direction="column"
        style={{
          position: "absolute",
          right: 7,
          height: "100%",
          minWidth: 42,
        }}
        justifyContent="center"
        alignItems="center"
      >
        <ButtonBase
          disabled={updatingMissions}
          disableRipple
          style={{
            width: 32,
            height: 32,
            borderRadius: 16,
            backgroundColor: colors.backgroundWhite,
            boxSizing: "border-box",
            border: "1.5px solid " + colors.primary,
            transition: "100ms",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            marginTop: action.timesPerDay > 1 ? 8 : 0,
          }}
          onClick={(e) => {
            e.stopPropagation()

            if (
              (!actionLogged || timesLogged < action.timesPerDay) &&
              isCurrentDate
            ) {
              if (!selected && selectedActions.length < maximumActionsPerTime) {
                selectedActions.push(action)
              } else if (selected) {
                let indexToRemove = selectedActions.findIndex(
                  (item) => item.id === action.id
                )
                selectedActions.splice(indexToRemove, 1)
              } else {
                setActionsPerTimeLimitAlertOpen(true)
              }

              setSelectedActions([...selectedActions])
            }
          }}
          aria-labelledby={action.id}
          role="checkbox"
          aria-checked={
            selected || timesLogged === action.timesPerDay ? "true" : "false"
          }
          aria-disabled={timesLogged === action.timesPerDay ? "true" : "false"}
        >
          <div
            style={{
              width: selected || timesLogged === action.timesPerDay ? 32 : 0,
              height: selected || timesLogged === action.timesPerDay ? 32 : 0,
              borderRadius: 16,
              backgroundColor: colors.primary,
              position: "absolute",
              transition: "120ms",
            }}
          />
          <img
            src={checkWhiteIcon}
            style={{
              width: 14,
              height: 14,
              opacity: selected || timesLogged === action.timesPerDay ? 1 : 0,
              transition: "100ms",
              position: "relative",
            }}
            alt=""
          />
        </ButtonBase>
        {action.timesPerDay > 1 ? (
          <Text
            color={colors.primary}
            fontWeight={600}
            fontSize={10}
            lineHeight="10px"
            style={{ marginTop: 6 }}
            textAlign="center"
          >
            {timesLogged}/{action.timesPerDay > 999 ? 999 : action.timesPerDay}
          </Text>
        ) : null}
      </Stack>
    </Stack>
  )
}

export default ActionCardHorizontal
