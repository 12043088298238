import { useContext } from "react"
import ConfettiExplosion from "react-confetti-explosion"
import { MainContext } from "../../../controllers/main"

const Confetti = ({
  particleCount = 120,
  zIndex = 100,
  width,
  top = 0,
}: {
  particleCount?: number
  zIndex?: number
  width?: number
  top?: number
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        position: "absolute",
        top: top,
      }}
    >
      <ConfettiExplosion
        force={0.4}
        duration={3000}
        particleCount={particleCount}
        width={
          width ? width : isMobile ? window.innerWidth + 300 : window.innerWidth
        }
        particleSize={7}
        zIndex={zIndex}
      />
    </div>
  )
}

export default Confetti
