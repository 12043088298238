import { CSSProperties, ReactNode, useContext } from "react"
import { MainContext } from "../../../controllers/main"

const Center = ({
  children,
  style,
}: {
  children: ReactNode
  style?: CSSProperties
}) => {
  const { windowHeight } = useContext(MainContext)

  return (
    <div
      style={{
        width: "100%",
        height: windowHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default Center
