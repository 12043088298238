import { Textfit } from "react-textfit"
import QuoteSlide from "../../../../models/slides/quoteSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"

const QuoteLayout = ({ slide }: { slide: QuoteSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 10,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Box slide={slide} />
        <Decor slide={slide} />
      </div>
      <Image slide={slide} />
    </div>
  )
}

const Box = ({ slide }: { slide: QuoteSlide }) => {
  return (
    <div
      style={{
        backgroundColor:
          slide.box && slide.box.boxBackColor
            ? slide.box.boxBackColor
            : undefined,
        position: "absolute",
        bottom: `calc(${slideHeight}px / ${numberOfRows} * 5)`,
        top: `calc(${slideHeight}px / ${numberOfRows} * 11)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
      }}
    >
      <Textfit
        style={{
          color: slide.textColor,
          lineHeight: 1.2,
          fontWeight: 600,
          paddingLeft: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          paddingRight: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          marginTop: slide.decor
            ? `calc(${slideHeight}px / ${numberOfRows} * 5)`
            : `calc(${slideHeight}px / ${numberOfRows} * 3)`,
          height: slide.decor
            ? `calc(${slideHeight}px / ${numberOfRows} * 14)`
            : `calc(${slideHeight}px / ${numberOfRows} * 16)`,
        }}
        min={26}
        max={60}
        mode="multi"
      >
        <div dangerouslySetInnerHTML={{ __html: slide.text }} />
      </Textfit>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          transition: "200ms",
          color: slide.textColor,
          position: "absolute",
          top: `calc(${slideHeight}px / ${numberOfRows} * 21)`,
          right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          width: `calc(${slideWidth}px / ${numberOfColumns} * 7)`,
        }}
      >
        <div
          style={{
            fontSize: 18,
            lineHeight: "17px",
            fontWeight: 600,
            textAlign: "right",
            maxWidth: `calc(${slideWidth}px / ${numberOfColumns} * 7)`,
            paddingLeft: 12,
          }}
        >
          {slide.author.toUpperCase()}
        </div>
        <div
          style={{
            fontSize: 16,
            lineHeight: "17px",
            fontWeight: 300,
            textAlign: "right",
            maxWidth: `calc(${slideWidth}px / ${numberOfColumns} * 7)`,
            paddingLeft: 12,
          }}
        >
          {slide.authorTitle}
        </div>
      </div>
    </div>
  )
}

const Image = ({ slide }: { slide: QuoteSlide }) => {
  return (
    <div
      style={{
        transition: "200ms",
        position: "absolute",
        top: `calc(100% / 2 + 175px)`,
        bottom: `calc(${slideHeight}px / ${numberOfRows} * 0)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
        width: `calc(100vw / ${numberOfColumns} * 6)`,
        backgroundImage: slide.image ? `url(${slide.image.url})` : undefined,
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    />
  )
}

const Decor = ({ slide }: { slide: QuoteSlide }) => {
  return (
    <div
      style={{
        transition: "200ms",
        position: "absolute",
        top: `calc(${slideHeight}px / ${numberOfRows} * 9)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 2)`,
        height: `calc(${slideHeight}px / ${numberOfRows} * 5)`,
        width: `calc(${slideHeight}px / ${numberOfRows} * 5)`,
        backgroundImage: `url(${slide.decor})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
  )
}

export default QuoteLayout
