import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import chevronRightIcon from "../../../assets/icons/chevron-right.svg"

const MenuButtonMobile = ({
  title,
  withBorder = true,
  withChevron = true,
  disabled = false,
  onClick,
  hidden = "false",
}: {
  title: string
  withBorder?: boolean
  withChevron?: boolean
  disabled?: boolean
  onClick?: () => void
  hidden?: "true" | "false"
}) => {
  return (
    <ButtonBase
      disabled={disabled}
      style={{
        width: "100%",
        height: 70,
        backgroundColor: colors.backgroundWhite,
        borderRadius: 12,
        paddingInline: 16,
        transition: "150ms",
        opacity: disabled ? 0.4 : 1,
        position: "relative",
      }}
      onClick={onClick}
      aria-label={title}
      aria-hidden={hidden}
      tabIndex={hidden === "false" ? 0 : -1}
    >
      <Stack direction="row" style={{ width: "100%", position: "relative" }}>
        <Title fontSize={18}>{title}</Title>
        {withChevron && (
          <img
            src={chevronRightIcon}
            style={{ width: 24, position: "absolute", right: 0 }}
            alt=""
          />
        )}
      </Stack>
      {withBorder && (
        <div
          style={{
            width: "100%",
            height: 2,
            backgroundColor: colors.primary,
            position: "absolute",
            bottom: 0,
            borderRadius: 10,
          }}
        />
      )}
    </ButtonBase>
  )
}

export default MenuButtonMobile
