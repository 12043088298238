import { CSSProperties, ReactNode } from "react"
import { motion, useReducedMotion } from "framer-motion"

const AppearFromSide = ({
  id,
  children,
  style,
  x = -100,
  fade = false,
  delay = 0,
}: {
  id?: string
  children?: ReactNode
  style?: CSSProperties
  x?: number | string
  fade?: boolean
  delay?: number
}) => {
  const shouldReduceMotion = useReducedMotion()

  return (
    <motion.div
      id={id}
      style={style}
      initial={{ opacity: fade ? 0 : 1, x: x }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        delay: shouldReduceMotion ? 0 : delay,
        x: {
          type: "spring",
          duration: 0.4,
        },
      }}
    >
      {children}
    </motion.div>
  )
}

export default AppearFromSide
