import { useEffect } from "react"
import MissionsDesktop from "../../components/missions/MissionsDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Missions = () => {
  // register google analytics event
  useEffect(() => {
    registerPageEvent("missions")
  }, [])

  return <MissionsDesktop />
}

export default Missions
