export const colors = {
  primary: "#3B4536", // electrolux color
  secondary: "#D02963", // same as missions => act
  tertiary: "#BD8D24",
  disabled: "#CAD2C6", // electrolux color + 50% white
  background: "#D4D8D2", // electrolux background
  backgroundLight: "#F2F3F4", // Japanese Indigo + 95% white
  backgroundGray: "#EDE9E8", // 100% white
  backgroundWhite: "#FFFFFF", // 100% white
  stroke: "#7D8D96", // Japanese Indigo + 40% white
  text: "#041E50", // Japanese Indigo
  textSecondary: "#7A8A9C", // Japanese Indigo + 20% white
  textWhite: "#FFFFFF", // 100% white
  metrics: {
    co2: "#759357",
    water: "#5D82CA",
    energy: "#CE9256",
  },
  missions: {
    act: "#14856F",
    learn: "#D02963",
    measure: "#54428E",
  },
  success: "#008000",
  error: "#D02929",
}
