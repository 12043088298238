import { useNavigate } from "react-router-dom"
import howToCalculateImage from "../../assets/images/how-to-calculate-metrics.png"
import { colors } from "../../services/config/colors"
import Text from "../global/common/Text"
import BackButtonMobile from "../global/mobile/BackButtonMobile"
import AppearFromSide from "../animations/AppearFromSide"
import Action from "../../models/action"
import { useTranslation } from "react-i18next"

const ActionCalculationsMobile = ({ action }: { action: Action }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: "#E6F0FC",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: 30,
          paddingTop: 80,
        }}
      >
        <img
          src={howToCalculateImage}
          style={{ width: 80, height: 80 }}
          alt=""
        />
        <AppearFromSide style={{ marginTop: 16, alignSelf: "flex-start" }} fade>
          <Text
            fontSize={24}
            lineHeight="28px"
            fontWeight={600}
            paddingLeft={16}
            paddingRight={16}
            textAlign="left"
            component="h1"
          >
            {t("how_is_calculated")}
          </Text>
        </AppearFromSide>
        <AppearFromSide
          style={{ width: "100%", marginTop: 16 }}
          delay={0.05}
          fade
        >
          <div
            className="html"
            dangerouslySetInnerHTML={{
              __html: action!.document.assumptions!.replace(/<\/?a[^>]*>/g, ""),
            }}
            style={{
              fontSize: 16,
              fontWeight: 300,
              lineHeight: "25px",
              color: colors.text,
              overflow: "hidden",
              paddingLeft: 16,
              paddingRight: 16,
            }}
          />
        </AppearFromSide>
      </div>
      <BackButtonMobile
        style={{ position: "absolute", top: 22, left: 16 }}
        onClick={() => {
          navigate(
            window.location.pathname.slice(
              0,
              window.location.pathname.indexOf("/calculations")
            )
          )
        }}
      />
    </div>
  )
}

export default ActionCalculationsMobile
