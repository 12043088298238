import { useContext, useEffect } from "react"
import PerformanceMobile from "../../components/performance/PerformanceMobile"
import { MainContext } from "../../controllers/main"
import PerformanceDesktop from "../../components/performance/PerformanceDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Performance = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("performance")
  }, [])

  return isMobile ? <PerformanceMobile /> : <PerformanceDesktop />
}

export default Performance
