import { Textfit } from "react-textfit"
import PortraitSlide from "../../../../models/slides/portraitSlide"
import {
  numberOfColumns,
  numberOfRows,
  slideHeight,
  slideWidth,
} from "../../../../services/config/constants"

const PortraitLayout = ({ slide }: { slide: PortraitSlide }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundImage: `url(${slide.background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 30,
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: slideWidth,
          maxWidth: slideWidth,
          height: slideHeight,
          maxHeight: slideHeight,
          position: "relative",
        }}
      >
        <Box slide={slide} />
        {slide.image && slide.image.type !== "XXL" ? (
          <Image slide={slide} />
        ) : null}
      </div>
      {slide.image && slide.image.type === "XXL" ? (
        <Image slide={slide} />
      ) : null}
    </div>
  )
}

const Box = ({ slide }: { slide: PortraitSlide }) => {
  return (
    <div
      style={{
        backgroundColor:
          slide.box && slide.box.boxBackColor
            ? slide.box.boxBackColor
            : undefined,
        position: "absolute",
        bottom:
          slide.image && slide.image.type === "M"
            ? `calc(${slideHeight}px / ${numberOfRows} * 9)`
            : `calc(${slideHeight}px / ${numberOfRows} * 6)`,
        top:
          slide.image && slide.image.type === "M"
            ? `calc(${slideHeight}px / ${numberOfRows} * 23)`
            : `calc(${slideHeight}px / ${numberOfRows} * 26)`,
        left: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        right: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
        paddingTop: `calc(${slideHeight}px / ${numberOfRows} * 1.5)`,
      }}
    >
      <Textfit
        style={{
          color: slide.textColor,
          lineHeight: 1.31,
          fontWeight: 400,
          paddingLeft: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          paddingRight: `calc(${slideWidth}px / ${numberOfColumns} * 1)`,
          height: `calc(${slideHeight}px / ${numberOfRows} * 8)`,
        }}
        min={18}
        max={80}
        mode="multi"
      >
        <div dangerouslySetInnerHTML={{ __html: slide.text }} />
      </Textfit>
    </div>
  )
}

const Image = ({ slide }: { slide: PortraitSlide }) => {
  return (
    <div
      style={{
        position: "absolute",
        top:
          slide.image.type === "M"
            ? `calc(${slideHeight}px / ${numberOfRows} * 11)`
            : `calc(${slideHeight}px / ${numberOfRows} * 0)`,
        bottom:
          slide.image.type === "M"
            ? `calc(${slideHeight}px / ${numberOfRows} * 20)`
            : `calc(100% / 2 - 90px)`,
        left:
          slide.image.type === "M"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 1)`
            : `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
        right:
          slide.image.type === "M"
            ? `calc(${slideWidth}px / ${numberOfColumns} * 1)`
            : `calc(${slideWidth}px / ${numberOfColumns} * 0)`,
        backgroundImage: `url(${slide.image.url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
  )
}

export default PortraitLayout
