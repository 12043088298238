import { Stack } from "@mui/material"
import { desktopPadding } from "../../../services/config/constants"
import { CSSProperties, ReactNode } from "react"

const PaddingContainerDesktop = ({
  children,
  style,
  disabled = false,
}: {
  children?: ReactNode
  style?: CSSProperties
  disabled?: boolean
}) => {
  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        paddingInline: disabled ? 0 : desktopPadding,
        ...style,
      }}
    >
      {children}
    </Stack>
  )
}

export default PaddingContainerDesktop
