import { Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"

const EpisodeTimeline = ({
  currentSlideIndex,
  numberOfSlides,
  numberOfQuiz,
}: {
  currentSlideIndex: number
  numberOfSlides: number
  numberOfQuiz: number
}) => {
  // timeline items to render
  const slidesItems = Array(numberOfSlides).fill("")
  const quizItems = Array(numberOfQuiz + 1).fill("")

  return (
    <Stack
      direction="row"
      style={{
        width: "calc(100% - 32px)",
        height: 4,
        position: "absolute",
        top: 22,
        left: 16,
        zIndex: 5,
      }}
      spacing={0.6}
    >
      {slidesItems.map((item, index) => (
        <div
          key={index}
          style={{
            width: "100%",
            transition: "100ms",
            height: 4,
            backgroundColor:
              index <= currentSlideIndex ? colors.primary : colors.background,
            borderRadius: 2,
          }}
        />
      ))}
      {quizItems.map((item, index) => (
        <div
          key={index}
          style={{
            width: "100%",
            transition: "100ms",
            height: 4,
            backgroundColor:
              index + slidesItems.length <= currentSlideIndex
                ? "#f43b6d"
                : "#fee6f5",
            borderRadius: 2,
          }}
        />
      ))}
    </Stack>
  )
}

export default EpisodeTimeline
