import { ButtonBase, Stack } from "@mui/material"
import Appear from "../../animations/Appear"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Button from "../../global/common/Button"
import { scrollWindowToTop } from "../../../services/utils/utils"
import { useNavigate } from "react-router-dom"
import infoIconPrimary from "../../../assets/icons/info-primary.svg"
import { CSSProperties, Dispatch, SetStateAction, useContext } from "react"
import { t } from "i18next"
import { MainContext } from "../../../controllers/main"

const ActionsHelpCardDesktop = ({
  setWhatAreMissionsAlertOpen,
  setWhyLogAlertOpen,
  style,
}: {
  setWhatAreMissionsAlertOpen: Dispatch<SetStateAction<boolean>>
  setWhyLogAlertOpen: Dispatch<SetStateAction<boolean>>
  style?: CSSProperties
}) => {
  const navigate = useNavigate()
  const { user } = useContext(MainContext)

  return (
    <Appear style={{ position: "absolute", right: 0, ...style }} fade>
      <Stack gap={2}>
        <Stack
          style={{
            width: 240,
            height: "auto",
            borderRadius: 15,
            border: "1px solid " + colors.primary,
            padding: 14,
            backgroundColor: colors.backgroundWhite,
          }}
        >
          <Stack direction="row" alignItems="center" gap={1.5}>
            <img
              src={user?.profileImage}
              style={{
                width: 76,
                height: 76,
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: 8,
              }}
              alt=""
            />
            <Title fontSize={20}>{t("do_you_need_help")}</Title>
          </Stack>
          <ButtonBase
            disableRipple
            style={{
              width: "fit-content",
              justifyContent: "flex-start",
              marginTop: 18,
            }}
            aria-label={t("what_are_act_missions")}
            onClick={() => {
              setWhatAreMissionsAlertOpen(true)
            }}
          >
            <Stack direction="row" gap={1}>
              <img
                src={infoIconPrimary}
                style={{ width: 20, height: 20 }}
                alt=""
              />
              <Title fontSize={18} lineHeight="21px" color={colors.primary}>
                {t("what_are_act_missions")}
              </Title>
            </Stack>
          </ButtonBase>
          <ButtonBase
            disableRipple
            style={{
              width: "fit-content",
              justifyContent: "flex-start",
              marginTop: 18,
            }}
            aria-label={t("why_log_actions")}
            onClick={() => {
              setWhyLogAlertOpen(true)
            }}
          >
            <Stack direction="row" gap={1}>
              <img
                src={infoIconPrimary}
                style={{ width: 20, height: 20 }}
                alt=""
              />
              <Title fontSize={18} lineHeight="21px" color={colors.primary}>
                {t("why_log_actions")}
              </Title>
            </Stack>
          </ButtonBase>
        </Stack>
        <Button
          title={t("back_to_all_missions")}
          outlined
          width="100%"
          onClick={() => {
            scrollWindowToTop()
            navigate("/")
          }}
        >
          {t("back_to_all_missions")}
        </Button>
      </Stack>
    </Appear>
  )
}

export default ActionsHelpCardDesktop
