import { useLocation, useNavigate } from "react-router-dom"
import { Compose } from "../../services/utils/compose"
import { MissionsContext, MissionsController } from "../../controllers/missions"
import { ActionsContext, ActionsController } from "../../controllers/actions"
import { useContext } from "react"
import Loading from "../public/loading"
import { MainContext } from "../../controllers/main"
import NavBarDesktop from "../../components/global/desktop/NavBarDesktop"
import FooterDesktop from "../../components/global/desktop/FooterDesktop"
import AppRoutes from "../../services/config/routes"
import { JourneysContext, JourneysController } from "../../controllers/journeys"
import { TagsContext, TagsController } from "../../controllers/tags"
import Error from "../public/error"
import { UsersContext, UsersController } from "../../controllers/users"
import {
  ChallengeContext,
  ChallengeController,
} from "../../controllers/challenge"
import {
  FootprintContext,
  FootprintController,
} from "../../controllers/footprint"
import { useTranslation } from "react-i18next"
import TutorialAlertDesktop from "../../components/onboarding/desktop/TutorialAlertDesktop"
import NavBarDesktopTutorial from "../../components/global/desktop/NavBarDesktopTutorial"
import TutorialAlertMobile from "../../components/onboarding/mobile/TutorialAlertMobile"
import Alert from "../../components/global/common/Alert"
import { roundWithDecimalPlaces } from "../../services/utils/utils"

const Main = () => {
  // all controllers
  const controllers = [
    UsersController,
    TagsController,
    ChallengeController,
    MissionsController,
    ActionsController,
    JourneysController,
    FootprintController,
  ]

  return (
    <Compose components={controllers}>
      <Container />
    </Compose>
  )
}

const Container = () => {
  const { isMobile } = useContext(MainContext)

  // check that every controller has finished loading data
  const {
    actionsLoading,
    historyLoading,
    detailedHistoryLoading,
    actionsError,
    historyError,
    detailedHistoryError,
  } = useContext(ActionsContext)
  const {
    journeysLoading,
    journeysError,
    dailyEpisodeLoading,
    dailyEpisodeError,
  } = useContext(JourneysContext)
  const {
    categoriesLoading,
    categoriesError,
    mainCategoriesLoading,
    mainCategoriesError,
  } = useContext(TagsContext)
  const {
    missionsLoading,
    missionsError,
    missionsHistoryLoading,
    missionsHistoryError,
    completedMissionsCountLoading,
    completedMissionsCountError,
  } = useContext(MissionsContext)
  const { leaderboardLoading, leaderboardError } = useContext(UsersContext)
  const { challengeLoading, challengeError, savingsLoading, savingsError } =
    useContext(ChallengeContext)
  const {
    userFootprintLoading,
    userFootprintError,
    formsListLoading,
    formsListError,
    userFormsListLoading,
    userFormsListError,
  } = useContext(FootprintContext)

  const loading =
    actionsLoading ||
    historyLoading ||
    detailedHistoryLoading ||
    journeysLoading ||
    categoriesLoading ||
    mainCategoriesLoading ||
    missionsLoading ||
    dailyEpisodeLoading ||
    leaderboardLoading ||
    challengeLoading ||
    missionsHistoryLoading ||
    userFootprintLoading ||
    formsListLoading ||
    userFormsListLoading ||
    savingsLoading ||
    completedMissionsCountLoading

  const error =
    actionsError ||
    historyError ||
    detailedHistoryError ||
    journeysError ||
    categoriesError ||
    mainCategoriesError ||
    missionsError ||
    dailyEpisodeError ||
    leaderboardError ||
    challengeError ||
    missionsHistoryError ||
    userFootprintError ||
    formsListError ||
    userFormsListError ||
    savingsError ||
    completedMissionsCountError

  if (error) {
    return (
      <Error
        modules={[
          { name: "Authentication", error: false },
          { name: "Categories", error: categoriesError },
          { name: "Main categories", error: mainCategoriesError },
          { name: "Actions", error: actionsError },
          { name: "History", error: historyError },
          { name: "Daily history", error: detailedHistoryError },
          { name: "Journey", error: journeysError },
          { name: "Daily episode", error: dailyEpisodeError },
          { name: "Missions", error: missionsError },
          { name: "Missions history", error: missionsHistoryError },
          { name: "Completed missions", error: completedMissionsCountError },
          { name: "Leaderboard", error: leaderboardError },
          { name: "Challenge", error: challengeError },
          { name: "Challenge savings", error: savingsError },
          { name: "Footprint", error: userFootprintError },
          { name: "Footprint forms", error: formsListError },
          { name: "User footprint forms", error: userFormsListError },
        ]}
      />
    )
  }

  if (loading) {
    return <Loading />
  }

  return isMobile ? <ContainerMobile /> : <ContainerDesktop />
}

const ContainerDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    windowHeight,
    footprintFeedbackOpen,
    setFootprintFeedbackOpen,
    viewTutorial,
    setViewTutorial,
  } = useContext(MainContext)
  const { userFootprint } = useContext(FootprintContext)

  return (
    <div
      id="main-container"
      style={{
        width: "100%",
        height: windowHeight,
        maxHeight: windowHeight,
        position: "relative",
        overflowY: "scroll",
      }}
    >
      {window.location.pathname.includes("/journey/") ||
      window.location.pathname.includes(
        "/dailyepisode/"
      ) ? null : viewTutorial ? (
        <NavBarDesktopTutorial />
      ) : (
        <NavBarDesktop />
      )}
      <main
        id="main-content"
        style={{
          height:
            window.location.pathname.includes("/journey/") ||
            window.location.pathname.includes("/dailyepisode/")
              ? "100%"
              : "auto",
        }}
        aria-label={t("main_content")}
      >
        <TutorialAlertDesktop />
        <Alert
          open={footprintFeedbackOpen}
          title={t("your_footprint_equals_to", {
            tons: userFootprint
              ? roundWithDecimalPlaces(userFootprint.footprint, 2)
                  .toString()
                  .replace(".", ",")
              : "0",
          })}
          description={t("your_footprint_equals_to_description", {
            trees: userFootprint
              ? Math.round((userFootprint.footprint * 1000) / 22)
              : 0,
          })}
          primaryActionLabel={t("start_taking_action")}
          primaryActionOnClick={() => {
            setFootprintFeedbackOpen(false)
          }}
          secondaryActionLabel={t("found_out_emissions_details")}
          secondaryActionOnClick={() => {
            setFootprintFeedbackOpen(false)
            setViewTutorial(false)
            navigate("/performance/footprint")
          }}
          isSecondaryActionPrimary={false}
          isDescriptionHtml
        />
        <AppRoutes location={location} />
      </main>
      {window.location.pathname.includes("/journey/") ||
      window.location.pathname.includes("/dailyepisode") ? null : (
        <FooterDesktop />
      )}
    </div>
  )
}

const ContainerMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    windowHeight,
    footprintFeedbackOpen,
    setFootprintFeedbackOpen,
    setViewTutorial,
  } = useContext(MainContext)
  const { userFootprint } = useContext(FootprintContext)

  return (
    <main
      id="main-container"
      style={{
        width: "100%",
        height: windowHeight,
        maxHeight: windowHeight,
        position: "relative",
        overflowY: "scroll",
      }}
      aria-label={t("main_content")}
    >
      <TutorialAlertMobile />
      <Alert
        open={footprintFeedbackOpen}
        title={t("your_footprint_equals_to", {
          tons: userFootprint
            ? roundWithDecimalPlaces(userFootprint.footprint, 2)
                .toString()
                .replace(".", ",")
            : "0",
        })}
        description={t("your_footprint_equals_to_description", {
          trees: userFootprint
            ? Math.round((userFootprint.footprint * 1000) / 22)
            : 0,
        })}
        primaryActionLabel={t("start_taking_action")}
        primaryActionOnClick={() => {
          setFootprintFeedbackOpen(false)
        }}
        secondaryActionLabel={t("found_out_emissions_details")}
        secondaryActionOnClick={() => {
          setFootprintFeedbackOpen(false)
          setViewTutorial(false)
          navigate("/performance/footprint")
        }}
        isSecondaryActionPrimary={false}
        isDescriptionHtml
      />
      <AppRoutes location={location} />
    </main>
  )
}

export default Main
