import {
  CSSProperties,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react"
import { colors } from "../../../services/config/colors"
import { ButtonBase, Stack } from "@mui/material"
import Text from "./Text"
import { motion } from "framer-motion"
import { MainContext } from "../../../controllers/main"

const Tabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
  tabContentId,
  style,
}: {
  tabs: string[]
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
  tabContentId: string
  style?: CSSProperties
}) => {
  const { isMobile } = useContext(MainContext)

  // focus tab content (accessibility)
  const focusTabContent = () => {
    const container = document.getElementById(tabContentId)!
    container.tabIndex = 0
    container.focus()
    setTimeout(() => container.removeAttribute("tabindex"), 500)
  }

  // refs for all tabs
  const tabRefs = useRef<HTMLButtonElement[]>([])

  // focus tab helper
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const focusTab = (index: number) => {
    const tab = tabRefs.current[index]
    if (tab) {
      tab.focus()
    }
  }

  // onKeyDown handler for tab elements
  const onKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const count = tabs.length
    const nextTab = () => focusTab((selectedIndex + 1) % count)
    const prevTab = () => focusTab((selectedIndex - 1 + count) % count)
    const firstTab = () => focusTab(0)
    const lastTab = () => focusTab(count - 1)

    const keyMap = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    }

    if (
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft" ||
      event.key === "Home" ||
      event.key === "End"
    ) {
      const action = keyMap[event.key]
      if (action) {
        event.preventDefault()
        action()
      }
    }
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: isMobile ? "calc(100% - 32px)" : "100%",
        height: isMobile ? 40 : 56,
        marginLeft: isMobile ? 16 : 0,
        borderRadius: 16,
        backgroundColor: colors.background,
        position: "relative",
        gap: 4,
        paddingInline: 2,
        ...style,
      }}
    >
      <motion.div
        animate={selectedTab.toString()}
        variants={tabs.reduce(
          (obj, current, index) => ({
            ...obj,
            [index]: {
              x: isMobile
                ? `calc((100vw - 32px) / ${tabs.length} * ${index})`
                : index
                ? `calc(100% / ${index} + 4px)`
                : 0,
            },
          }),
          {}
        )}
        transition={{ x: { type: "spring", damping: 18, stiffness: 160 } }}
        initial={false}
        style={{
          width: `calc(100% / ${tabs.length} - 4px)`,
          height: isMobile ? 36 : 52,
          position: "absolute",
          backgroundColor: colors.primary,
          borderRadius: 14,
        }}
      />
      {tabs.map((tab, index) => (
        <ButtonBase
          key={index}
          style={{
            width: `calc(100% / ${tabs.length})`,
            height: isMobile ? 36 : 52,
            borderRadius: 14,
            position: "relative",
          }}
          onClick={() => {
            setSelectedTab(index)

            focusTabContent()
          }}
          aria-label={tab}
          role="tab"
          aria-selected={selectedTab === index ? "true" : "false"}
          ref={(element) => (tabRefs.current[index] = element!)}
          onKeyDown={onKeyDown}
          onFocus={() => setSelectedIndex(index)}
        >
          <Text
            fontSize={14}
            lineHeight="18px"
            fontWeight={700}
            style={{ width: "100%", transition: "300ms" }}
            textAlign="center"
            color={
              selectedTab === index ? colors.textWhite : colors.textSecondary
            }
          >
            {tab}
          </Text>
        </ButtonBase>
      ))}
    </Stack>
  )
}

export default Tabs
