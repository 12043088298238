import { CSSProperties, ReactNode } from "react"
import { colors } from "../../../services/config/colors"

const Title = ({
  children,
  fontSize = 26,
  fontWeight = 700,
  lineHeight,
  color = colors.text,
  width,
  textAlign = "left",
  paddingLeft = 0,
  paddingRight = 0,
  style,
  ellipsis = false,
  component = "p",
  id,
  ariaHidden = false,
  ariaLive = "off",
}: {
  children?: ReactNode
  fontSize?: number
  fontWeight?: 300 | 400 | 500 | 600 | 700 | 800
  lineHeight?: string
  color?: string
  width?: string | number
  textAlign?: "left" | "center" | "right"
  paddingLeft?: number
  paddingRight?: number
  style?: CSSProperties
  ellipsis?: boolean
  component?: "p" | "h1" | "h2" | "h3" | "h4"
  id?: string
  ariaHidden?: boolean
  ariaLive?: "assertive" | "polite" | "off"
}) => {
  switch (component) {
    case "p":
      return (
        <p
          id={id}
          style={{
            fontSize: `${fontSize * 0.063}rem`,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            color: color,
            maxWidth: width,
            textAlign: textAlign,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            whiteSpace: ellipsis ? "nowrap" : undefined,
            overflow: ellipsis ? "hidden" : undefined,
            textOverflow: ellipsis ? "ellipsis" : undefined,
            ...style,
          }}
          aria-hidden={ariaHidden ? "true" : "false"}
          aria-live={ariaLive}
        >
          {children}
        </p>
      )
    case "h1":
      return (
        <h1
          id={id}
          style={{
            fontSize: `${fontSize * 0.063}rem`,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            color: color,
            maxWidth: width,
            textAlign: textAlign,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            whiteSpace: ellipsis ? "nowrap" : undefined,
            overflow: ellipsis ? "hidden" : undefined,
            textOverflow: ellipsis ? "ellipsis" : undefined,
            ...style,
          }}
          aria-hidden={ariaHidden ? "true" : "false"}
          aria-live={ariaLive}
        >
          {children}
        </h1>
      )
    case "h2":
      return (
        <h2
          id={id}
          style={{
            fontSize: `${fontSize * 0.063}rem`,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            color: color,
            maxWidth: width,
            textAlign: textAlign,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            whiteSpace: ellipsis ? "nowrap" : undefined,
            overflow: ellipsis ? "hidden" : undefined,
            textOverflow: ellipsis ? "ellipsis" : undefined,
            ...style,
          }}
          aria-hidden={ariaHidden ? "true" : "false"}
          aria-live={ariaLive}
        >
          {children}
        </h2>
      )
    case "h3":
      return (
        <h3
          id={id}
          style={{
            fontSize: `${fontSize * 0.063}rem`,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            color: color,
            maxWidth: width,
            textAlign: textAlign,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            whiteSpace: ellipsis ? "nowrap" : undefined,
            overflow: ellipsis ? "hidden" : undefined,
            textOverflow: ellipsis ? "ellipsis" : undefined,
            ...style,
          }}
          aria-hidden={ariaHidden ? "true" : "false"}
          aria-live={ariaLive}
        >
          {children}
        </h3>
      )
    case "h4":
      return (
        <h4
          id={id}
          style={{
            fontSize: `${fontSize * 0.063}rem`,
            fontWeight: fontWeight,
            lineHeight: lineHeight,
            color: color,
            maxWidth: width,
            textAlign: textAlign,
            paddingLeft: paddingLeft,
            paddingRight: paddingRight,
            whiteSpace: ellipsis ? "nowrap" : undefined,
            overflow: ellipsis ? "hidden" : undefined,
            textOverflow: ellipsis ? "ellipsis" : undefined,
            ...style,
          }}
          aria-hidden={ariaHidden ? "true" : "false"}
          aria-live={ariaLive}
        >
          {children}
        </h4>
      )
  }
}

export default Title
