import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import ChallengeMobile from "../../components/challenge/ChallengeMobile"
import ChallengeDesktop from "../../components/challenge/ChallengeDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Challenge = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("challenge")
  }, [])

  // prizes info alert
  const [prizesAlertOpen, setPrizesAlertOpen] = useState<boolean>(false)

  return isMobile ? (
    <ChallengeMobile
      prizesAlertOpen={prizesAlertOpen}
      setPrizesAlertOpen={setPrizesAlertOpen}
    />
  ) : (
    <ChallengeDesktop
      prizesAlertOpen={prizesAlertOpen}
      setPrizesAlertOpen={setPrizesAlertOpen}
    />
  )
}

export default Challenge
