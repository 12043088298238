import { ButtonBase } from "@mui/material"
import arrowLefIcon from "../../../assets/icons/arrow-left.svg"
import { CSSProperties } from "react"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"

const BackButtonMobile = ({
  onClick,
  style,
}: {
  onClick: () => void
  style?: CSSProperties
}) => {
  const { t } = useTranslation()

  return (
    <ButtonBase
      style={{
        width: 48,
        minWidth: 48,
        height: 48,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 12,
        backgroundColor: colors.backgroundWhite,
        boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
        ...style,
      }}
      onClick={onClick}
      aria-label={t("go_back")}
    >
      <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
    </ButtonBase>
  )
}

export default BackButtonMobile
