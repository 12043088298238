import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import SettingsMobile from "../../components/settings/SettingsMobile"
import SettingsDesktop from "../../components/settings/SettingsDesktop"
import { registerPageEvent } from "../../services/utils/utils"

const Settings = () => {
  const { isMobile } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("settings")
  }, [])

  return isMobile ? <SettingsMobile /> : <SettingsDesktop />
}

export default Settings
