import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import Title from "../../global/common/Title"
import { useTranslation } from "react-i18next"
import trophyWhiteIcon from "../../../assets/icons/trophy-white.svg"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"
import PointsBadge from "../../global/common/PointsBadge"
import { Dispatch, SetStateAction, useContext } from "react"
import {
  MissionPeriod,
  MissionSubType,
  MissionType,
} from "../../../services/config/enum"
import { JourneysContext } from "../../../controllers/journeys"
import { ActionsContext } from "../../../controllers/actions"
import bellIcon from "../../../assets/icons/bell.svg"
import {
  addDays,
  daysBetweenDates,
  hoursUntilMidnight,
} from "../../../services/utils/utils"
import FadeFromTop from "../../animations/FadeFromTop"
import { MainContext } from "../../../controllers/main"
import Confetti from "../../global/common/Confetti"

const MissionCardDesktop = ({
  index,
  color,
  title,
  titleColor = colors.text,
  goButtonColor,
  image,
  current,
  target,
  points,
  satisfied,
  missionType,
  missionSubType,
  missionTypeId,
  period,
  onClick,
  selectedTab,
  end,
  currentPerDay,
  targetPerDay,
  setComeBackAlertOpen,
  forFeedback = false,
  updatedAt,
}: {
  index: number
  color: string
  title: string
  titleColor?: string
  goButtonColor: string
  image: string
  current: number
  target: number
  points: number
  satisfied: boolean
  missionType: MissionType
  missionSubType: MissionSubType
  missionTypeId: string
  period: MissionPeriod
  onClick: () => void
  selectedTab?: number
  end?: string
  currentPerDay?: number
  targetPerDay?: number
  setComeBackAlertOpen?: Dispatch<SetStateAction<boolean>>
  forFeedback?: boolean
  updatedAt?: string
}) => {
  const { t } = useTranslation()
  const { viewTutorial, currentTutorialPage } = useContext(MainContext)
  const { journeys, dailyEpisode } = useContext(JourneysContext)
  const { actions } = useContext(ActionsContext)

  return (
    <FadeFromTop
      className={`mission-card ${missionType.toLowerCase()}`}
      style={{
        width: "100%",
        position: "relative",
        zoom: forFeedback ? 0.54 : 1,
      }}
      delay={0.025 * index}
      y={-40}
    >
      <div
        style={{
          width: "100%",
          maxWidth: 330,
          height: 461,
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
        onClick={
          satisfied || forFeedback
            ? () => {}
            : ((currentPerDay &&
                targetPerDay &&
                currentPerDay >= targetPerDay &&
                updatedAt &&
                new Date(updatedAt).toLocaleDateString() ===
                  new Date().toLocaleDateString()) ||
                (missionType === MissionType.LEARN &&
                  current < target &&
                  updatedAt &&
                  new Date(updatedAt).toLocaleDateString() ===
                    new Date().toLocaleDateString())) &&
              setComeBackAlertOpen
            ? () => {
                setComeBackAlertOpen(true)
              }
            : onClick
        }
      >
        {satisfied ? (
          <Stack
            alignItems="center"
            style={{
              width: "100%",
              height: "calc(100% - 34px)",
              borderRadius: 20,
              backgroundColor: color,
              paddingTop: 42,
              paddingInline: 25,
              position: "relative",
              boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
            }}
          >
            <img src={trophyWhiteIcon} style={{ width: 42 }} alt="" />
            <Title
              fontSize={20}
              lineHeight="23px"
              color={colors.textWhite}
              textAlign="center"
              style={{ marginTop: 22 }}
            >
              {t("mission_completed").toUpperCase() + "!"}
            </Title>
            <Text
              fontSize={18}
              fontWeight={500}
              lineHeight="21px"
              color={colors.textWhite}
              textAlign="center"
              style={{ width: "100%", marginTop: 20, fontStyle: "italic" }}
              paddingLeft={25}
              paddingRight={25}
            >
              {title}
            </Text>
            <PointsBadge
              points={points}
              style={{ position: "absolute", bottom: 120 }}
            />
            <Text
              fontSize={14}
              lineHeight="16px"
              fontWeight={400}
              textAlign="center"
              color={colors.textWhite}
              style={{ position: "absolute", bottom: 60 }}
              paddingLeft={50}
              paddingRight={50}
            >
              {period === MissionPeriod.DAILY
                ? t("come_back_tomorrow")
                : period === MissionPeriod.WEEKLY
                ? t("come_back_next_week")
                : period === MissionPeriod.CUSTOM && end
                ? t("come_back_next_month", {
                    date: addDays(new Date(end), 1).toLocaleDateString(),
                  })
                : null}
            </Text>
          </Stack>
        ) : (
          <div
            style={{
              width: "100%",
              height: "calc(100% - 34px)",
              borderRadius: 20,
              backgroundColor: colors.backgroundWhite,
              position: "relative",
            }}
          >
            <img
              src={image}
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                borderRadius: 20,
                objectFit: "cover",
              }}
              alt=""
            />
            <ButtonBase
              disabled={forFeedback}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 20,
                boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
                position: "relative",
              }}
              aria-label={t("go_to_specific_mission", { mission: title })}
            >
              <Stack
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 20,
                  paddingInline: 30,
                  position: "relative",
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "auto",
                    height: 40,
                    position: "absolute",
                    top: 24,
                    right: 25,
                    gap: 10,
                  }}
                >
                  {/* period badge */}
                  {!selectedTab && !forFeedback ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      style={{
                        width: "auto",
                        height: 40,
                        borderRadius: 4,
                        paddingInline: 10,
                        border: "1.5px solid " + colors.tertiary,
                        backgroundColor: colors.backgroundWhite,
                        gap: MissionPeriod.DAILY ? 6.5 : 7.5,
                      }}
                    >
                      {period === MissionPeriod.DAILY && (
                        <img src={bellIcon} style={{ width: 13.3 }} alt="" />
                      )}
                      <Title fontSize={18} color={colors.tertiary}>
                        {period === MissionPeriod.DAILY
                          ? `-${hoursUntilMidnight()} ${t("hours")}`
                          : t(period.toLowerCase())}
                      </Title>
                    </Stack>
                  ) : null}
                  {selectedTab === 3 &&
                  end &&
                  daysBetweenDates(new Date(), new Date(end), true) > 0 ? (
                    <Stack
                      direction="row"
                      alignItems="center"
                      style={{
                        width: "auto",
                        height: 40,
                        borderRadius: 4,
                        paddingInline: 10,
                        border: "1.5px solid " + colors.secondary,
                        backgroundColor: colors.backgroundWhite,
                        gap: 6.5,
                      }}
                    >
                      <img src={bellIcon} style={{ width: 13.3 }} alt="" />
                      <Title fontSize={18} color={colors.secondary}>
                        -{daysBetweenDates(new Date(), new Date(end), true)}{" "}
                        {t("days")}
                      </Title>
                    </Stack>
                  ) : null}
                  {/* points badge */}
                  {!forFeedback && <PointsBadge points={points} />}
                </Stack>
                <Title
                  fontSize={20}
                  lineHeight="23.6px"
                  color={titleColor}
                  style={{
                    marginTop: 88,
                    width:
                      missionSubType === MissionSubType.DAILYEPISODE &&
                      period === MissionPeriod.DAILY
                        ? "100%"
                        : "85%",
                  }}
                >
                  {title}
                </Title>
                {/* journey */}
                {missionSubType === MissionSubType.JOURNEY && (
                  <div
                    style={{
                      width: "60%",
                      height: 37,
                      display: "flex",
                      alignItems: "center",
                      marginTop: 26,
                    }}
                  >
                    <Title
                      fontSize={16}
                      lineHeight="18.5px"
                      color={colors.textWhite}
                      style={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {
                        journeys.find(
                          (journey) => journey.id === missionTypeId
                        )!.title
                      }
                    </Title>
                  </div>
                )}
                {missionSubType === MissionSubType.JOURNEY && (
                  <div
                    style={{
                      width: 140,
                      height: 140,
                      borderRadius: 20,
                      backgroundColor: color,
                      position: "absolute",
                      top: 200,
                      left: 100,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={
                        journeys.find(
                          (journey) => journey.id === missionTypeId
                        )!.image
                      }
                      style={{
                        width: 128,
                        height: 128,
                        borderRadius: 16,
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                  </div>
                )}
                {missionSubType === MissionSubType.JOURNEY && (
                  <Stack
                    style={{
                      width: 38,
                      position: "absolute",
                      top: 125,
                      right: 14.5,
                      gap: 14,
                    }}
                  >
                    {journeys
                      .find((journey) => journey.id === missionTypeId)!
                      .episodes.slice(0, 5)
                      .map((episode) => (
                        <img
                          key={episode.id}
                          src={episode.image}
                          style={{
                            width: 38,
                            height: 38,
                            minHeight: 38,
                            borderRadius: 9,
                          }}
                          alt={t("episode")}
                        />
                      ))}
                  </Stack>
                )}
                {/* daily episode daily */}
                {missionSubType === MissionSubType.DAILYEPISODE &&
                period === MissionPeriod.DAILY &&
                dailyEpisode ? (
                  <Stack
                    alignItems="center"
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: 125,
                      left: 0,
                      gap: 16,
                    }}
                  >
                    <img
                      src={dailyEpisode?.image}
                      style={{
                        width: 146,
                        height: 136,
                        borderRadius: 14,
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <Title
                      fontSize={16}
                      lineHeight="18.5px"
                      color={colors.textWhite}
                      textAlign="center"
                      style={{
                        width: "60%",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {dailyEpisode?.title}
                    </Title>
                  </Stack>
                ) : null}
                {/* daily episode weekly and custom */}
                {missionSubType === MissionSubType.DAILYEPISODE &&
                (period === MissionPeriod.WEEKLY ||
                  period === MissionPeriod.CUSTOM) &&
                dailyEpisode ? (
                  <Stack
                    direction="row"
                    alignItems="center"
                    style={{
                      width: "calc(100% - 60px)",
                      height: 160,
                      position: "absolute",
                      top: 160,
                      gap: 16,
                    }}
                  >
                    <img
                      src={dailyEpisode?.image}
                      style={{
                        width: "60%",
                        height: "100%",
                        borderRadius: 14,
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <Stack style={{ width: "40%", height: "100%", gap: 16 }}>
                      <div
                        style={{
                          width: "100%",
                          height: "calc(50% - 8px)",
                          borderRadius: 14,
                          backgroundImage: `url(${
                            dailyEpisode?.slides.find(
                              (slide) => slide.slideType === "TitleSlide"
                            )?.background
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        <img
                          src={
                            (dailyEpisode?.slides as any[]).filter(
                              (item) => item.image
                            )[0].image.url
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: 14,
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          height: "calc(50% - 8px)",
                          borderRadius: 14,
                          backgroundImage: `url(${
                            dailyEpisode?.slides.find(
                              (slide) => slide.slideType === "TitleSlide"
                            )?.background
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        <img
                          src={
                            (dailyEpisode?.slides as any[]).filter(
                              (item) => item.image
                            )[1] &&
                            (dailyEpisode?.slides as any[]).filter(
                              (item) => item.image
                            )[1].image
                              ? (dailyEpisode?.slides as any[]).filter(
                                  (item) => item.image
                                )[1].image.url
                              : dailyEpisode!.image
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: 14,
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                    </Stack>
                  </Stack>
                ) : null}
                {/* single action */}
                {actions.some((action) => action.id === missionTypeId) && (
                  <img
                    src={
                      actions.find((action) => action.id === missionTypeId)
                        ?.image
                    }
                    style={{
                      width: 180,
                      height: 180,
                      objectFit: "cover",
                      position: "absolute",
                      top: 170,
                      left: 0,
                      right: 0,
                      marginInline: "auto",
                    }}
                    alt=""
                  />
                )}
              </Stack>
              {/* go button */}
              <Title
                fontSize={18}
                color={
                  goButtonColor === "#FFFFFF"
                    ? goButtonColor
                    : (colors.missions as any)[missionType.toLowerCase()]
                }
                style={{
                  position: "absolute",
                  bottom: 48,
                  textDecoration: "underline",
                }}
              >
                {t(missionType.toLowerCase()).toUpperCase()}
              </Title>
            </ButtonBase>
          </div>
        )}
        <div
          className="mission-card-completion-badge"
          style={{
            width: 68,
            height: 68,
            borderRadius: "100%",
            backgroundColor: satisfied ? color : colors.backgroundWhite,
            border:
              "3.5px solid " + (satisfied ? colors.backgroundWhite : color),
            position: "absolute",
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform:
              viewTutorial && currentTutorialPage === 4 && index === 2
                ? "scale(1.5)"
                : "none",
            transition: "400ms",
            transitionTimingFunction:
              viewTutorial && currentTutorialPage === 4 && index === 2
                ? "cubic-bezier(.17, .67, .41, 2)"
                : "ease-out",
          }}
        >
          {viewTutorial && currentTutorialPage === 4 && index === 2 ? (
            <Confetti particleCount={80} width={500} top={34} />
          ) : null}
          {satisfied ? (
            <img
              src={checkWhiteIcon}
              style={{ width: 24 }}
              alt={t("mission_completed")}
            />
          ) : (
            <Text
              fontSize={16}
              lineHeight="19.6px"
              fontWeight={700}
              color={color}
              ariaLabel={t("mission_progression_count", {
                count: current,
                total: target,
              })}
            >
              {(viewTutorial && currentTutorialPage === 4 && index === 2) ||
              forFeedback
                ? `${target}/${target}`
                : `${current}/${target}`}
            </Text>
          )}
        </div>
      </div>
    </FadeFromTop>
  )
}

export default MissionCardDesktop
