import { ButtonBase, Stack } from "@mui/material"
import closeIcon from "../../../assets/icons/close.png"
import chevronDownIcon from "../../../assets/icons/chevron-down.svg"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import Episode from "../../../models/episode"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import IosSelectMobile from "../../global/mobile/IosSelectMobile"
import { JourneysContext } from "../../../controllers/journeys"
import { useTranslation } from "react-i18next"

const EpisodeHeader = ({
  episode,
  episodeIndex,
}: {
  episode: Episode
  episodeIndex: number
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { journey } = useContext(JourneysContext)

  // episode select open
  const [episodeSelectOpen, setEpisodeSelectOpen] = useState<boolean>(false)

  return (
    <Stack
      direction="row"
      style={{
        width: "calc(100% - 32px)",
        height: 32,
        position: "absolute",
        left: 16,
        top: 35,
      }}
    >
      <ButtonBase
        style={{ width: "calc(100% - 48px)", borderRadius: 5, zIndex: 5 }}
        onClick={() => {
          setEpisodeSelectOpen(true)
        }}
        aria-label={t("select_episode")}
      >
        <div
          style={{
            width: "100%",
            height: 32,
            backgroundColor: colors.backgroundWhite,
            borderRadius: 5,
            boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.16)",
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            paddingLeft: 16,
            position: "relative",
          }}
        >
          <Text
            fontSize={16}
            fontWeight={400}
            lineHeight="19px"
            textAlign="left"
            ellipsis
            style={{
              maxWidth: "calc(100% - 50px)",
            }}
          >
            {episode.title}
          </Text>
          <img
            src={chevronDownIcon}
            style={{ width: 22, position: "absolute", right: 12, opacity: 0.8 }}
            alt=""
          />
        </div>
      </ButtonBase>
      <ButtonBase
        style={{
          width: 32,
          height: 32,
          marginLeft: 16,
          borderRadius: 16,
          zIndex: 5,
        }}
        onClick={() => {
          navigate("/journey")
        }}
        aria-label={t("go_back")}
      >
        <div
          style={{
            width: 30,
            height: 30,
            backgroundColor: colors.backgroundWhite,
            borderRadius: 15,
            boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.16)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={closeIcon} style={{ width: 9, height: 9 }} alt="" />
        </div>
      </ButtonBase>
      <IosSelectMobile
        open={episodeSelectOpen}
        setOpen={setEpisodeSelectOpen}
        onOkPressed={(newOption: number) => {
          navigate(`/journey/${journey?.episodes[newOption].id}/0`)
        }}
        options={journey!.episodes.map((episode) => {
          return {
            id: episode.id,
            label: episode.title,
            disabled: false,
          }
        })}
        currentOption={episodeIndex}
      />
    </Stack>
  )
}

export default EpisodeHeader
