import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import { CSSProperties, useContext } from "react"
import infoIconPrimary from "../../../assets/icons/info-primary.svg"
import Title from "../common/Title"
import { MainContext } from "../../../controllers/main"

const AvatarInfoButton = ({
  title,
  onClick,
  style,
}: {
  title: string
  onClick?: () => void
  style?: CSSProperties
}) => {
  const { user } = useContext(MainContext)

  return (
    <ButtonBase
      aria-label={title}
      onClick={onClick}
      style={{ borderRadius: 15, ...style }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        style={{
          width: 240,
          height: 92,
          border: "1px solid " + colors.primary,
          padding: 8,
          borderRadius: 15,
          position: "relative",
          backgroundColor: colors.backgroundWhite,
        }}
      >
        <img
          src={user?.profileImage}
          style={{
            width: 76,
            height: 76,
            objectFit: "cover",
            objectPosition: "center",
            borderRadius: 8,
          }}
          alt=""
        />
        <Title
          fontSize={18}
          lineHeight="21px"
          color={colors.primary}
          style={{ width: 110 }}
        >
          {title}
        </Title>
        <img
          src={infoIconPrimary}
          style={{
            width: 20,
            height: 20,
            position: "absolute",
            top: 8,
            right: 8,
          }}
          alt=""
        />
      </Stack>
    </ButtonBase>
  )
}

export default AvatarInfoButton
