import { Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import bellBigIcon from "../../../assets/icons/bell-big.svg"
import stopwatchImage from "../../../assets/images/stopwatch.svg"
import { Trans } from "react-i18next"
import { hoursUntilMidnight } from "../../../services/utils/utils"
import FadeFromTop from "../../animations/FadeFromTop"

const DailyMissionsCardDesktop = () => {
  return (
    <FadeFromTop delay={0.075} y={-40}>
      <Stack
        alignItems="center"
        style={{
          width: "100%",
          maxWidth: 330,
          height: 427,
          backgroundColor: colors.background,
          borderRadius: 20,
          border: "2px solid " + colors.primary,
          paddingTop: 30,
          paddingInline: 30,
          position: "relative",
        }}
      >
        <img src={bellBigIcon} style={{ height: 42 }} alt="" />
        <Text
          fontSize={20}
          fontWeight={400}
          lineHeight="24px"
          textAlign="center"
          style={{ marginTop: 28 }}
        >
          <Trans i18nKey="hours_left" count={hoursUntilMidnight()} />
        </Text>
        <img
          src={stopwatchImage}
          style={{ height: 180, position: "absolute", bottom: 26 }}
          alt=""
        />
      </Stack>
    </FadeFromTop>
  )
}

export default DailyMissionsCardDesktop
